import type { components } from '@/types/swagger'

type User = components['schemas']['User']

const currentDate = new Date().toISOString();

export const emptyUser: Partial<User> = {
  firstName: '',
  lastName: '',
  email: '',
  roles: [],
  locale: 'de',
  created_at: currentDate,
  updated_at: currentDate
}
