import { ref } from 'vue'

const isDarkMode = ref(false)

/**
 * A composable to detect the user's dark mode preference.
 * @returns A functions to set the listener and a reactive property isDarkMode.
 */
export function useDarkMode() {
  const mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)')

  const initializeDarkMode = () => {
    // Check if there's a dark mode value in localStorage
    const localStorageTheme = localStorage.getItem('theme')
    isDarkMode.value =
      localStorageTheme === 'dark'
        ? true
        : localStorageTheme === 'light'
          ? false
          : mediaQueryList.matches

    if (isDarkMode.value) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }

  const setDarkMode = (value: boolean) => {
    // manually set the dark mode with the switch component in menu top
    if (value) {
      document.documentElement.classList.add('dark')
      localStorage.setItem('theme', 'dark')
    } else {
      document.documentElement.classList.remove('dark')
      localStorage.setItem('theme', 'light')
    }

    isDarkMode.value = value
  }

  return {
    initializeDarkMode,
    isDarkMode,
    setDarkMode
  }
}
