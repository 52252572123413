<template>
  <div class="flex flex-col gap-1 pl-16 [&>*]:text-lg" ref="container">
    <slot />
    <!-- Only render the <hr> if slot content is rendered -->
    <hr v-if="slotHasContent" class="ml-[0.9rem] mr-1.5 mt-1 border-misty-grey" />
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, nextTick } from 'vue'

const slotHasContent = ref(false)
const container = ref<HTMLElement | null>(null)

onMounted(async () => {
  await nextTick() // Ensure the slot content is rendered first

  // Check if the div has more than just the slot
  if (container.value) {
    const children = container.value.children
    // Check if there is more than 1 child (the <slot> tag will be replaced by its content)
    slotHasContent.value = children.length > 0
  }
})
</script>
