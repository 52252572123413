export const getFormattedDate = (locale: string, dateString: string, onlyHours: boolean = false): string => {
  const optionsComplete: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  };
  const optionsOnlyHours: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric'
  };

  const date = new Date(dateString);
  const localeString = locale === 'de' ? 'de-DE' : 'en-US';
  const options = onlyHours ? optionsOnlyHours : optionsComplete;

  const formattedDate = onlyHours
    ? date.toLocaleTimeString(localeString, options)
    : date.toLocaleDateString(localeString, options);

  return locale === 'de' ? `${formattedDate} Uhr` : formattedDate;
};
