import '@techcast/histoire/dist/styles/style.css'

import { all } from '@awesome.me/kit-878aa3b85a/icons'
import { generateClasses } from '@formkit/themes'
import { defaultConfig, plugin } from '@formkit/vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Editor from 'quill'
import 'quill/dist/quill.snow.css'
import { type WritableComputedRef, createApp } from 'vue'
import type { I18n } from 'vue-i18n'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

// Vue 3 Quill 2.0.0 Editor Component
import { loadLocaleMessages, setupI18n } from '@/utils/i18n'
import pinia from '@/utils/piniaStore'

import App from './App.vue'
import './assets/css/main.css'
import './assets/css/scrollbar.css'
import router from './router'
// @ts-ignore
import defaultFormTheme from './themes/defaultFormTheme'

library.add(...all)

const app = createApp(App)

const i18n = setupI18n() as I18n

// vue-toastification options
const toastOptions = {
  shareAppContext: true,
  position: 'bottom-right',
  timeout: 5000,
  transition: 'Vue-Toastification__fade',
  showCloseButtonOnHover: true,
  hideProgressBar: true,
  draggable: false
}

// .then syntax is needed here because top level await is not supported
loadLocaleMessages(i18n, (i18n.global.locale as WritableComputedRef<string>).value)
  .then(() => {
    app.component('FontAwesomeIcon', FontAwesomeIcon)
    app.component('QuillEditor', Editor)
    app.use(router)
    app.use(pinia)
    app.use(i18n)
    app.use(Toast, toastOptions)
    app.use(
      plugin,
      defaultConfig({
        config: {
          classes: generateClasses(defaultFormTheme)
        }
      })
    )

    router.isReady().then(() => {
      app.mount('#app')
    })
  })
  .catch((error) => {
    console.error('Failed to load locale messages:', error)
  })
