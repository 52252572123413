/**
 * Check if the current path matches the base path.
 * Used to determine if a SubMenu should be open or not.
 */
export const matchesBasePath = (currentPath: string, basePath: string): boolean => {
  /**
   * Remove leading slashes from currentPath and basePath.
   */
  const normalizedCurrentPath = currentPath.replace(/^\/+/, '')
  const normalizedBasePath = basePath.replace(/^\/+/, '')

  /**
   * Escape special regex characters in the basePath.
   */
  const escapedBasePath = normalizedBasePath.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')

  /**
   * Build the regex pattern dynamically using the escaped basePath
   */
  const basePathPattern = new RegExp(`^${escapedBasePath}(?:/.*)?$`)

  /**
   * Check if the current path matches the base path.
   */
  return basePathPattern.test(normalizedCurrentPath)
}
