import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toastification'

import type { EmbedResponse3q, Project3q } from '@/types/Custom3qTypes'
import type { components as components3q } from '@/types/types3q'
import { convertLabel } from '@/utils/api3q/convertLabel'
import { type StreamType, convertStreamType } from '@/utils/api3q/convertStreamType'
import { createQueryString } from '@/utils/api3q/createQueryString'

/**
 * 3Q Types
 */
type ProjectPostParams = components3q['schemas']['ProjectPostParams']
type Stream = components3q['schemas']['Stream']
type Channel = components3q['schemas']['Channel']
type ChannelSetting = components3q['schemas']['ChannelSetting']
type embedParams = components3q['schemas']['FileEmbedParams']

export const useStreamingStore = defineStore('streaming', () => {
  /**
   * ----- Internal Variables -----
   */
  const { t } = useI18n()
  const toast = useToast()
  const url = import.meta.env.VITE_3Q_URL
  const apiKey = import.meta.env.VITE_3Q_API_KEY
  const currentProject = ref<Project3q | null>(null)

  /**
   * ----- CRUD Actions -----
   */
  const createProject = async (projectName: string, streamType: StreamType): Promise<Stream> => {
    const convertedStreamType = convertStreamType(streamType)
    const convertedProjectName = convertLabel(projectName)
    const payload: ProjectPostParams = {
      Label: convertedProjectName,
      StreamTypeId: convertedStreamType
    }

    const response = await fetch(`${url}/projects`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'X-AUTH-APIKEY': apiKey },
      body: JSON.stringify(payload)
    })

    if (!response.ok) {
      const errorText = await response.json()

      toast.error(`${t('views.streaming.failedCreateProject')}: ${errorText.message}`)
    }

    const { ProjectId } = await response.json()

    const newProject = await getProject(+ProjectId)
    currentProject.value = newProject

    return newProject
  }

  const getProject = async (projectId: number): Promise<Stream> => {
    const response = await fetch(`${url}/projects/${projectId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'X-AUTH-APIKEY': apiKey }
    })

    if (!response.ok) {
      const errorText = await response.json()

      toast.error(`${t('views.streaming.failedGetProject')}: ${errorText.message}`)
    }

    const jsonResponse: Stream = await response.json()
    currentProject.value = { ...jsonResponse, channels: [] }

    return jsonResponse
  }

  const getChannels = async (projectId: number) => {
    const response = await fetch(`${url}/projects/${projectId}/channels`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'X-AUTH-APIKEY': apiKey }
    })

    if (!response.ok) {
      const errorText = await response.json()

      toast.error(`${t('views.streaming.failedGetChannels')}: ${errorText.message}`)
    }

    const jsonResponse: { Channels?: Channel[] } = await response.json()

    if (currentProject.value) {
      currentProject.value.channels =
        jsonResponse.Channels?.map((channel) => ({
          ...channel,
          ingest: null // Will be populated later
        })) || []
    } else {
      console.warn('No currentProject provided.')
    }

    return jsonResponse
  }

  const getIngest = async (channelId: number) => {
    const response = await fetch(`${url}/channels/${channelId}/ingest`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'X-AUTH-APIKEY': apiKey }
    })

    if (!response.ok) {
      const errorText = await response.json()

      toast.error(`${t('views.streaming.failedGetIngest')}: ${errorText.message}`)
    }

    const jsonResponse: ChannelSetting = await response.json()

    // Find the corresponding channel and attach its ingest data
    if (currentProject.value && currentProject.value.channels) {
      const channel = currentProject.value.channels.find((c) => c.Id === channelId)
      if (channel) {
        channel.ingest = jsonResponse
      }
    }

    return jsonResponse
  }

  const getEmbedCodes = async (
    channelId: number,
    params?: embedParams
  ): Promise<EmbedResponse3q> => {
    let queryString = ''

    if (params) {
      queryString = createQueryString(params)
    }

    const response = await fetch(`${url}/channels/${channelId}/embed?${queryString}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'X-AUTH-APIKEY': apiKey }
    })

    if (!response.ok) {
      const errorText = await response.json()

      toast.error(`${t('views.streaming.failedGetEmbedCode')}: ${errorText.message}`)
    }

    return (await response.json()) as EmbedResponse3q
  }

  /**
   * Loading Functions
   */
  const loadProject = async (liveProjectId3q: number) => {
    await getProject(liveProjectId3q)
    await getChannels(liveProjectId3q)

    if (currentProject.value?.channels) {
      for (const channel of currentProject.value.channels) {
        if (channel.Id) {
          await getIngest(channel.Id)
        } else {
          throw new Error('Channel ID is missing.')
        }
      }
    }
  }

  const resetCurrentProject = () => {
    currentProject.value = null
  }

  return {
    currentProject,
    createProject,
    getProject,
    getChannels,
    getIngest,
    getEmbedCodes,
    loadProject,
    resetCurrentProject
  }
})
