import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toastification'

import { emptyWebformTemplate } from '@/stores/objects/emptyWebformTemplate'
import type { components } from '@/types/swagger'
import { tcFetch } from '@/utils/tcFetch'

type Webform = components['schemas']['Webform']
type WebformTemplate = components['schemas']['WebformTemplate']
type CreateWebformDto = components['schemas']['CreateWebformDto']
type UpdateWebformDto = components['schemas']['UpdateWebformDto']

export const useWebformsStore = defineStore('webforms', () => {
  /**
   * ----- Internal Variables -----
   */

  const url = import.meta.env.VITE_BACKEND_URL
  const { t } = useI18n()
  const toast = useToast()

  /**
   * ----- Reactive Variables -----
   */

  const currentWebform = ref<WebformTemplate>(
    structuredClone(emptyWebformTemplate) as WebformTemplate
  )

  /**
   * ----- CRUD Actions -----
   */

  /**
   * Add a webform to the current event.
   *
   * @param eventId - The ID of the event.
   * @param webform - The webform to add.
   *
   * @returns The saved webform.
   */
  const createWebform = async (eventId: number, webform: CreateWebformDto): Promise<Webform> => {
    const response = await tcFetch('POST', `${url}/events/${eventId}/webforms`, webform)

    if (!response.ok) {
      const errorText = await response.json()
      // Check if errorText is an array
      if (Array.isArray(errorText.message) && errorText.message.length > 0) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(
            `${t('views.events.registration.failedCreateWebform')}: ${errorMessage.message}`
          )
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(`${t('views.events.registration.failedCreateWebform')}: ${errorText.message}`)
      }
    }

    return await response.json()
  }

  /**
   * Fetch a single webform by its ID from the backend.
   *
   * @param webformId - The ID of the webform to fetch.
   *
   * @returns A promise with the fetched webform.
   */
  const fetchWebformById = async (webformId: number): Promise<Webform> => {
    const response = await tcFetch('GET', `${url}/webforms/${webformId}`)

    if (!response.ok) {
      throw new Error(`Failed to fetch webforms. Status: ${response.status} ${response.statusText}`)
    }

    const data = await response.json()

    return data
  }

  /**
   * Update an existing webform.
   *
   * @param webformId - The ID of the webform to update.
   * @param updateWebformDto - The updated webform object.
   *
   * @returns A promise with the updated webform.
   */
  const updateWebform = async (
    webformId: number,
    updateWebformDto: UpdateWebformDto
  ): Promise<Webform> => {
    const response = await tcFetch('PATCH', `${url}/webforms/${webformId}`, updateWebformDto)

    if (!response.ok) {
      const errorText = await response.json()
      // Check if errorText is an array
      if (Array.isArray(errorText.message) && errorText.message.length > 0) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(
            `${t('views.events.registration.failedUpdateWebform')}: ${errorMessage.message}`
          )
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(`${t('views.events.registration.failedUpdateWebform')}: ${errorText.message}`)
      }
    }

    return await response.json()
  }

  /**
   * Delete an webform from the backend.
   * If the request is successful, remove the webform from the webforms array.
   *
   * @param webformId - The ID of the webform to delete.
   */
  const deleteWebform = async (webformId: number) => {
    const response = await tcFetch('DELETE', `${url}/webforms/${webformId}`)

    if (!response.ok) {
      const errorText = await response.json()
      // Check if errorText is an array
      if (Array.isArray(errorText.message) && errorText.message.length > 0) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(
            `${t('views.events.registration.failedDeleteWebform')}: ${errorMessage.message}`
          )
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(`${t('views.events.registration.failedDeleteWebform')}: ${errorText.message}`)
      }
    }

    return response
  }

  return {
    currentWebform,
    createWebform,
    updateWebform,
    fetchWebformById,
    deleteWebform
  }
})
