import type { components } from '@/types/swagger'

type AgendaItem = components['schemas']['AgendaItem']

export const emptyAgendaItem: Partial<AgendaItem> = {
  id: undefined,
  eventId: undefined,
  title: { de: '', en: '' },
  description: { de: '', en: '' },
  startDate: '',
  endDate: '',
  speakers: [],
  type: ''
}
