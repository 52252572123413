import { cloneable } from '@/composables/useClone'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toastification'

import { emptyEmailTemplate } from '@/stores/objects/emptyEmailTemplate'
import type { components } from '@/types/swagger'
import { tcFetch } from '@/utils/tcFetch'

type EmailTemplate = components['schemas']['EmailTemplate']
type CreateEmailTemplateDto = components['schemas']['CreateEmailTemplateDto']
type UpdateEmailTemplateDto = components['schemas']['UpdateEmailTemplateDto']

export const useEmailTemplatesStore = defineStore('emailTemplates', () => {
  /**
   * ----- Internal Variables -----
   */

  const url = import.meta.env.VITE_BACKEND_URL
  const { t } = useI18n()
  const toast = useToast()

  /**
   * ----- Reactive Variables -----
   */

  const emailTemplates = ref<EmailTemplate[]>([])
  const currentEmailTemplate = ref<EmailTemplate>(
    structuredClone(emptyEmailTemplate) as EmailTemplate
  )
  const currentEmailTemplateLanguage = ref<'de' | 'en'>('de')

  /**
   * ----- CRUD Actions -----
   */

  /**
   * Save an emailTemplate to the backend.
   * If the request is successful, add the emailTemplate to the emailTemplates array and sort it.
   *
   * @param createEmailTemplateDto - The emailTemplate object to save.
   *
   * @returns The saved emailTemplate.
   */
  const createEmailTemplate = async (
    createEmailTemplateDto: CreateEmailTemplateDto
  ): Promise<EmailTemplate> => {
    const response = await tcFetch('POST', `${url}/templates/emails`, createEmailTemplateDto)

    if (!response.ok) {
      // TODO: Bind validation messages to forms
      const errorText = await response.json()
      // Check if errorText is an array
      if (Array.isArray(errorText.message) && errorText.message.length > 0) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(
            `${t('views.templates.email.failedCreateEmailTemplate')}: ${errorMessage.message}`
          )
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(
          `${t('views.templates.email.failedCreateEmailTemplate')}: ${errorText.message}`
        )
      }
    }

    const data = await response.json()

    emailTemplates.value.push(structuredClone(data))

    currentEmailTemplate.value = cloneable.deepCopy(data) as EmailTemplate

    const index = emailTemplates.value.findIndex(
      (template) => String(template.id) === String(currentEmailTemplate.value.id)
    )

    if (index !== -1) {
      // Update the email templates object
      emailTemplates.value[index] = structuredClone(data) as EmailTemplate
    }

    return currentEmailTemplate.value
  }

  /**
   * Fetch emailTemplates from the backend and update the emailTemplates array.
   *
   * @returns A promise with the fetched emailTemplates.
   */
  const fetchAllEmailTemplates = async (): Promise<EmailTemplate[]> => {
    const response = await tcFetch('GET', `${url}/templates/emails`)

    if (!response.ok) {
      throw new Error(
        `Failed to fetch emailTemplates. Status: ${response.status} ${response.statusText}`
      )
    }

    const data = await response.json()

    // Assign the fetched data to the emailTemplates array.
    emailTemplates.value = structuredClone(data) as EmailTemplate[]

    sortEmailTemplates(emailTemplates.value)

    return data
  }

  /**
   * Fetch a single emailTemplate by its ID from the backend.
   *
   * @param emailTemplateId - The ID of the emailTemplate to fetch.
   *
   * @returns The emailTemplate object.
   */
  const fetchEmailTemplateById = async (emailTemplateId: number): Promise<EmailTemplate> => {
    const response = await tcFetch('GET', `${url}/templates/emails/${emailTemplateId}`)

    if (!response.ok) {
      throw new Error(
        `Failed to fetch emailTemplates. Status: ${response.status} ${response.statusText}`
      )
    }

    const data = await response.json()

    // Assign the fetched data to the currentEmailTemplate object.
    currentEmailTemplate.value = cloneable.deepCopy(data) as EmailTemplate

    return data
  }

  /**
   * Update an existing emailTemplate.
   * If the request is successful, update the emailTemplate in the emailTemplates array and sort it.
   *
   * @param updateEmailTemplateDto - The emailTemplate object to update.
   *
   * @returns The updated emailTemplate.
   */
  const updateEmailTemplate = async (
    updateEmailTemplateDto: UpdateEmailTemplateDto
  ) => {

    const response = await tcFetch(
      'PATCH',
      `${url}/templates/emails/${updateEmailTemplateDto.id}`,
      updateEmailTemplateDto as UpdateEmailTemplateDto
    )

    if (!response.ok) {
      const errorText = await response.json()
      // Check if errorText is an array
      if (Array.isArray(errorText.message) && errorText.message.length > 0) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(
            `${t('views.templates.email.failedUpdateEmailTemplate')}: ${errorMessage.message}`
          )
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(
          `${t('views.templates.email.failedUpdateEmailTemplate')}: ${errorText.message}`
        )
      }
    }

    const data = await response.json()

    // fetchAllEmailTemplates must be called so that the array emailTemplates.value is full with the new data
    await fetchAllEmailTemplates()

    const index = emailTemplates.value.findIndex(
      (template) => String(template.id) === String(currentEmailTemplate.value.id)
    )

    // If found, update the template object in the array
    if (index !== -1) {
      // Update the email template object
      emailTemplates.value[index] = structuredClone(data) as EmailTemplate
    }

    return data
  }

  /**
   * Delete an emailTemplate from the backend.
   * If the request is successful, remove the emailTemplate from the emailTemplates array.
   *
   * @param emailTemplateId - The ID of the emailTemplate to delete.
   *
   * @returns The response from the backend (200, 401, 500).
   */
  const deleteEmailTemplate = async (emailTemplateId: number): Promise<Response> => {
    const response = await tcFetch('DELETE', `${url}/templates/emails/${emailTemplateId}`)

    if (!response.ok) {
      const errorText = await response.json()
      // Check if errorText is an array
      if (Array.isArray(errorText.message) && errorText.message.length > 0) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(
            `${t('views.templates.email.failedDeleteEmailTemplate')}: ${errorMessage.message}`
          )
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(
          `${t('views.templates.email.failedDeleteEmailTemplate')}: ${errorText.message}`
        )
      }
    }

    emailTemplates.value = emailTemplates.value.filter(
      (emailTemplate) => String(emailTemplate.id) !== String(emailTemplateId)
    )

    sortEmailTemplates(emailTemplates.value)

    resetCurrentEmailTemplate()

    return response
  }

  /**
   * ----- Helper Functions -----
   */

  /**
   * Reset the currentEmailTemplate object to its initial state (emptyEmailTemplate).
   * This function can be called when adding a new emailTemplate.
   */
  const resetCurrentEmailTemplate = () => {
    currentEmailTemplate.value = structuredClone(emptyEmailTemplate) as EmailTemplate
  }

  /**
   * Sort the emailTemplates array by the updated_at property.
   */
  const sortEmailTemplates = (emailTemplates: EmailTemplate[]) => {
    // sort by updated_at
    emailTemplates.sort((a, b) => {
      return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
    })
  }

  return {
    emailTemplates,
    currentEmailTemplate,
    currentEmailTemplateLanguage,
    fetchAllEmailTemplates,
    fetchEmailTemplateById,
    createEmailTemplate,
    updateEmailTemplate,
    deleteEmailTemplate,
    resetCurrentEmailTemplate,
    sortEmailTemplates
  }
})
