export const arraySortedByStringProperty = <T extends Record<string, any>>(array: T[], property: string): T[] => {
  return array.slice().sort((a, b) => {
    const propA = a[property]
    const propB = b[property]

    // Ensure the properties are strings before comparing
    if (typeof propA === 'string' && typeof propB === 'string') {
      return propA.localeCompare(propB)
    }

    // Fallback in case properties are not strings, consider them equal
    return 0
  })
}