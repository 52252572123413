import { computed } from 'vue'
import { type Composer } from 'vue-i18n'

/**
 * Transform a path to include the current locale.
 * @example
 * ```vue
 * <routerLink :to="useLocalePath('/login', i18n)">
 *   Login
 * </routerLink>
 * ```
 */
export const useLocalePath = (to: string, i18n: Composer) => {
  return computed(() => {
    const locale = i18n.locale.value
    return `/${locale}${to}`
  })
}
