<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'

import I18nRouterLink from '@/components/utils/I18nRouterLink.vue'
import { useNavStore } from '@/stores/nav.store'

/**
 * Component: BackButton
 *
 * Description:
 * This component renders a button that navigates back to a specified route. It uses the I18nRouterLink component
 * to handle the navigation and displays an icon with a conditional label. The label "Back" is only shown when
 * the navigation menu is closed, which is controlled by the `isOpen` state from the navigation store.
 *
 * Props:
 * - to (String): The route path to navigate to when the button is clicked.
 *
 * Usage:
 * <BackButton to="/events" />
 *
 */

const { t } = useI18n()

const navStore = useNavStore()
const { isOpen } = storeToRefs(navStore)

const props = defineProps<{
  to: string
}>()
</script>

<template>
  <I18nRouterLink :to="to" class="mb-6 flex h-7 items-center gap-2 text-base text-light-grey">
    <FontAwesomeIcon
      :icon="['fal', 'circle-arrow-left']"
      :class="[isOpen ? 'ml-3.5' : 'mx-auto', 'h-[18px] w-[18px]']"
    />
    <!-- Show the text 'Back' only when the navigation menu is closed -->
    <span :class="[!isOpen && 'hidden', 'font-normal']">
      {{ t('global.back') }}
    </span>
  </I18nRouterLink>
</template>
