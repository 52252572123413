import type { components } from '@/types/swagger'

type WebformTemplate = components['schemas']['WebformTemplate']

export const emptyWebformTemplate: Partial<WebformTemplate> = {
  id: undefined,
  name: 'Neues Webform Template',
  introductionText: { de: '<p><br></p>', en: '<p><br></p>' },
  elements: [],
  isMultilanguage: false
}
