import { ref } from 'vue'

/**
 * A composable to download a file from a URL and convert it to a File object.
 *
 * @param url - The URL of the file to download.
 * @param filename - The name of the file.
 * @param mimeType - The MIME type of the file.
 *
* @returns {Promise<File>} - A promise that resolves with a File object.
 **/
export function useUrlToFile() {
	const isLoading = ref(false)

	/**
	 * Convert a URL to a File object.
	 * @param {string} url - The URL of the file to fetch.
	 * @param {string} filename - The desired filename of the File object.
	 * @param {string} mimeType - The MIME type of the File object.
	 * @returns {Promise<File>} - A promise that resolves with a File object.
	 */
	async function urlToFile(url: string, filename: string, mimeType: string): Promise<File> {
		isLoading.value = true
		try {
			const response = await fetch(url)
			const blob = await response.blob()
			return new File([blob], filename, { type: mimeType })
		} finally {
			isLoading.value = false
		}
	}

	return {
		urlToFile,
		isLoading
	}
}
