<script setup lang="ts">
import { VButton, VCheckbox, VInput, VModal, VToggleTwoOptions } from '@techcast/histoire'

import { storeToRefs } from 'pinia'
import { computed, onMounted, onUnmounted, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { onBeforeRouteLeave, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification'
import draggable from 'vuedraggable'

import EditableFormElement from '@/components/registration/EditableFormElement.vue'
import I18nRouterLink from '@/components/utils/I18nRouterLink.vue'
import QuillEditor from '@/components/utils/QuillEditor.vue'
import {
  generatelistOfAvailableInputs,
  submitButton
} from '@/composables/registration/formSharedFunctions'
import { findDifferences } from '@/composables/unsaved-changes/useFindDifferences'
import { useUnsavedChanges } from '@/composables/unsaved-changes/useUnsavedChanges'
import { cloneable } from '@/composables/useClone'
import MainLayout from '@/layouts/MainLayout.vue'
import router from '@/router'
import { useWebformTemplatesStore } from '@/stores/webformTemplates.store'
import type { FormElement } from '@/types/FormElement'
import type { components } from '@/types/swagger'

/****************************************
* NOTIFICATIONS
*****************************************/
const toast = useToast()

/****************************************
* TRANSLATIONS
*****************************************/
const { t, locale } = useI18n()

/**
 * Router
 */
const route = useRoute()

/****************************************
* TYPES
*****************************************/
type WebformTemplate = components['schemas']['WebformTemplate']
type UpdateWebformTemplateDto = components['schemas']['UpdateWebformTemplateDto']

/****************************************
* STORES
*****************************************/
const webformTemplateStore = useWebformTemplatesStore()
const { currentWebformTemplate, currentWebformTemplateLanguage } = storeToRefs(webformTemplateStore)
const {
  createWebformTemplate,
  updateWebformTemplate,
  resetCurrentWebformTemplate,
  fetchWebformTemplateById
} = webformTemplateStore

/****************************************
* LIFECYCLE HOOKS
*****************************************/
/**
 * 
 * - Fetches webform template by ID if available
 * - Initializes the form data, multilanguage checkbox, and available inputs list
 * - Adds a submit button to the form if not already present
 */
onMounted(async () => {
  if (webformTemplateId.value) {
    await fetchWebformTemplateById(+webformTemplateId.value)

    // Save a deep copy of the original webform template state
    originalWebformTemplateState.value = cloneable.deepCopy(currentWebformTemplate.value)
    hasUnsavedChanges.value = false
  } else {
    hasUnsavedChanges.value = true
  }

  form.value = currentWebformTemplate.value
  showMultilanguageCheckbox.value = !currentWebformTemplate.value.isMultilanguage

  // set the current webform template language to the first language of the supported locales always on onMounted
  currentWebformTemplateLanguage.value = 'de'

  listOfAvailableInputs.value = generatelistOfAvailableInputs()

  // TODO: Fix typing here
  const hasSubmitButton: boolean = form.value.elements.some(
    (element: FormElement) => element.$formkit === 'submit'
  )
  if (!hasSubmitButton) {
    form.value.elements.push(submitButton as FormElement)
  }
})

onUnmounted(() => {
  // Necessary to reset the current webform template when leaving the page
  resetCurrentWebformTemplate()
  nextRoute.value = ''
})

// Check for unsaved changes before navigating away
onBeforeRouteLeave((to, from, next) => {
  if (hasUnsavedChanges.value) {
    triggerUnsavedChangesModal(to.path)
    next(false) // Prevent navigation
  } else {
    next() // Allow navigation
  }
})

/****************************************
* COMPOSABLES
*****************************************/
// Composable setup
const {
  hasUnsavedChanges,
  isUnsavedChangesModalOpen,
  confirmNavigation,
  triggerUnsavedChangesModal,
  nextRoute
} = useUnsavedChanges()

/****************************************
* REFS
*****************************************/
const showMultilanguageCheckbox = ref<boolean>(!currentWebformTemplate.value.isMultilanguage) // Show the multilanguage checkbox only if the user has yet not created a multilanguage template
const form = ref(currentWebformTemplate.value)
const openEditTemplateNameModal = ref<boolean>(false) // Modal to edit the name of the webform template
const listOfAvailableInputs = ref<Array<any>>([]) // List of available inputs to drag and drop into the final form
const savedTempOldTemplateName = ref<string>('') // This variable will store the old name of the template to be used in the modal to edit the name of the template in case the user wants to cancel the action
let originalWebformTemplateState = ref<WebformTemplate | null>(null) // Clone the initial form data to compare against later

/****************************************
* COMPUTED VARIABLES
*****************************************/
const webformTemplateId = computed(() => route.params.id)

/**
 * 
 * ---------- Methods ----------
 */
/**
 * Function to handle language change for the webform template
 * @param {string} newValue - The new selected language value ('de' or 'en')
 */
function handleWebformTemplateLanguageChange(newValue: string) {
  currentWebformTemplateLanguage.value = newValue as 'de' | 'en'
}

/*
  When cloning the field, it will be checked if the element already brings an id, so that the new elements that are dragged inside the form get always a new id
  idGlobal is a global variable that will be used to give the new elements a new id and it starts with 1
*/
let idGlobal: number = 1

/**
 * Method to clone form elements while ensuring a unique ID is assigned to the new element
 * When cloning the field, it will be checked if the element already brings an id, so that the new elements that are dragged inside the form get always a new id
 * @param {FormElement} element - The form element to clone
 * @returns {FormElement} The cloned form element with a unique ID
 */
function cloneElement(element: FormElement) {
  const clonedElement: FormElement = reactive({ ...element })
  const existingIds: Array<number> =
    form.value.elements &&
    form.value.elements.map((item: any) => parseInt(item.id, 10)).filter((id: number) => !isNaN(id)) // filter out non-numeric ids

  // Find the highest existing ID
  let highestId: number = existingIds && existingIds.length > 0 ? Math.max(...existingIds) : 0

  // When I clone any element it will check for the current highest id, compare it to the idGlobal and give the element a new id that is not already in the list of elements
  if (!clonedElement.id) {
    idGlobal = highestId + 1
    clonedElement.id = parseInt(idGlobal.toString())
  }

  return clonedElement
}

/**
 * Method to update the form elements when new elements are dragged and dropped into the form
 * @param {FormElement[]} updatedElements - The updated list of form elements
 */
function updateFormElements(updatedElements: FormElement[]) {
  if (form.value) {
    form.value.elements = updatedElements
    updateElement()
  }
}


/**
 * Method to trigger re-rendering of the form elements by incrementing the refresh key
 * The component DefaultInput will emit a function to run this one and refresh the updated element inside the draggable listOfFormElements
 */
const refreshKey = ref(0)
function updateElement() {
  refreshKey.value++
}

/**
 * Method to open the modal for editing the template name and save the current name in case of cancellation
 */
function openModalAndSaveTempTemplateName() {
  savedTempOldTemplateName.value = currentWebformTemplate.value.name
  openEditTemplateNameModal.value = true
}

/**
 * Method to save the webform template, either by updating an existing one or creating a new one
 */
async function handleSaveWebformTemplate() {
  if (webformTemplateId.value) {
    await updateWebformTemplate(currentWebformTemplate.value as UpdateWebformTemplateDto)

    toast.success(t('views.templates.registration.templateUpdated'))
  } else {
    const response = await createWebformTemplate(currentWebformTemplate.value)
    if (response) {
      originalWebformTemplateState.value = cloneable.deepCopy(form.value)
      hasUnsavedChanges.value = false

      // Navigate to the update page with the new template ID
      await router.push({ name: 'templates-webform-update', params: { id: response.id } })
      toast.success(t('views.templates.registration.templateCreated'))
    }
  }

  originalWebformTemplateState.value = cloneable.deepCopy(form.value)
  hasUnsavedChanges.value = false
  showMultilanguageCheckbox.value = !currentWebformTemplate.value.isMultilanguage
}

/****************************************
* WATCHERS
*****************************************/
// This watcher will watch the form.elements and the refreshKey to update the listOfAvailableInputs and the currentWebformTemplate.elements and also to track unsaved changes
watch(
  () => [
    form.value,
    refreshKey.value,
    currentWebformTemplate.value.name,
    currentWebformTemplate.value.isMultilanguage
  ],
  () => {
    listOfAvailableInputs.value = generatelistOfAvailableInputs()

    if (originalWebformTemplateState.value) {
      // Compare each property
      const differences = findDifferences(originalWebformTemplateState.value, form.value)
      // Update hasUnsavedChanges
      hasUnsavedChanges.value = differences.length > 0
    }
  },
  {
    deep: true // Enable deep watching to watch nested properties of objects
  }
)
</script>

<template>
  <MainLayout>
    <section class="w-full text-dark-grey dark:text-light-grey">
      <!-- the class 'group/form' triggers the form validation classes of the submit button -->
      <form @submit.prevent @keydown.enter.prevent class="group/form" novalidate>
        <div class="flex flex-col items-start">
          <!-- If the webformTemplateId exists, the name of the template will be displayed and it's editable, otherwise an input field will be displayed to give it a name -->
          <div v-if="webformTemplateId" class="relative">
            <h1 class="mr-8 text-[32px] font-bold lg:text-[42px] xl:text-[58px]">
              {{ currentWebformTemplate.name || t('views.templates.registration.newForm') }}
            </h1>
            <VButton
              type="button"
              appearance="empty"
              size="medium"
              class="absolute right-0 top-0 cursor-pointer"
              :functionOnClick="openModalAndSaveTempTemplateName"
            >
              <FontAwesomeIcon :icon="['fal', 'pen-circle']" class="size-5 p-1.5" />
            </VButton>
          </div>
          <VInput
            v-else
            v-model="currentWebformTemplate.name"
            :label="t('global.name')"
            placeholder="Webform template's name"
            help=""
            class="w-300px"
            type="text"
            inputId="webform-template-name"
            :required="true"
            :tooltip="t('global.requiredField')"
            :errorMessage="t('global.invalidValue')"
          />
          <div class="my-5 mr-auto flex w-full flex-row items-center justify-start gap-4">
            <VToggleTwoOptions
              v-if="currentWebformTemplate.isMultilanguage"
              v-model="currentWebformTemplateLanguage"
              input-id="currentEventLanguage"
              leftOptionValue="de"
              rightOptionValue="en"
              @change="handleWebformTemplateLanguageChange"
            />
            <VCheckbox
              v-if="showMultilanguageCheckbox"
              v-model="currentWebformTemplate.isMultilanguage"
              input-id="webform-template-multilanguage"
              :label="t('views.templates.registration.webformTemplateIsMultilanguage')"
              :help="t('views.templates.registration.webformTemplateIsMultilanguage')"
              class="w-300px"
            />
          </div>
        </div>
        <div class="prose-ul:list-none">
          <div
            class="h-[calc(100svh-19.5rem)] overflow-y-scroll rounded-lg bg-white p-10 shadow dark:bg-dark-grey"
          >
            <div class="mb-4">
              <QuillEditor
                :inputId="'webform-template-description'"
                ref="quillEditorRegistrationTemplate"
                :label="t('views.events.registration.introductionText')"
                v-model:content="form.introductionText[currentWebformTemplateLanguage]"
                contentType="html"
                :placeholder="t('views.events.registration.introductionText')"
              />
            </div>
            <div class="mb-4 grid grid-cols-1 gap-4 lg:grid-cols-12 lg:gap-4">
              <div class="relative z-10 lg:col-span-4 2xl:col-span-3">
                <h3 class="mb-4">
                  <strong>{{ t('views.events.registration.elements') }}</strong>
                </h3>
                <draggable
                  v-model="listOfAvailableInputs"
                  itemKey="id"
                  :animation="300"
                  :group="{ name: 'form-items', pull: 'clone', put: false }"
                  :sort="false"
                  :clone="cloneElement"
                  :filter="'.cursor-not-allowed'"
                  :onEnd="updateElement"
                  class="flex flex-row flex-wrap gap-4 rounded bg-transparent xl:flex-col"
                >
                  <template #item="{ element, index }">
                    <div
                      :key="element.id"
                      class="w-full min-w-[132px] rounded border border-misty-grey/20 p-6 text-center shadow-sm shadow-ice-grey
                        sm:max-w-[145px] lg:max-w-full dark:shadow-black/5"
                      :class="
                        element.disabled
                          ? 'cursor-not-allowed bg-ice-grey/60 opacity-50 dark:bg-misty-grey/60'
                          : 'cursor-move bg-ice-grey dark:bg-misty-grey'
                      "
                    >
                      {{ element.displayedName[locale] }}
                    </div>
                  </template>
                </draggable>
              </div>

              <div class="lg:col-span-8 2xl:col-span-9">
                <h3 class="mb-4">
                  <strong>{{ t('global.form') }}</strong>
                </h3>
                <draggable
                  v-model="form.elements"
                  itemKey="id"
                  :animation="300"
                  group="form-items"
                  handle=".handle"
                  class="relative flex h-[calc(100%-2.5rem)] flex-col gap-4 overflow-auto rounded bg-ice-grey p-4 pb-28
                    dark:bg-misty-grey"
                >
                  <template #item="{ element, index }">
                    <div
                      :class="
                        element.$formkit === 'submit' &&
                        'absolute bottom-4 left-4 flex w-[calc(100%-2rem)] justify-center'
                      "
                    >
                      <EditableFormElement
                        :locale="currentWebformTemplateLanguage"
                        :element="element"
                        :key="refreshKey"
                        :list="form.elements"
                        class="bg-white dark:bg-dark-grey"
                        @updateFormElements="updateFormElements"
                      />
                    </div>
                  </template>
                </draggable>
              </div>
            </div>
          </div>
          <div class="mt-10 flex justify-end gap-8">
            <I18nRouterLink to="/templates/webform" class="overflow-hidden">
              <VButton
                type="button"
                appearance="cancel"
                :label="t('global.cancel')"
                :disabled="false"
                size="large"
                :functionOnClick="confirmNavigation"
              />
            </I18nRouterLink>
            <VButton
              type="submit"
              appearance="default"
              :label="currentWebformTemplate.id ? t('global.update') : t('global.save')"
              :disabled="!hasUnsavedChanges"
              size="large"
              :functionOnClick="handleSaveWebformTemplate"
            />
          </div>
        </div>
      </form>
    </section>
    <!-- Modal to edit the name of the webform template -->
    <VModal
      v-model:trigger="openEditTemplateNameModal"
      :functionOnClose="
        () => {
          currentWebformTemplate.name === ''
            ? (currentWebformTemplate.name = savedTempOldTemplateName)
            : currentWebformTemplate.name
          savedTempOldTemplateName = ''
          openEditTemplateNameModal = false
        }
      "
      includeForm
    >
      <template #modalBody>
        <VInput
          v-model="currentWebformTemplate.name"
          :label="t('global.name')"
          placeholder="Webform template's name"
          help=""
          class="w-300px"
          type="text"
          inputId="webform-template-name"
          :required="true"
          :tooltip="t('global.requiredField')"
          :errorMessage="t('global.invalidValue')"
        />
      </template>
      <template #modalFooter>
        <div class="flex justify-end">
          <VButton
            type="submit"
            size="medium"
            appearance="default"
            :label="t('global.close')"
            :functionOnClick="() => (openEditTemplateNameModal = false)"
          />
        </div>
      </template>
    </VModal>
    <!-- Unsaved Changes Modal -->
    <VModal
      :trigger="isUnsavedChangesModalOpen"
      :function-on-close="
        () => {
          isUnsavedChangesModalOpen = false
        }
      "
    >
      <template #modalHeader>
        <p class="text-center text-xl uppercase text-dark-grey dark:text-light-grey">
          {{ t('views.events.index.unsavedChangesTitle') }}
        </p>
      </template>
      <template #modalBody>
        <p class="text-dark-grey dark:text-light-grey">
          {{ t('views.events.index.unsavedChangesMessage') }}
        </p>
      </template>
      <template #modalFooter>
        <div class="flex justify-between">
          <VButton
            type="button"
            appearance="cancel"
            :label="t('global.cancel')"
            size="medium"
            :functionOnClick="
              () => {
                isUnsavedChangesModalOpen = false
              }
            "
          />
          <VButton
            type="button"
            appearance="default"
            :label="t('global.continue')"
            size="medium"
            :functionOnClick="confirmNavigation"
          />
        </div>
      </template>
    </VModal>
  </MainLayout>
</template>
