<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { RouterLink } from 'vue-router'

import { useLocalePath } from '@/utils/useLocalePath'

const props = defineProps<{
  to: string
}>()

const i18n = useI18n()

const localePath = useLocalePath(props.to, i18n)

// This is a wrapper for RouterLink that injects the current locale into the url
</script>

<template>
  <RouterLink :to="localePath" v-bind="$attrs">
    <slot v-bind="$attrs" v-on="$attrs" />
  </RouterLink>
</template>
