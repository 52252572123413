/**
 * Find the differences between two objects.
 * @param obj1 The original object.
 * @param obj2 The updated object.
 * @returns An array of differences between the two objects.
 * @example
 * const obj1 = {
 *  name: 'Alice',
 * age: 30,
 * address: {
 * street: '123 Main St',
 * city: 'Wonderland'
 * },
 * hobbies: ['Reading', 'Cycling']
 * }
 * 
 * const obj2 = {
 * name: 'Alice',
 * age: 31, // Changed
 * address: {
 * street: '123 Main St',
 * city: 'Wonderland'
 * },
 * hobbies: ['Reading', 'Swimming'] // Changed
 * }
 * 
 * const differences = findDifferences(obj1, obj2)
 * console.log(differences)
 * 
 */

import { cloneable } from "../useClone"

interface Difference {
	path: string
	originalValue: any
	updatedValue: any
}

export function findDifferences(originalObj: any, newObj: any): Difference[] {
	const differences: Difference[] = []

	function compareObjects(o1: any, o2: any, currentPath: string = '') {
		// Normalize Date comparison: Convert both to ISO strings, ignore milliseconds
		if ((typeof o1 === 'string' && !isNaN(Date.parse(o1))) || o1 instanceof Date) {
			o1 = new Date(o1).toISOString().replace(/\.\d{3}Z$/, 'Z');
		}
		if ((typeof o2 === 'string' && !isNaN(Date.parse(o2))) || o2 instanceof Date) {
			o2 = new Date(o2).toISOString().replace(/\.\d{3}Z$/, 'Z');
		}

		// Handle both values being strictly equal
		if (o1 === o2) {
			return;
		}

		// Handle one value being undefined
		if (o1 === undefined || o2 === undefined) {
			differences.push({
				path: currentPath,
				originalValue: o1,
				updatedValue: o2
			});
			return;
		}

		// Handle objects and arrays
		if (typeof o1 === 'object' && typeof o2 === 'object') {
			if (Array.isArray(o1) && Array.isArray(o2)) {
				if (o1.length !== o2.length) {
					differences.push({
						path: currentPath,
						originalValue: o1,
						updatedValue: o2
					});
					return;
				}
				o1.forEach((item: any, index: number) => {
					compareObjects(item, o2[index], `${currentPath}[${index}]`);
				});
				return;
			}

			// Compare object keys
			const allKeys = new Set([...Object.keys(o1), ...Object.keys(o2)]);

			allKeys.forEach((key) => {
				const newPath = currentPath ? `${currentPath}.${key}` : key;
				compareObjects(o1[key], o2[key], newPath);
			});
		} else {
			// Handle primitive values
			differences.push({
				path: currentPath,
				originalValue: o1,
				updatedValue: o2
			});
		}
	}

	compareObjects(cloneable.deepCopy(originalObj), cloneable.deepCopy(newObj))
	return differences
}
