import { nextTick } from 'vue'
import type { DesignColorVariables } from '@/types/DesignColorVariables'

export function updateColors(colors: DesignColorVariables) {

	nextTick(() => {
		document.documentElement.style.setProperty(
			'--page-background-color',
			colors.backgroundColor
		)
		document.documentElement.style.setProperty(
			'--primary-text-color',
			colors.primaryTextColor
		)
		document.documentElement.style.setProperty(
			'--header-background-color',
			colors.headerBackgroundColor
		)
		document.documentElement.style.setProperty(
			'--header-text-color',
			colors.headerTextColor
		)
		document.documentElement.style.setProperty(
			'--footer-background-color',
			colors.footerBackgroundColor
		)
		document.documentElement.style.setProperty(
			'--footer-text-color',
			colors.footerTextColor
		)
		document.documentElement.style.setProperty(
			'--button-background-color',
			colors.buttonBackgroundColor
		)
		document.documentElement.style.setProperty(
			'--button-text-color',
			colors.buttonTextColor
		)
		document.documentElement.style.setProperty(
			'--button-hover-color',
			colors.buttonHoverBackgroundColor
		)
		document.documentElement.style.setProperty(
			'--form-background-color',
			colors.formBackgroundColor
		)
		document.documentElement.style.setProperty(
			'--form-text-color',
			colors.formTextColor
		)
		document.documentElement.style.setProperty(
			'--boxes-background-color',
			colors.boxesBackgroundColor
		)
		document.documentElement.style.setProperty(
			'--boxes-text-color',
			colors.boxesTextColor
		)
	})
}
