import { computed, ref, watch, type Ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toastification'
import type { components } from '@/types/swagger'

/****************************************
* TYPES
*****************************************/
export type User = components['schemas']['User'] & { password?: string }

interface UseUserSetupReturn {
	changePassword: Ref<boolean>
	changePasswordDropdown: Ref<HTMLElement | null>
	confirmPassword: Ref<string>
	confirmPasswordErrorMessage: Ref<string>
	currentPassword: Ref<string>
	generateRandomPassword: (length?: number) => string
	isUserModalOpen: Ref<boolean>
	locale: Ref<string>
	onSelectRole: (user: User, option: { label: string; value: string }) => void
	password: Ref<string>
	passwordLength: Ref<number>
	passwordValidationMessages: Ref<string[]>
	originalUserState: Ref<components['schemas']['User'] | null>
	resetPasswordFields: () => void
	toast: ReturnType<typeof useToast>
	t: (key: string) => string
	toggleChangePassword: () => void
	validatePassword: (passwordValue: string) => boolean
}

export function useUserSetup(): UseUserSetupReturn {
	const { t, locale } = useI18n()
	const toast = useToast()

	// Refs and reactive variables
	const originalUserState = ref<components['schemas']['User'] | null>(null)
	const isUserModalOpen = ref(false)
	// Password related
	const changePassword = ref(false)
	const changePasswordDropdown = ref() // DOM node to implement auto-animate
	const currentPassword = ref('')
	const password = ref('')
	const confirmPassword = ref('')
	const confirmPasswordErrorMessage = ref('')
	const passwordLength = ref(12)

	/****************************************
* METHODS
*****************************************/
	// Password validation function
	function validatePassword(passwordValue: string): boolean {
		const errors: string[] = []

		const minLength = /.{10,}/
		const hasUppercase = /[A-Z]/
		const hasLowercase = /[a-z]/
		const hasNumber = /\d/
		const hasSymbol = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]/

		if (!minLength.test(passwordValue)) {
			errors.push(t('views.user.passwordErrorCharacters'))
		}
		if (!hasUppercase.test(passwordValue)) {
			errors.push(t('views.user.passwordErrorUppercase'))
		}
		if (!hasLowercase.test(passwordValue)) {
			errors.push(t('views.user.passwordErrorLowercase'))
		}
		if (!hasNumber.test(passwordValue)) {
			errors.push(t('views.user.passwordErrorNumbers'))
		}
		if (!hasSymbol.test(passwordValue)) {
			errors.push(t('views.user.passwordErrorSymbols'))
		}

		// Update the reactive variable with current errors
		passwordValidationMessages.value = errors

		return errors.length === 0
	}

	// Computed property for password validation messages
	const passwordValidationMessages = computed(() => {
		const errors: string[] = []

		if (password.value === '') {
			errors.push(t('views.user.passwordErrorCharacters'))
			errors.push(t('views.user.passwordErrorUppercase'))
			errors.push(t('views.user.passwordErrorLowercase'))
			errors.push(t('views.user.passwordErrorNumbers'))
			errors.push(t('views.user.passwordErrorSymbols'))
		} else {
			if (!/.{10,}/.test(password.value)) {
				errors.push(t('views.user.passwordErrorCharacters'))
			}
			if (!/[A-Z]/.test(password.value)) {
				errors.push(t('views.user.passwordErrorUppercase'))
			}
			if (!/[a-z]/.test(password.value)) {
				errors.push(t('views.user.passwordErrorLowercase'))
			}
			if (!/\d/.test(password.value)) {
				errors.push(t('views.user.passwordErrorNumbers'))
			}
			if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]/.test(password.value)) {
				errors.push(t('views.user.passwordErrorSymbols'))
			}
		}

		return errors
	})

	function generateRandomPassword(length: number = 12): string {
		
		// Define character sets for each pattern
		const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
		const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz';
		const numbers = '0123456789';
		const symbols = '!@#$%^&*()_+-=[]{};:\'",.<>/?`~|\\';

		// Function to get a random character from a given set
		function getRandomCharacter(set: string): string {
			return set.charAt(Math.floor(Math.random() * set.length));
		}

		// Ensure at least one character from each required set
		let generatedPassword = '';
		generatedPassword += getRandomCharacter(uppercaseLetters);
		generatedPassword += getRandomCharacter(lowercaseLetters);
		generatedPassword += getRandomCharacter(numbers);
		generatedPassword += getRandomCharacter(symbols);

		// Fill the rest of the generatedPassword length with random characters from all sets
		const allCharacters = uppercaseLetters + lowercaseLetters + numbers + symbols;
		for (let i = generatedPassword.length; i < length; i++) {
			generatedPassword += getRandomCharacter(allCharacters);
		}

		// Shuffle the generatedPassword to ensure the order is random
		generatedPassword = generatedPassword.split('').sort(() => Math.random() - 0.5).join('');

		password.value = generatedPassword
		confirmPassword.value = generatedPassword

		return generatedPassword
	}

	function resetPasswordFields() {
		password.value = ''
		confirmPassword.value = ''
		confirmPasswordErrorMessage.value = ''
		if (currentPassword.value) {
			currentPassword.value = ''
		}
		changePassword.value = false
	}

	function toggleChangePassword() {
		changePassword.value = !changePassword.value
		if (!changePassword.value) {
			resetPasswordFields()
		}
	}

	// This function will select a role from the list of available roles
	function onSelectRole(user: User, option: { label: string; value: string }) {
		if (user) {
			user.roles = [option.value] // Update user.roles with the selected role's value
		}
	}

	// Watch for locale changes and ensure validation messages update
	watch(locale, () => {
		// Trigger validation to update messages
		validatePassword(password.value)
	}, { immediate: true })

	return {
		changePassword,
		changePasswordDropdown,
		confirmPassword,
		confirmPasswordErrorMessage,
		currentPassword,
		generateRandomPassword,
		isUserModalOpen,
		locale,
		onSelectRole,
		originalUserState,
		password,
		passwordLength,
		passwordValidationMessages,
		resetPasswordFields,
		t,
		toast,
		toggleChangePassword,
		validatePassword
	}
}
