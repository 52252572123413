import { defineStore } from 'pinia'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toastification'

import { emptyDownload } from '@/stores/objects/emptyDownload'
import { emptyDownloadFile } from '@/stores/objects/emptyDownloadFile'
import { emptyLink } from '@/stores/objects/emptyLink'
import type { DownloadFile } from '@/types/DownloadFile'
import type { components } from '@/types/swagger'
import { tcFetch } from '@/utils/tcFetch'

type Download = components['schemas']['Download']
type Link = components['schemas']['Link']
type CreateLinkDto = components['schemas']['CreateLinkDto']
type UpdateLinkDto = components['schemas']['UpdateLinkDto']

export const useDownloadsLinksStore = defineStore('downloadsLinks', () => {
  /**
   * ----- Internal Variables -----
   */
  const url = import.meta.env.VITE_BACKEND_URL
  const { t } = useI18n()
  const toast = useToast()

  /**
   * ----- Reactive Variables -----
   */

  const currentDownload = ref<Download>(structuredClone(emptyDownload) as Download)
  const currentDownloadFile = reactive(structuredClone(emptyDownloadFile) as DownloadFile)
  const currentLink = ref<Link>(structuredClone(emptyLink) as Link)

  /**
   * ----- CRUD Actions -----
   */

  /**
   * Save a download to the backend
   *
   * @param eventId - The ID of the event the download belongs to
   * @param name - The name of the download
   * @param file - The file to upload
   *
   * @returns A promise that resolves to the saved download
   */
  const createDownload = async (eventId: number, name: string, file: File): Promise<Download> => {
    const formData = new FormData()
    formData.append('name', name)
    formData.append('file', file)

    const response = await tcFetch('POST', `${url}/events/${eventId}/downloads`, formData, true)

    // noinspection Duplicates
    if (!response.ok) {
      const errorText = await response.json()
      // Check if errorText.message is an array
      if (Array.isArray(errorText.message)) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: string) => {
          toast.error(`${t('views.assets.failedUploadAsset')}: ${errorMessage}`)
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(`${t('views.assets.failedUploadAsset')}: ${errorText.message}`)
      }
    }

    return await response.json()
  }

  /**
   * Save a Link to the backend
   *
   * @param eventId - The ID of the event the link belongs to
   * @param createLinkDto - The link to save
   *
   * @returns A promise that resolves to the saved link
   */
  const createLink = async (eventId: number, createLinkDto: CreateLinkDto): Promise<Link> => {
    const response = await tcFetch('POST', `${url}/events/${eventId}/links`, createLinkDto)

    if (!response.ok) {
      // TODO: Check i18n messages and test error handling
      const errorText = await response.json()
      if (Array.isArray(errorText.message) && errorText.message.length > 0) {
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(
            `${t('views.events.downloadsLinks.failedCreateLink')}: ${errorMessage.message}`
          )
        })
      } else {
        toast.error(`${t('views.events.downloadsLinks.failedCreateLink')}: ${errorText.message}`)
      }
    }

    return await response.json()
  }

  /**
   * Fetch a download by ID
   *
   * @param downloadId - The ID of the download to fetch
   *
   * @returns A promise that resolves to the fetched download
   */
  const fetchDownloadById = async (downloadId: number): Promise<Download> => {
    const response = await tcFetch('GET', `${url}/downloads/${downloadId}`)

    if (!response.ok) {
      throw new Error(`Failed to fetch download. Status: ${response.status} ${response.statusText}`)
    }

    const data = await response.json()
    // Update the currentDownload object with the fetched data
    currentDownload.value = { ...data }

    return data
  }

  /**
   * Fetch a link by ID
   *
   * @param linkId - The ID of the link to fetch
   *
   * @returns A promise that resolves to the fetched link
   */
  const fetchLinkById = async (linkId: number): Promise<Link> => {
    const response = await tcFetch('GET', `${url}/links/${linkId}`)

    if (!response.ok) {
      throw new Error(`Failed to fetch link. Status: ${response.status} ${response.statusText}`)
    }

    const data = await response.json()
    // Update the currentLink object with the fetched data
    currentLink.value = { ...data }

    return data
  }

  /**
   * Update an existing download
   *
   * @param downloadId - The ID of the download to update
   * @param name - The new name of the download
   * @param file - (optional) The new file to upload
   *
   * @returns A promise that resolves to the updated download
   */
  const updateDownload = async (downloadId: number, name: string, file?: File) => {
    const formData = new FormData()
    formData.append('name', name)
    if (file) {
      formData.append('file', file)
    }

    console.log('formData', formData)

    const response = await tcFetch('PATCH', `${url}/downloads/${downloadId}`, formData, true)

    if (!response.ok) {
      const errorText = await response.json()
      if (Array.isArray(errorText.message)) {
        errorText.message.forEach((errorMessage: string) => {
          toast.error(`${t('views.events.downloadsLinks.failedUpdateDownload')}: ${errorMessage}`)
        })
      } else {
        toast.error(
          `${t('views.events.downloadsLinks.failedUpdateDownload')}: ${errorText.message}`
        )
      }
    }

    return await response.json()
  }

  /**
   * Update an existing link
   *
   * @param linkId - The ID of the link to update
   * @param updateLinkDto - The updated link
   *
   * @returns A promise that resolves to the updated link
   */
  const updateLink = async (linkId: number, updateLinkDto: UpdateLinkDto): Promise<Link> => {
    const response = await tcFetch('PATCH', `${url}/links/${linkId}`, updateLinkDto)

    if (!response.ok) {
      // TODO: Check i18n messages and test error handling
      const errorText = await response.json()
      if (Array.isArray(errorText.message) && errorText.message.length > 0) {
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(
            `${t('views.events.downloadsLinks.failedUpdateLink')}: ${errorMessage.message}`
          )
        })
      } else {
        toast.error(`${t('views.events.downloadsLinks.failedUpdateLink')}: ${errorText.message}`)
      }
    }

    return await response.json()
  }

  /**
   * Delete a download
   *
   * @param downloadId - The ID of the download to delete
   *
   * @returns The response from the backend (200, 401, 500).
   */
  const deleteDownload = async (downloadId: number): Promise<Response> => {
    const response = await tcFetch('DELETE', `${url}/downloads/${downloadId}`)

    if (!response.ok) {
      // TODO: Check i18n messages and test error handling
      const errorText = await response.json()
      if (Array.isArray(errorText.message)) {
        errorText.message.forEach((errorMessage: string) => {
          toast.error(`${t('views.events.downloadsLinks.failedDeleteDownload')}: ${errorMessage}`)
        })
      } else {
        toast.error(
          `${t('views.events.downloadsLinks.failedDeleteDownload')}: ${errorText.message}`
        )
      }
    }

    return response
  }

  /**
   * Delete a link
   *
   * @param linkId - The ID of the link to delete
   *
   * @returns The response from the backend (200, 401, 500).
   */
  const deleteLink = async (linkId: number): Promise<Response> => {
    const response = await tcFetch('DELETE', `${url}/links/${linkId}`)

    if (!response.ok) {
      const errorText = await response.json()
      if (Array.isArray(errorText.message)) {
        errorText.message.forEach((errorMessage: string) => {
          toast.error(`${t('views.events.downloadsLinks.failedDeleteLink')}: ${errorMessage}`)
        })
      } else {
        toast.error(`${t('views.events.downloadsLinks.failedDeleteLink')}: ${errorText.message}`)
      }
    }

    return response
  }

  /**
   * ----- Helper Functions -----
   */

  /**
   * Reset the currentDownload object to its initial state
   */
  const resetCurrentDownload = () => {
    currentDownload.value = structuredClone(emptyDownload) as Download
  }

  /**
   * Reset the currentDownloadFile object to its initial state
   */
  const resetCurrentDownloadFile = () => {
    Object.assign(currentDownloadFile, structuredClone(emptyDownloadFile) as DownloadFile)
  }

  /**
   * Reset the currentLink object to its initial state
   */
  const resetCurrentLink = () => {
    currentLink.value = structuredClone(emptyLink) as Link
  }

  return {
    currentDownload,
    currentLink,
    currentDownloadFile,
    createDownload,
    createLink,
    fetchDownloadById,
    fetchLinkById,
    updateDownload,
    updateLink,
    deleteDownload,
    deleteLink,
    resetCurrentDownload,
    resetCurrentDownloadFile,
    resetCurrentLink
  }
})
