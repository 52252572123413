<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import NavIcon from '@/components/nav/utilities/NavIcon.vue'
import NavLink from '@/components/nav/utilities/NavLink.vue'

/**
 * Component: NavMenu
 *
 * Description:
 * This component renders a navigation menu with links to different sections of the application. 
 *
 * Components:
 * - `NavIcon`: A component for styling or positioning the icon within each navigation item.
 * - `NavLink`: A component that provides styled navigation links.
 * - `FontAwesomeIcon`: Used for rendering Font Awesome icons.
 * 
 */

 /****************************************
 * TRANSLATIONS
 *****************************************/
const { t } = useI18n()
</script>

<template>
  <section>
    <NavLink to="/events">
      <NavIcon>
        <FontAwesomeIcon :icon="['fal', 'calendar-days']" />
      </NavIcon>
      <span>Events</span>
    </NavLink>
    <NavLink to="/templates/webform">
      <NavIcon>
        <FontAwesomeIcon :icon="['fal', 'grid-2-plus']" />
      </NavIcon>
      <span>Templates</span>
    </NavLink>
    <NavLink to="/speakers">
      <NavIcon>
        <FontAwesomeIcon :icon="['fal', 'headset']" />
      </NavIcon>
      <span>{{ t('global.speakers') }}</span>
    </NavLink>
  </section>
</template>
