<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { twMerge } from 'tailwind-merge'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import NavLink from '@/components/nav/utilities/NavLink.vue'
import { useAuthStore } from '@/stores/auth.store'

const props = withDefaults(
  defineProps<{
    collapse: boolean
  }>(),
  {
    collapse: true
  }
)

// emit an update event when the closing cross is clicked
const emit = defineEmits<{
  'update:collapse': [collapse: boolean]
}>()

const { t } = useI18n()

/****************************************
* STORES
*****************************************/
const userStore = useAuthStore()
const { user } = storeToRefs(userStore)

const userInfoMenu = ref(null)

onClickOutside(userInfoMenu, (event) => {
  if (event.target !== userInfoMenu.value) {
    emit('update:collapse', true)
  }
})

// TODO: Delay class 'hidden' so the animation can play
</script>

<template>
  <div
    ref="userInfoMenu"
    :class="
      twMerge(
        `flex w-full flex-col gap-y-3 rounded-lg bg-glass-misty-grey/75 p-6 px-[1.375rem] backdrop-blur
        transition-opacity [&>a]:text-xl`,
        collapse && 'hidden opacity-0'
      )
    "
  >
    <NavLink to="/profile" class="cursor-not-allowed pl-0">
      <FontAwesomeIcon :icon="['fal', 'circle-user']" class="mr-2 size-5 p-1.5" />
      <span>{{ t('components.nav.UserInfoMenu.profile') }}</span>
    </NavLink>
    <NavLink v-if="user?.roles.includes('admin')" to="/management" class="cursor-not-allowed pl-0">
      <FontAwesomeIcon :icon="['fal', 'users']" class="mr-2 size-5 p-1.5" />
      <span>{{ t('views.user.usersManagement') }}</span>
    </NavLink>
    <NavLink to="/certificates" class="cursor-not-allowed pl-0">
      <FontAwesomeIcon :icon="['fal', 'file-certificate']" class="mr-2 size-5 p-1.5" />
      <span>{{ t('components.nav.UserInfoMenu.certificates') }}</span>
    </NavLink>
    <NavLink :noLocale="true" to="/logout" class="pl-0">
      <FontAwesomeIcon :icon="['fal', 'arrow-right-from-bracket']" class="mr-2 size-5 p-1.5" />
      <span>{{ t('global.logout') }}</span>
    </NavLink>
    <font-awesome-icon
      @click="emit('update:collapse', !collapse)"
      :icon="['fas', 'x']"
      class="absolute right-1.5 top-1.5 cursor-pointer p-1 text-sm text-light-grey"
    />
  </div>
</template>
