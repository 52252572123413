import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toastification'

import { emptyWebformTemplate } from '@/stores/objects/emptyWebformTemplate'
import type { components } from '@/types/swagger'
import { tcFetch } from '@/utils/tcFetch'

type WebformTemplate = components['schemas']['WebformTemplate']
type CreateWebformTemplateDto = components['schemas']['CreateWebformTemplateDto']
type UpdateWebformTemplateDto = components['schemas']['UpdateWebformTemplateDto']

export const useWebformTemplatesStore = defineStore('webformTemplates', () => {
  /**
   * ----- Internal Variables -----
   */

  const url = import.meta.env.VITE_BACKEND_URL
  const { t } = useI18n()
  const toast = useToast()

  /**
   * ----- Reactive Variables -----
   */

  const webformTemplates = ref<WebformTemplate[]>([])
  const currentWebformTemplate = ref<WebformTemplate>(
    structuredClone(emptyWebformTemplate) as WebformTemplate
  )
  const currentWebformTemplateLanguage = ref<'de' | 'en'>('de')

  /**
   * ----- CRUD Actions -----
   */

  /**
   * Save an webformTemplate to the backend.
   * If the request is successful, add the webformTemplate to the webformTemplates array and sort it.
   *
   * @param createWebformTemplateDto - The webformTemplate object to save.
   *
   * @returns The saved webformTemplate.
   */
  const createWebformTemplate = async (
    createWebformTemplateDto: CreateWebformTemplateDto
  ): Promise<WebformTemplate> => {
    const response = await tcFetch('POST', `${url}/templates/webforms`, createWebformTemplateDto)

    if (!response.ok) {
      // TODO: Bind validation messages to forms
      const errorText = await response.json()
      // Check if errorText is an array
      if (Array.isArray(errorText.message) && errorText.message.length > 0) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(
            `${t('views.templates.registration.failedCreateTemplate')}: ${errorMessage.message}`
          )
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(
          `${t('views.templates.registration.failedCreateTemplate')}: ${errorText.message}`
        )
      }
    }

    const data = await response.json()

    webformTemplates.value.push(structuredClone(data))
    currentWebformTemplate.value = structuredClone(data)

    return data
  }

  /**
   * Fetch webformTemplates from the backend and update the webformTemplates array.
   *
   * @returns A promise with the fetched webformTemplates.
   */
  const fetchAllWebformTemplates = async (): Promise<WebformTemplate[]> => {
    const response = await tcFetch('GET', `${url}/templates/webforms`)

    if (!response.ok) {
      throw new Error(
        `Failed to fetch webformTemplates. Status: ${response.status} ${response.statusText}`
      )
    }

    const data = await response.json()

    // Assign the fetched data to the webformTemplates array.
    webformTemplates.value = structuredClone(data)

    sortWebformTemplates(webformTemplates.value)

    return data
  }

  /**
   * Fetch a single webformTemplate by its ID from the backend.
   *
   * @param webformTemplateId - The ID of the webformTemplate to fetch.
   *
   * @returns The webformTemplate object.
   */
  const fetchWebformTemplateById = async (webformTemplateId: number): Promise<WebformTemplate> => {
    const response = await tcFetch('GET', `${url}/templates/webforms/${webformTemplateId}`)

    if (!response.ok) {
      throw new Error(
        `Failed to fetch webformTemplates. Status: ${response.status} ${response.statusText}`
      )
    }

    const data = await response.json()

    // Assign the fetched data to the currentWebformTemplate object.
    currentWebformTemplate.value = structuredClone(data) as WebformTemplate

    return data
  }

  /**
   * Update an existing webformTemplate.
   * If the request is successful, update the webformTemplate in the webformTemplates array and sort it.
   *
   * @param updateWebformTemplateDto - The webformTemplate object to update.
   *
   * @returns The updated webformTemplate.
   */
  const updateWebformTemplate = async (updateWebformTemplateDto: UpdateWebformTemplateDto) => {
    const response = await tcFetch(
      'PATCH',
      `${url}/templates/webforms/${updateWebformTemplateDto.id}`,
      updateWebformTemplateDto
    )

    if (!response.ok) {
      const errorText = await response.json()
      // Check if errorText is an array
      if (Array.isArray(errorText.message) && errorText.message.length > 0) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(
            `${t('views.templates.registration.failedUpdateTemplate')}: ${errorMessage.message}`
          )
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(
          `${t('views.templates.registration.failedUpdateTemplate')}: ${errorText.message}`
        )
      }
    }

    const data = await response.json()

    const index = webformTemplates.value.findIndex(
      (webformTemplate) => String(webformTemplate.id) === String(updateWebformTemplateDto.id)
    )

    webformTemplates.value[index] = structuredClone(data)
    currentWebformTemplate.value = structuredClone(data)

    return data
  }

  /**
   * Delete an webformTemplate from the backend.
   * If the request is successful, remove the webformTemplate from the webformTemplates array.
   *
   * @param webformTemplateId - The ID of the webformTemplate to delete.
   *
   * @returns The response from the backend (200, 401, 500).
   */
  const deleteWebformTemplate = async (webformTemplateId: number): Promise<Response> => {
    const response = await tcFetch('DELETE', `${url}/templates/webforms/${webformTemplateId}`)

    if (!response.ok) {
      const errorText = await response.json()
      // Check if errorText is an array
      if (Array.isArray(errorText.message) && errorText.message.length > 0) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(
            `${t('views.templates.registration.failedDeleteTemplate')}: ${errorMessage.message}`
          )
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(
          `${t('views.templates.registration.failedDeleteTemplate')}: ${errorText.message}`
        )
      }
    }

    webformTemplates.value = webformTemplates.value.filter(
      (webformTemplate) => String(webformTemplate.id) !== String(webformTemplateId)
    )

    sortWebformTemplates(webformTemplates.value)

    resetCurrentWebformTemplate()

    return response
  }

  /**
   * ----- Helper Functions -----
   */

  /**
   * Reset the currentWebformTemplate object to its initial state (emptyWebformTemplate).
   * This function can be called when adding a new webformTemplate.
   */
  const resetCurrentWebformTemplate = () => {
    currentWebformTemplate.value = structuredClone(emptyWebformTemplate) as WebformTemplate
  }

  /**
   * Sort the webformTemplates array by the updated_at property.
   */
  const sortWebformTemplates = (webformTemplates: WebformTemplate[]) => {
    // sort by updated_at
    webformTemplates.sort((a, b) => {
      return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
    })
  }

  return {
    webformTemplates,
    currentWebformTemplate,
    currentWebformTemplateLanguage,
    fetchAllWebformTemplates,
    fetchWebformTemplateById,
    createWebformTemplate,
    updateWebformTemplate,
    deleteWebformTemplate,
    resetCurrentWebformTemplate,
    sortWebformTemplates
  }
})
