<script setup lang="ts">
import {
  VButton,
  VInput,
  VModal,
  VRangeSlider,
  VSelect,
  VTable,
  type VTableColumn
} from '@techcast/histoire'

import autoAnimate from '@formkit/auto-animate'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { storeToRefs } from 'pinia'
import { type ComputedRef, computed, onMounted, onUnmounted, ref } from 'vue'
import { watch } from 'vue'
import { RouterLink } from 'vue-router'

import { findDifferences } from '@/composables/unsaved-changes/useFindDifferences'
import { useUnsavedChanges } from '@/composables/unsaved-changes/useUnsavedChanges'
import { cloneable } from '@/composables/useClone'
import { type User, useUserSetup } from '@/composables/user/useUserSetup'
import MainLayout from '@/layouts/MainLayout.vue'
import { useAuthStore } from '@/stores/auth.store'
import { usePasswordVisibility } from '@/utils/passwordVisibility'

/****************************************
* STORES
*****************************************/
const userStore = useAuthStore()
const { users, user, currentUser, roles } = storeToRefs(userStore)
const { fetchAllUsers, createUser, updateUser, deleteUser, resetCurrentUser } = userStore

/****************************************
* REFS
*****************************************/
const isConfirmDeleteModalOpen = ref<boolean>(false) // State to track whether the delete confirmation modal is open.
const isNewUser = ref<boolean>(false) // State to track whether the user is new or being edited.
const isUserModalOpen = ref<boolean>(false) // Tracks if the speaker modal is open

/****************************************
* COMPUTED VARIABLES
*****************************************/
// As each user has a 'roles' property which is an array and you must give a string in 'sortBy' to get the values sorted,
// the users are transformed in here to include the new key 'role', which includes the string that is inside the 'roles' array.
const transformedUsers = computed(() => {
  return users.value.map((user) => ({
    ...user,
    role: user.roles[0] || '' // Extract the first role or set a default
  }))
})

const columns: ComputedRef<VTableColumn[]> = computed(() => [
  // Define the columns for the table displaying users.
  {
    title: t('global.name'),
    name: 'name',
    sortable: true,
    align: 'left',
    valign: 'middle',
    keys: ['firstName', 'lastName'],
    sortBy: 'lastName',
    keyPositionInSmallDevices: 3
  },
  {
    title: t('global.role'),
    name: 'role',
    sortable: true,
    align: 'left',
    valign: 'middle',
    keys: ['role'], // The key to look for in the data object ĉreated in the transformedUsers computed variable.
    sortBy: 'role', // The key to sort by created in the transformedUsers computed variable.
    keyPositionInSmallDevices: 4
  },
  {
    title: t('global.actions'),
    name: 'id',
    align: 'right',
    valign: 'middle',
    keys: ['id'],
    keyPositionInSmallDevices: 1
  }
])

// Computed property to find the label of the selected role
const selectedRoleLabel = computed(() => {
  const selectedRoleValue = currentUser.value ? currentUser.value.roles[0] : '' // Get the current role value if user is not null
  const role = roles.value.find((r) => r.value === selectedRoleValue)
  return role ? role.label : '' // Return the role label if found, otherwise return an empty string
})

// Computed property to determine if the email is already taken
const isEmailTaken = computed(() => {
  const email = currentUser.value?.email
  // Only check if the email is not an empty string
  return email !== '' && users.value.some((user) => user.email === email)
})

/****************************************
* LIFECYCLE HOOKS
*****************************************/
onMounted(async () => {
  if (changePasswordDropdown.value) {
    autoAnimate(changePasswordDropdown.value)
  }
  await fetchAllUsers()
})

onUnmounted(() => {
  nextRoute.value = ''
})

/****************************************
* COMPOSABLES
*****************************************/
// User setup composable
const {
  changePassword,
  changePasswordDropdown,
  confirmPassword,
  confirmPasswordErrorMessage,
  generateRandomPassword,
  locale,
  onSelectRole,
  originalUserState,
  password,
  passwordLength,
  passwordValidationMessages,
  resetPasswordFields,
  t,
  toast,
  toggleChangePassword,
  validatePassword
} = useUserSetup()

// Unsaved changes composable
const {
  hasUnsavedChanges,
  isUnsavedChangesModalOpen,
  confirmNavigation,
  forgetUnsavedChanges,
  triggerUnsavedChangesModal,
  nextRoute
} = useUnsavedChanges()

// Use the composable for password visibility
const { showPassword, togglePasswordVisibility } = usePasswordVisibility()
const {
  showPassword: showConfirmPassword,
  togglePasswordVisibility: toggleConfirmPasswordVisibility
} = usePasswordVisibility()

/**
 * Opens the user modal for creating or editing a user.
 * If the user data exists, it's set for editing, otherwise the modal is opened for creating a new user.
 * @param data - The user object to edit or `null` for creating a new one.
 */
function openUserModal(id: number | null) {
  // Find the speaker in the 'speakers' array
  const foundUser = id && users.value.find((user) => String(user.id) === String(id))

  if (foundUser) {
    isNewUser.value = false
    currentUser.value = foundUser
    changePassword.value = false
  } else {
    isNewUser.value = true
    changePassword.value = true
  }

  isUserModalOpen.value = true

  if (originalUserState.value === null) {
    // Save a deep copy of the original speaker template state
    originalUserState.value = cloneable.deepCopy(currentUser.value)
  }
}

/**
 * Closes the speaker modal and checks if there are any unsaved changes.
 * If there are unsaved changes, it will prompt a modal to confirm navigation.
 */
async function closeUserModal() {
  if (forgetUnsavedChanges.value) {
    hasUnsavedChanges.value = false
    originalUserState.value = null
    resetCurrentUser()
    await fetchAllUsers()
    isUserModalOpen.value = false
    forgetUnsavedChanges.value = false
  } else {
    if (hasUnsavedChanges.value) {
      triggerUnsavedChangesModal(null)
    } else {
      isUserModalOpen.value = false
      forgetUnsavedChanges.value = true
      resetCurrentUser()
      originalUserState.value = null
    }
  }

  resetPasswordFields()
}

/**
 * Handles the action of saving or updating a user.
 * If the user is new, it creates a new user, otherwise it updates the existing user.
 */
async function handleUserAction() {
  // Clone the user object to avoid mutating the original object
  let payload = cloneable.deepCopy(currentUser.value)

  // If the user is new or the changePassword toggle is active, update the password
  if (isNewUser.value || changePassword.value) {
    // Use type assertion to add password property
    ;(payload as any).password = password.value
  }

  if (isNewUser.value) {
    // Create a new user
    await createUser(payload as User)
    toast.success(t('views.user.userCreated'))
  } else {
    // Update the user
    await updateUser(payload as User)
    toast.success(t('views.user.userUpdated'))
  }

  isUserModalOpen.value = false
  resetPasswordFields()
  forgetUnsavedChanges.value = true
  resetCurrentUser()
}

/**
 * Handles the deletion of a user.
 * @param userId - The ID of the user to delete.
 */
async function handleDeleteUser(userId: number) {
  await deleteUser(userId).then((response) => {
    resetPasswordFields()
    forgetUnsavedChanges.value = true
    resetCurrentUser()
    isConfirmDeleteModalOpen.value = false
    if (response) {
      toast.success(t('views.user.userDeleted'))
    }
  })
}

/**
 * Opens the delete confirmation modal.
 * Fetches the user by its ID and displays it in the modal.
 * @param userId - The ID of the user to delete.
 */
async function openConfirmationModal(userId: number) {
  // Find the user in the 'users' array
  const foundUser = users.value.find((user) => String(user.id) === String(userId))

  if (foundUser) {
    currentUser.value = foundUser
  } else {
    toast.error(t('views.user.userNotFound'))
    return
  }

  // as currentUser is always undefined when any modal is opened, we prevent the watch function from triggering by setting forgetUnsavedChanges to true
  forgetUnsavedChanges.value = true
  isConfirmDeleteModalOpen.value = true
}

/**
 * Function to confirm and handle unsaved changes when a modal is open.
 * This function resets various unsaved change states and refetches the users list.
 */
async function confirmUnsavedChangesModal() {
  confirmNavigation() // Confirm navigation and reset unsaved changes related to the image and other form data
  originalUserState.value = null // Clear the original user state, since it's no longer needed after confirmation
  resetCurrentUser() // Reset the current user state
  await fetchAllUsers() // Refetch all users to ensure the list is up to date after confirming the changes
}

/**
 * Cancels the unsaved changes and restores the original user state.
 * This function reverts any modifications made to the user and closes the unsaved changes modal.
 */
function cancelUnsavedChangesModal() {
  // If the user is not new (his/her data is not saved yet), then restore the originalUserState
  if (!isNewUser.value) {
    currentUser.value = cloneable.deepCopy(originalUserState.value as User) // Restore the original user state by creating a deep copy of the original user object
  }

  isUnsavedChangesModalOpen.value = false // Close the unsaved changes modal
  openUserModal(+currentUser.value.id) // Re-open the user modal with the restored original user data
}

/****************************************
* WATCHERS
*****************************************/
// Validate the password whenever it changes
watch(password, (newPassword) => {
  validatePassword(newPassword)
})

// Watch user and password values for changes to track unsaved changes
watch(
  [() => currentUser.value, () => password.value, () => confirmPassword.value],
  () => {
    console.log('Email:', currentUser.value.email, 'Is email taken:', isEmailTaken.value)

    // Skip comparison if we are supposed to forget unsaved changes or if the original state is not available
    if (forgetUnsavedChanges.value || !originalUserState.value || !currentUser.value) {
      hasUnsavedChanges.value = false // Reset unsaved changes if data is not available
      return
    }

    // Ensure both user and originalUserState are valid objects
    if (
      typeof currentUser.value === 'object' &&
      currentUser.value !== null &&
      typeof originalUserState.value === 'object' &&
      originalUserState.value !== null
    ) {
      // Compare old and new user state
      const differences = findDifferences(originalUserState.value, currentUser.value)

      // Only consider password and confirmPassword if the changePassword toggle is active
      const hasPasswordChanges = changePassword.value
        ? (password.value !== '' || confirmPassword.value !== '') &&
          password.value === confirmPassword.value
        : false

      // Update hasUnsavedChanges based on other fields or password change if applicable
      hasUnsavedChanges.value = differences.length > 0 || hasPasswordChanges
    } else {
      hasUnsavedChanges.value = false // Reset unsaved changes if invalid objects
    }
  },
  { deep: true }
)

// Watch for changes in password and confirm password to validate
watch([() => password.value, () => confirmPassword.value], ([newPassword, newConfirmPassword]) => {
  // Check if both fields are filled before comparing
  if (newPassword && newConfirmPassword) {
    if (newPassword !== newConfirmPassword) {
      confirmPasswordErrorMessage.value = t('views.user.passwordMismatch')
    } else {
      confirmPasswordErrorMessage.value = ''
    }
  } else {
    // If one of the fields is empty, reset the error message
    confirmPasswordErrorMessage.value = ''
  }
})
</script>

<template>
  <MainLayout>
    <section class="w-full text-dark-grey dark:text-light-grey">
      <div class="mb-10 flex flex-wrap items-center">
        <h1 class="mr-8 text-[32px] font-bold lg:text-[42px] xl:text-[58px]">
          {{ t('views.user.usersManagement') }}
        </h1>
        <VButton
          type="button"
          appearance="default"
          :label="t('views.user.newUser')"
          size="large"
          :functionOnClick="async () => openUserModal(null)"
        >
          <FontAwesomeIcon :icon="['fal', 'circle-plus']" />
        </VButton>
      </div>
      <VTable
        v-if="transformedUsers.length > 0"
        :columns="columns"
        :data="transformedUsers"
        :filterButtonsInSmallDevices="['name', 'role']"
      >
        <template #name="{ row, col }">
          <div
            class="inline-block [&>*]:leading-tight"
            :class="{ 'font-extrabold': row.id === user?.id }"
          >
            <span v-if="col.keys && col.keys.length > 0" class="text-bse">
              {{ row[col.keys[0]] }} &nbsp;
            </span>
            <span v-if="col.keys && col.keys.length > 1" class="text-base">
              {{ row[col.keys[1]] }}
            </span>
          </div>
        </template>
        <template #role="{ value }">
          <span class="text-base">
            {{ roles.find((role) => role.value === value)?.label || value }}
          </span>
        </template>
        <template #id="{ value }">
          <VButton type="button" appearance="empty">
            <RouterLink v-if="value === user?.id" :to="`/${locale}/profile`">
              <FontAwesomeIcon :icon="['fal', 'pen-circle']" class="mr-2 size-5 p-1.5" />
            </RouterLink>
            <VButton
              v-else
              type="button"
              appearance="empty"
              size="medium"
              :functionOnClick="async () => openUserModal(value)"
            >
              <FontAwesomeIcon :icon="['fal', 'pen-circle']" class="mr-2 size-5 p-1.5" />
            </VButton>
          </VButton>
          <!-- Admin user cannot delete his account by himself -->
          <VButton
            v-if="value !== user?.id"
            type="button"
            appearance="empty"
            size="medium"
            class="cursor-pointer"
            :functionOnClick="() => openConfirmationModal(Number(value))"
          >
            <FontAwesomeIcon :icon="['fal', 'trash-can']" class="mr-2 size-5 p-1.5" />
          </VButton>
        </template>
      </VTable>
    </section>
    <template #modal>
      <!-- Edit user modal -->
      <VModal
        :trigger="isUserModalOpen"
        @update:trigger="isUserModalOpen = $event"
        includeForm
        :function-on-close="closeUserModal"
      >
        <template #modalHeader>
          <p
            v-if="currentUser !== null && currentUser.id"
            class="text-center text-xl uppercase text-dark-grey dark:text-light-grey"
          >
            {{ t('views.speakers.index.editSpeaker') }}
          </p>
          <p v-else class="text-center text-xl uppercase text-dark-grey dark:text-light-grey">
            {{ t('views.speakers.index.createSpeaker') }}
          </p>
        </template>
        <template #modalBody>
          <VInput
            v-model="currentUser!.firstName"
            type="text"
            :input-id="`global-input-first-name-${currentUser!.id}`"
            :label="t('global.firstName')"
            :required="true"
            :tooltip="t('global.requiredField')"
            :errorMessage="t('global.invalidValue')"
            :placeholder="t('global.firstName')"
            class="mb-5"
          />
          <VInput
            v-model="currentUser!.lastName"
            type="text"
            :input-id="`global-input-last-name-${currentUser!.id}`"
            :label="t('global.lastName')"
            :required="true"
            :tooltip="t('global.requiredField')"
            :errorMessage="t('global.invalidValue')"
            :placeholder="t('global.lastName')"
            class="mb-5"
          />
          <VInput
            v-model="currentUser!.email"
            type="email"
            :input-id="`global-input-email-${currentUser!.id}`"
            :label="t('global.email')"
            :required="true"
            :placeholder="t('global.email')"
            :disabled="!isNewUser"
            :errorMessage="isNewUser && isEmailTaken && t('views.user.emailIsAlreadyTaken')"
            :showValidClasses="isNewUser && !isEmailTaken"
            :show-error-message="isNewUser && isEmailTaken"
            class="mb-5"
          />
          <VSelect
            v-model="currentUser!.roles[0]"
            :selectedValue="selectedRoleLabel"
            :label="`${t('global.role')}:`"
            :placeholder="t('global.role')"
            :required="true"
            @selectOption="onSelectRole(currentUser!, $event)"
            :options="roles"
            class="col-span-2 lg:col-span-1"
          />
          <div class="mb-5 mt-7 flex w-full flex-col justify-end gap-5">
            <VButton
              v-if="!isNewUser"
              type="button"
              appearance="button"
              :label="t('views.user.changePassword')"
              :disabled="false"
              size="medium"
              :functionOnClick="toggleChangePassword"
              :class="'mt-3'"
            />
            <div ref="changePasswordDropdown">
              <div v-if="changePassword" class="flex flex-col gap-5">
                <div class="relative">
                  <div class="flex flex-col gap-4">
                    <VInput
                      v-model="password"
                      :type="showPassword ? 'text' : 'password'"
                      input-id="user-password"
                      :label="t('global.password')"
                      placeholder=""
                      :required="true"
                      :tooltip="t('global.requiredField')"
                      :errorMessage="t('global.invalidValue')"
                      :showValidClasses="password !== '' && passwordValidationMessages.length === 0"
                      :show-error-message="password === '' || passwordValidationMessages.length > 0"
                      :disabled="false"
                      class="col-span-2 lg:col-span-1"
                    />
                    <VButton
                      type="button"
                      appearance="empty"
                      size="medium"
                      :functionOnClick="togglePasswordVisibility"
                      class="absolute right-1 top-1"
                    >
                      <FontAwesomeIcon
                        v-if="showPassword"
                        :icon="['fal', 'eye']"
                        class="absolute right-1 top-1"
                      />
                      <FontAwesomeIcon
                        v-else
                        :icon="['fal', 'eye-slash']"
                        class="absolute right-1 top-1"
                      />
                    </VButton>
                    <ul v-if="passwordValidationMessages.length" class="flex flex-col gap-2">
                      <li
                        v-for="(error, index) in passwordValidationMessages"
                        :key="index"
                        class="text-xs text-dark-red dark:text-light-red"
                      >
                        * {{ error }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="relative">
                  <VInput
                    v-model="confirmPassword"
                    :type="showConfirmPassword ? 'text' : 'password'"
                    input-id="user-confirm-password"
                    :label="t('views.user.confirmPassword')"
                    placeholder=""
                    :required="true"
                    :tooltip="t('global.requiredField')"
                    :errorMessage="confirmPasswordErrorMessage"
                    :showValidClasses="
                      password !== '' &&
                      passwordValidationMessages.length === 0 &&
                      password === confirmPassword
                    "
                    :show-error-message="confirmPasswordErrorMessage ? true : false"
                    :disabled="password === '' || passwordValidationMessages.length > 0"
                    class="col-span-2 lg:col-span-1"
                  />
                  <VButton
                    type="button"
                    appearance="empty"
                    size="medium"
                    :functionOnClick="toggleConfirmPasswordVisibility"
                    class="absolute right-1 top-1"
                  >
                    <FontAwesomeIcon
                      v-if="showConfirmPassword"
                      :icon="['fal', 'eye']"
                      class="absolute right-1 top-1"
                    />
                    <FontAwesomeIcon
                      v-else
                      :icon="['fal', 'eye-slash']"
                      class="absolute right-1 top-1"
                    />
                  </VButton>
                </div>
                <div class="flex flex-col gap-2">
                  <VRangeSlider
                    :value="passwordLength"
                    :min="10"
                    :max="20"
                    :disabled="false"
                    :help="`${t('views.user.passwordLength')}: ${passwordLength}`"
                    :class-name="'text-dark-grey dark:text-light-grey'"
                    @update:modelValue="(newValue: number) => (passwordLength = newValue)"
                  />
                  <VButton
                    type="button"
                    appearance="button"
                    :label="t('views.user.createRandomPassword')"
                    :disabled="false"
                    size="medium"
                    :functionOnClick="() => generateRandomPassword(passwordLength)"
                    :class="'mt-3'"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #modalFooter>
          <div class="flex justify-between">
            <VButton
              type="reset"
              appearance="cancel"
              :label="t('global.cancel')"
              size="medium"
              :functionOnClick="
                () => {
                  forgetUnsavedChanges = true
                  isUserModalOpen = false
                }
              "
            />
            <VButton
              type="submit"
              appearance="default"
              :label="isNewUser ? t('global.save') : t('global.update')"
              size="medium"
              :disabled="
                !hasUnsavedChanges ||
                (changePassword && password !== confirmPassword) ||
                isNewUser && isEmailTaken
              "
              :functionOnClick="handleUserAction"
            />
          </div>
        </template>
      </VModal>
      <!-- Confirm delete user modal -->
      <VModal
        v-model:trigger="isConfirmDeleteModalOpen"
        @update:trigger="isConfirmDeleteModalOpen = $event"
      >
        <template #modalHeader>
          <p
            v-if="currentUser"
            class="text-center text-xl uppercase text-dark-grey dark:text-light-grey"
          >
            {{ currentUser.firstName }}&nbsp;{{ currentUser.lastName }}
          </p>
        </template>
        <template #modalBody>
          <p class="text-dark-grey dark:text-light-grey">
            {{ t('views.templates.registration.confirmDeleteTemplate') }}
          </p>
        </template>
        <template #modalFooter>
          <div class="flex justify-between">
            <VButton
              type="button"
              appearance="cancel"
              :label="t('global.cancel')"
              size="medium"
              :functionOnClick="(isUserModalOpen = false)"
            />
            <VButton
              v-if="currentUser"
              type="button"
              appearance="default"
              :label="t('global.delete')"
              size="medium"
              :functionOnClick="
                () => {
                  handleDeleteUser(Number(currentUser!.id))
                }
              "
            />
          </div>
        </template>
      </VModal>
      <!-- Unsaved Changes Modal -->
      <VModal :trigger="isUnsavedChangesModalOpen" avoidCloseModalOnOverlay>
        <template #modalHeader>
          <p class="text-center text-xl uppercase text-dark-grey dark:text-light-grey">
            {{ t('views.events.index.unsavedChangesTitle') }}
          </p>
        </template>
        <template #modalBody>
          <p class="text-dark-grey dark:text-light-grey">
            {{ t('views.events.index.unsavedChangesMessage') }}
          </p>
        </template>
        <template #modalFooter>
          <div class="flex justify-between">
            <VButton
              type="button"
              appearance="cancel"
              :label="t('global.cancel')"
              size="medium"
              :functionOnClick="cancelUnsavedChangesModal"
            />
            <VButton
              type="button"
              appearance="default"
              :label="t('global.continue')"
              size="medium"
              :functionOnClick="confirmUnsavedChangesModal"
            />
          </div>
        </template>
      </VModal>
    </template>
  </MainLayout>
</template>
