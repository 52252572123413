import type { components } from '@/types/swagger'

type Event = components['schemas']['Event']
type Webform = components['schemas']['Webform']
type Design = components['schemas']['Design']

export const emptyEvent: Partial<Event> = {
  id: undefined,
  name: { de: '', en: '' },
  description: { de: '', en: '' },
  startDate: new Date().toISOString(),
  endDate: new Date().toISOString(),
  subtitle: { de: '', en: '' },
  url: { de: '', en: '' },
  languages: [],
  protected: false,
  created_at: '',
  updated_at: '',
  speakers: [],
  webform: {} as Webform,
  previewImages: [],
  agendaItems: [],
  downloads: [],
  registrations: [],
  links: [],
  webformSubmissions: [],
  isMultilanguage: false,
  design: {} as Design
}
