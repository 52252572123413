<script setup lang="ts">
import { VButton, VSearch, VSpeakerAvatar } from '@techcast/histoire'

import autoAnimate from '@formkit/auto-animate'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { storeToRefs } from 'pinia'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import anonymousUserImage from '@/assets/images/anonymous-user.png'
import CloudImage from '@/components/utils/CloudImage.vue'
import I18nRouterLink from '@/components/utils/I18nRouterLink.vue'
import { getFilteredSpeakers } from '@/composables/speakers/useSpeakersFilter'
import MainLayout from '@/layouts/MainLayout.vue'
import { useAgendaItemsStore } from '@/stores/agendaItems.store'
import { useEventsStore } from '@/stores/events.store'
import { useSpeakersStore } from '@/stores/speakers.store'
import type { components } from '@/types/swagger'
import { arraySortedByStringProperty } from '@/utils/arraySortedByStringProperty'

/**
 * Router
 */
const route = useRoute()

/****************************************
* TRANSLATIONS
*****************************************/
const { t } = useI18n()

/****************************************
* TYPES
*****************************************/
type Speaker = components['schemas']['Speaker']

/****************************************
* STORES
*****************************************/
const speakerStore = useSpeakersStore()
const { speakers, speakersAlreadyFetched } = storeToRefs(speakerStore)
const { fetchAllSpeakers } = speakerStore
const eventStore = useEventsStore()
const { currentEvent } = storeToRefs(eventStore)
const { addSpeakerToEvent, removeSpeakerFromEvent, fetchEventById, resetCurrentEvent } = eventStore
const agendaItemsStore = useAgendaItemsStore()
const { removeSpeakerFromAllAgendaItems } = agendaItemsStore

/****************************************
* LIFECYCLE HOOKS
*****************************************/
onMounted(async () => {
  if (!currentEvent.value) {
    throw new Error('Current event is missing')
  }

  // initialize the current event after the component is mounted
  if (eventId) {
    await fetchEventById(+eventId)
    await fetchAllSpeakers()
  }
  autoAnimate(sortingAllSpeakers.value)
  autoAnimate(sortingSpeakersEvent.value)
})

onUnmounted(async () => {
  resetCurrentEvent()
})

/****************************************
* REFS
*****************************************/
const eventId = route.params.id
const sortingAllSpeakers = ref()
const sortingSpeakersEvent = ref()
const searchInput = ref<string>('')
const tempSelectedSpeakers = ref<Speaker[]>([]) // Array to hold temporarily selected speakers
const selectedSpeakers = ref<Speaker[]>([]) // Array to hold the selected speakers for the current event

/****************************************
* COMPUTED VARIABLES
*****************************************/
// Variable with the filtered speakers based on the search input
const speakersFiltered = computed(() => {
  return getFilteredSpeakers(speakers.value, searchInput.value)
})

// Check if the speaker is selected in the temporary selected speakers
const isSpeakerTempSelected = computed(() => {
  return (speaker: Speaker) => {
    return selectedSpeakers.value.some(
      (selectedSpeaker) => String(selectedSpeaker.id) === String(speaker.id)
    )
  }
})

/****************************************
* METHODS
*****************************************/
/**
 * Updates the search input value.
 * @param input - The search query.
 */
function filterSpeakers(input: string) {
  searchInput.value = input
}

/**
 * Adds or removes a speaker from the temporary selection list.
 * @param speaker - The speaker to add or remove.
 * @param isChecked - Whether the checkbox is checked or not.
 */
function addSpeakerToTempSelectedSpeakers(speaker: Speaker, isChecked: boolean) {
  if (isChecked) {
    const index = tempSelectedSpeakers.value.findIndex(
      (selectedSpeaker) => String(selectedSpeaker.id) === String(speaker.id)
    )
    if (index === -1) {
      tempSelectedSpeakers.value.push(speaker)
    }
  } else {
    // Remove the speaker from the temporary list if it is unchecked
    const index = tempSelectedSpeakers.value.findIndex(
      (selectedSpeaker) => String(selectedSpeaker.id) === String(speaker.id)
    )
    if (index !== -1) {
      tempSelectedSpeakers.value.splice(index, 1)
    }
  }
}

/**
 * Merges temporary selected speakers with the final selection list and sends to backend.
 * @param eventId - The ID of the event.
 * @param speakers - The list of speakers to add.
 */
async function addTempSpeakerToSelectedSpeakers(eventId: number, speakers: Speaker[]) {
  if (tempSelectedSpeakers.value.length > 0) {
    // merge the temporary list into the final one before sending it to the backend
    selectedSpeakers.value = [...selectedSpeakers.value, ...speakers]
    resetCheckedSpeakers()

    // bundle alle remove speaker promises
    await Promise.all(
      selectedSpeakers.value.map((speaker) => addSpeakerToEvent(eventId, speaker.id))
    )

    tempSelectedSpeakers.value = []
    selectedSpeakers.value = []
  }
}

/**
 * Checks if a speaker is disabled based on current event's speakers list.
 * @param speaker - The speaker to check.
 * @returns True if the speaker is disabled; otherwise, false.
 */
function isSpeakerDisabled(speaker: Speaker) {
  return currentEvent.value.speakers?.some(
    (selectedSpeaker) => String(selectedSpeaker.id) === String(speaker.id)
  )
}

/**
 * Resets the checked state of speaker checkboxes.
 */
function resetCheckedSpeakers() {
  const listSpeakers = document.querySelectorAll('.speaker-avatar input[type="checkbox"]')
  listSpeakers.forEach((speaker) => {
    const inputElement = speaker as HTMLInputElement
    inputElement.checked = false
  })
}

/**
 * Removes a speaker from the event and all related agenda items.
 * @param eventId - The ID of the event.
 * @param speakerId - The ID of the speaker to remove.
 */
async function handleRemoveSpeaker(eventId: number, speakerId: number) {
  await removeSpeakerFromEvent(eventId, speakerId)
  await removeSpeakerFromAllAgendaItems(currentEvent.value, speakerId)
}
</script>

<template>
  <MainLayout>
    <section class="w-full text-dark-grey dark:text-light-grey">
      <div class="mb-10 flex flex-wrap items-center">
        <h1 class="mr-8 text-[32px] font-bold lg:text-[42px] xl:text-[58px]">
          {{ t('global.speakers') }}
        </h1>
        <I18nRouterLink to="/speakers">
          <VButton
            type="button"
            appearance="default"
            :label="t('views.speakers.index.goToSpeakersManagement')"
            size="large"
          >
            <FontAwesomeIcon :icon="['fal', 'headset']" />
          </VButton>
        </I18nRouterLink>
      </div>
      <VSearch
        v-model="searchInput"
        :input-id="'input-regular'"
        :label="t('views.speakers.index.searchSpeakers')"
        :button-label="'Search'"
        :placeholder="t('global.search')"
        @input="filterSpeakers(searchInput)"
        class="mb-5"
      />
      <hr class="mb-5" />
      <div v-if="speakersAlreadyFetched" class="flex flex-col gap-4 pb-5 xl:flex-row">
        <div class="flex flex-1 flex-col gap-4">
          <h2 class="text-base font-bold text-dark-grey dark:text-light-grey">
            {{ t('views.events.speakers.existingSpeakers') }}
          </h2>
          <div
            class="overflow-y-auto rounded-xl bg-white p-10 shadow xl:h-[50vh] dark:bg-dark-grey"
          >
            <div v-if="speakers.length === 0">
              <p class="mb-4">{{ t('views.events.speakers.noSpeakersAvailable') }}</p>
              <I18nRouterLink to="/speakers">
                <VButton
                  type="button"
                  appearance="default"
                  :label="t('views.speakers.index.goToSpeakersManagement')"
                  size="medium"
                >
                  <FontAwesomeIcon :icon="['fal', 'headset']" />
                </VButton>
              </I18nRouterLink>
            </div>
            <div v-else>
              <div v-if="speakersFiltered.length > 0">
                <ul class="flex h-[25vh] flex-col gap-4" ref="sortingAllSpeakers">
                  <li
                    v-for="speaker in arraySortedByStringProperty(speakersFiltered, 'lastName')"
                    :key="`${speaker.firstName} ${speaker.lastName}`"
                  >
                    <VSpeakerAvatar
                      :type="'checkbox'"
                      :input-id="`global-${speaker.id}`"
                      :title="`${speaker.firstName} ${speaker.lastName}`"
                      :subtitle="speaker.company"
                      :description="speaker.vita"
                      :model-value="isSpeakerTempSelected(speaker)"
                      @change="addSpeakerToTempSelectedSpeakers(speaker, $event.target.checked)"
                      class="speaker-avatar"
                      :disabled="isSpeakerDisabled(speaker)"
                    >
                      <template #image>
                        <CloudImage
                          v-if="speaker.images?.length"
                          :imageName="speaker.images?.[0]?.['public_id']"
                          class="h-full w-full object-cover"
                          :alt="`${speaker.firstName} ${speaker.lastName}`"
                        />
                        <img
                          v-else
                          :src="anonymousUserImage"
                          :alt="`${speaker.firstName} ${speaker.lastName}`"
                          class="h-full w-full object-cover"
                        />
                      </template>
                    </VSpeakerAvatar>
                  </li>
                </ul>
              </div>
              <div v-else>
                <p class="mb-4">{{ t('views.speakers.index.noSpeakersFound') }}</p>
              </div>
            </div>
          </div>
          <VButton
            v-if="currentEvent.id && speakersFiltered.length > 0"
            type="button"
            appearance="default"
            :label="t('views.events.speakers.addSelectedSpeakers')"
            size="medium"
            class="self-end"
            :functionOnClick="
              () => addTempSpeakerToSelectedSpeakers(currentEvent.id!, tempSelectedSpeakers)
            "
          />
        </div>
        <div class="flex flex-1 flex-col gap-4">
          <h2 class="text-base font-bold text-dark-grey dark:text-light-grey">
            {{ t('views.events.speakers.speakersInEvent') }}
          </h2>
          <ul
            class="flex h-[25vh] flex-col gap-4 overflow-y-scroll rounded-xl bg-white p-10 shadow xl:h-[50vh]
              dark:bg-dark-grey"
            ref="sortingSpeakersEvent"
          >
            <li
              v-for="speaker in currentEvent.speakers"
              :key="`${speaker.firstName} ${speaker.lastName}`"
            >
              <VSpeakerAvatar
                :type="'edit'"
                :input-id="`event-speaker-${speaker.id}`"
                :title="`${speaker.firstName} ${speaker.lastName}`"
                :subtitle="speaker.company"
                :description="speaker.vita"
                :model-value="false"
              >
                <template #image>
                  <CloudImage
                    v-if="speaker.images?.length"
                    :imageName="speaker.images?.[0]?.['public_id']"
                    class="h-full w-full object-cover"
                    :alt="`${speaker.firstName} ${speaker.lastName}`"
                  />
                  <img
                    v-else
                    :src="anonymousUserImage"
                    :alt="`${speaker.firstName} ${speaker.lastName}`"
                    class="h-full w-full object-cover"
                  />
                </template>
                <VButton
                  v-if="currentEvent.id"
                  type="button"
                  appearance="empty"
                  size="medium"
                  :functionOnClick="
                    async () => await handleRemoveSpeaker(currentEvent.id!, speaker.id)
                  "
                >
                  <FontAwesomeIcon :icon="['fal', 'circle-minus']" class="mr-2 size-5 p-1.5" />
                </VButton>
              </VSpeakerAvatar>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </MainLayout>
</template>
