export const createQueryString = (params: Record<string, any>): string => {
  // Filter out undefined or null values and convert all values to strings
  return new URLSearchParams(
    Object.entries(params).reduce(
      (acc, [key, value]) => {
        if (value !== undefined && value !== null) {
          acc[key] = String(value) // Ensure all values are converted to strings
        }
        return acc
      },
      {} as Record<string, string>
    )
  ).toString()
}
