import type { components } from '@/types/swagger'
type Speaker = components['schemas']['Speaker']

/**
 * Filters speakers based on the search input.
 * 
 * @param speakers - The array of speakers to filter.
 * @param searchInput - The search query input.
 * @returns The filtered array of speakers.
 */
export function getFilteredSpeakers(speakers: Speaker[], searchInput: string): Speaker[] {
  const query = searchInput.toLowerCase()
  return speakers.filter(speaker => {
    return (
      (speaker.firstName && speaker.firstName.toLowerCase().includes(query)) ||
      (speaker.lastName && speaker.lastName.toLowerCase().includes(query)) ||
      (speaker.company && speaker.company.toLowerCase().includes(query))
    )
  })
}
