import { defineAsyncComponent } from 'vue'

const asyncDefault = defineAsyncComponent(() => import('./NoAccessDefault.vue'))

const mappedFrontendNoAccessComponents = {
  default: asyncDefault
}

export {
  asyncDefault,
  mappedFrontendNoAccessComponents
}
