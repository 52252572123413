// src/utils/honeypotFunctions.ts

/**
 * Generate a random name for honeypot fields.
 * @returns {string} Randomized field name
 */
export function generateRandomName(): string {
    return `field_${Math.random().toString(36).slice(2, 14)}`; // Updated from substr to slice
}

/**
 * Check if the form data contains any honeypot fields filled.
 * @param {object} formData - The data submitted in the form
 * @param {string} honeypotName - The name of the honeypot field
 * @param {string} extraFieldName - The name of the extra field
 * @returns {boolean} True if a bot is detected, otherwise false
 */
export function isBotDetected(
    formData: Record<string, any>,
    honeypotName: string,
    extraFieldName: string
): boolean {
    // Check if the honeypot field is filled
    if (formData[honeypotName] || formData[extraFieldName]) {
        return true; // Detected as a bot
    }
    return false;
}

/**
 * Check if the form was submitted too quickly, indicating possible bot activity.
 * @param {number} formLoadTime - Timestamp of when the form was loaded
 * @param {number} minSubmissionTime - Minimum time in milliseconds expected for a human to complete the form
 * @returns {boolean} True if a bot is detected based on submission time
 */
export function isSubmissionTooFast(
    formLoadTime: number,
    minSubmissionTime: number = 3000
): boolean {
    const timeTaken = Date.now() - formLoadTime;
    return timeTaken < minSubmissionTime; // Detected as a bot if submission is too fast
}

/**
 * Add an event listener to check for focus events
 * @param elementId 
 * @param callback 
 */
export function monitorFocusOnElement(elementId: string, callback: () => void): void {
    const element = document.getElementById(elementId);
    if (element) {
        element.addEventListener('focus', callback);
    }
}