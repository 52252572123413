import type { DesignColorVariables } from '@/types/DesignColorVariables'
import type { components } from '@/types/swagger'

type DesignTemplate = components['schemas']['DesignTemplate']
type FooterItemClass = components["schemas"]["FooterItemClass"][]

export const emptyDesignTemplate: Partial<DesignTemplate> = {
  id: undefined,
  name: 'Neues Design Template',
  colors: {} as DesignColorVariables,
  positions: {
    logo: 'left'
  },
  footer: [
    {
      position: 'center',
      text: { 
        de: '<p>Footer Text</p>',
        en: '<p>Footer Text</p>'
      }
    }
  ] as FooterItemClass,
  logos: [],
  isMultilanguage: false
}
