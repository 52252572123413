<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { twMerge } from 'tailwind-merge'
import { ref } from 'vue'

import UserInfoMenu from '@/components/nav/UserInfoMenu.vue'
import { useAuthStore } from '@/stores/auth.store'
import { useNavStore } from '@/stores/nav.store'

const { user } = useAuthStore()

const { isOpen } = storeToRefs(useNavStore())

const userMenuCollapsed = ref(true)
</script>

<template>
  <section
    v-if="user"
    :class="
      twMerge(
        'flex min-h-[4.75rem] justify-between rounded-lg bg-glass-light-grey/75 p-6 pr-4 backdrop-blur',
        !isOpen && '!justify-center pr-6'
      )
    "
  >
    <div v-if="isOpen" class="truncate">
      <p class="text-base font-bold text-light-grey">
        {{ user.firstName + ' ' + user.lastName }}
      </p>
      <p class="text-[12px] leading-none text-medium-light-grey">
        {{ user.email }}
      </p>
      <p class="text-[12px] leading-none text-medium-light-grey">
        {{ user.roles.join(', ') }}
      </p>
    </div>
    <div
      @click="userMenuCollapsed = !userMenuCollapsed"
      class="cursor-pointer self-center px-4 py-2"
    >
      <font-awesome-icon :icon="['fal', 'ellipsis-vertical']" class="text-3xl text-light-grey" />
    </div>
    <!-- UserInfoMenu emits an update event when the closing cross is clicked -->
    <UserInfoMenu
      :collapse="userMenuCollapsed"
      @update:collapse="userMenuCollapsed = $event"
      class="absolute -top-2.5 left-0 origin-bottom -translate-y-full"
    />
  </section>
</template>
