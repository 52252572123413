<script setup lang="ts">
import { VButton, VTable, type VTableColumn, VTag } from '@techcast/histoire'

import dayjs from 'dayjs'
import { type ComputedRef, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import eventPlaceholder from '@/assets/images/event_placeholder.jpg'
import type { components } from '@/types/swagger'
import { getImageName } from '@/utils/getImageName'
import { orderedMultilanguageTableTitles } from '@/utils/orderedMultilanguageTableTitles'

import CloudImage from '../utils/CloudImage.vue'

/**
 * Component: EventTable
 *
 * Description:
 * This component displays a table of event data. It includes functionality for sorting, tooltips, and responsive design for small devices.
 * The component uses `VTooltip` for showing additional information on hover and `VScrollIndicator` to provide visual cues for scrollable content.
 * 
 * Props:
 * - columns (Array<VTableColumn<KeyType>>): An array of objects defining the columns of the table. Each object includes:
 *   - name (String): The key in the data object corresponding to this column.
 *   - title (String): The title to display in the column header.
 *   - sortable (Boolean): Indicates whether the column can be sorted.
 *   - keyPositionInSmallDevices (Number): Position of the column in grid layout for small devices.
 *   - keys (Array<String>): Array of keys to display in the cell for multiple data points.
 *   - showTooltip (Boolean): Indicates whether to show a tooltip for the column.
 * - data (Array<EventDataType<KeyType>>): An array of objects representing the rows of the table.
 * - className (String, optional): Additional class names for custom styling.
 * - filterButtonsInSmallDevices (Array<String>, optional): An array of strings for filter buttons displayed on small devices.
 *
 * Usage:
 * <EventTable
 *   :columns="columns"
 *   :data="data"
 *   className="custom-class"
 *   :filterButtonsInSmallDevices="['Filter1', 'Filter2']"
 * />
 */

type Event = components['schemas']['Event']

const props = defineProps<{
  events: Event[]
}>()

const { t, locale } = useI18n()

// Define columns for VTable, specifying titles, sorting capabilities, alignment, etc.
const columns: ComputedRef<VTableColumn[]> = computed(() => [
  {
    title: t('global.date'),
    name: 'startDate',
    valign: 'middle',
    sortable: true,
    keys: ['startDate'],
    align: 'center',
    keyPositionInSmallDevices: 1
  },
  {
    title: t('components.events.EventTable.previewImage'),
    name: 'previewImages',
    align: 'center',
    valign: 'middle',
    keys: ['previewImages']
  },
  {
    title: t('global.title'),
    name: 'name',
    sortable: true,
    align: 'left',
    valign: 'middle',
    keys: ['name', 'languages'],
    // showTooltip: true,
    keyPositionInSmallDevices: 3
  },
  {
    title: 'Status',
    name: 'status',
    sortable: true,
    align: 'center',
    valign: 'middle',
    keys: ['status'],
    keyPositionInSmallDevices: 2
  },
  {
    title: '',
    name: 'links',
    align: 'center',
    valign: 'middle',
    keys: ['id', 'url', 'languages'],
    keyPositionInSmallDevices: 4
  }
])

const emit = defineEmits<{
  openDeleteModal: [openDeleteModal: number]
}>()

// Function to open the delete modal and emit the event with the event ID
function openDeleteModal(eventId: number) {
  emit('openDeleteModal', eventId)
}
</script>

<template>
  <!-- Render VTable only if there are events -->
  <VTable
    v-if="events.length > 0"
    :columns="columns"
    :data="events"
    :filterButtonsInSmallDevices="['name', 'startDate']"
  >
  <!-- Template for rendering startDate with formatted date and time -->
    <template #startDate="{ value }">
      <div
        class="flex w-fit flex-col items-center justify-center font-extrabold [&>*]:leading-tight"
      >
        <span class="text-lg">
          {{ dayjs(value as string).format('DD.MM') }}
        </span>
        <span class="text-base tracking-[2.08px]">
          {{ dayjs(value as string).format('YYYY') }}
        </span>
        <span class="rounded bg-light-grey px-3 py-1 text-xs font-semibold text-misty-grey">
          {{ dayjs(value as Date).format('HH:mm') }} H
        </span>
      </div>
    </template>

    <!-- Template for rendering previewImages with CloudImage or placeholder -->
    <template #previewImages="{ value }">
      <CloudImage
        v-if="value?.[0]?.['public_id']"
        :imageName="value?.[0]?.['public_id']"
        class="aspect-video h-20 w-32 rounded object-cover"
        :alt="getImageName(value?.[0]?.['public_id'])"
      />
      <img
        v-if="!value?.[0]?.['public_id']"
        class="aspect-video h-20 w-32 rounded object-cover"
        :src="eventPlaceholder"
        alt="Event placeholder"
      />
    </template>

    <!-- Template for rendering event name, handling multiple languages -->
    <template #name="{ row, col }">
      <div class="flex flex-col">
        <div
          v-for="({ str }, index) in orderedMultilanguageTableTitles(
            row[col.keys[0]],
            row[col.keys[1]],
            locale
          )"
        >
          <span
            v-if="str !== undefined && str !== '' && str !== null"
            :key="str"
            class="font-semibold"
            :class="{
              'text-lg text-dark-grey dark:text-white': index === 0,
              'text-md text-misty-grey/60 dark:text-light-grey/50': index === 1
            }"
          >
            {{ str }}
          </span>
        </div>
      </div>
    </template>

    <!-- Template for rendering status with VTag and an icon -->
    <template #status="{ value }">
      <VTag :type="'success'">{{ value ? value : 'LIVE EVENT' }}</VTag>
      <font-awesome-icon :icon="['fal', 'lock-keyhole']" class="ml-1 text-dark-green" />
    </template>

    <!-- Template for rendering links with view, edit, and delete buttons using the event ID -->
    <template #links="{ row, col }">
      <div class="flex flex-row justify-end gap-6">
        <div class="flex flex-col">
          <div
            v-for="({ str, locale }, index) in orderedMultilanguageTableTitles(
              row[col.keys[1]],
              row[col.keys[2]],
              locale
            )"
          >
            <VButton
              v-if="str !== undefined && str !== ''"
              :key="str"
              type="button"
              appearance="empty"
              :label="locale"
              :className="'gap-0 [&_a]:relative [&_a]:top-0.5'"
            >
              <RouterLink :to="`/events-public/${str}-${row[col.keys[0]]}`" target="_blank">
                <FontAwesomeIcon :icon="['fal', 'eye']" class="mr-2 size-5 p-1.5" />
              </RouterLink>
            </VButton>
          </div>
        </div>
        <VButton type="button" appearance="empty">
          <RouterLink :to="`/${locale}/events/${row[col.keys[0]]}/update`">
            <FontAwesomeIcon :icon="['fal', 'pen-circle']" class="mr-2 size-5 p-1.5" />
          </RouterLink>
        </VButton>
        <VButton
          type="button"
          appearance="empty"
          size="medium"
          :functionOnClick="() => openDeleteModal(+row[col.keys[0]])"
        >
          <FontAwesomeIcon :icon="['fal', 'trash-can']" class="mr-2 size-5 p-1.5" />
        </VButton>
      </div>
    </template>
  </VTable>
</template>
