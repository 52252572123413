import type { components } from '@/types/swagger'

type Speaker = components['schemas']['Speaker']

export const emptySpeaker: Partial<Speaker> = {
  firstName: '',
  lastName: '',
  company: '',
  vita: { de: '', en: '' },
  position: '',
  images: []
}
