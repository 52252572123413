/**
 * Generate a slug from a given string
 * Filters out any non-alphanumeric characters except dashes,
 * replaces whitespace with dashes,
 * converts the string to lowercase
 * and replaces German Umlaute (and ß) with their respective two-letter representation
 *
 * @param name - The string to generate a slug from
 *
 * @returns The generated slug
 */
export const generateSlug = (name: string): string => {
  return name
    .trim()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace whitespace with dashes
    .replace(/ä/g, 'ae') // Replace German Umlaute
    .replace(/ö/g, 'oe')
    .replace(/ü/g, 'ue')
    .replace(/ß/g, 'ss')
    .replace(/[^a-z0-9-]/g, '') // Remove any other non-alphanumeric characters except dashes
}
