<script setup lang="ts">
import {
  VButton,
  VCheckbox,
  VColorpicker,
  VInput,
  VModal,
  VTabNavigator,
  VToggleTwoOptions,
  VTooltip
} from '@techcast/histoire'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { storeToRefs } from 'pinia'
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { onBeforeRouteLeave, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification'

import CloudImage from '@/components/utils/CloudImage.vue'
import I18nRouterLink from '@/components/utils/I18nRouterLink.vue'
import ImageCropper from '@/components/utils/ImageCropper.vue'
import QuillEditor from '@/components/utils/QuillEditor.vue'
import { findDifferences } from '@/composables/unsaved-changes/useFindDifferences'
import { useUnsavedChanges } from '@/composables/unsaved-changes/useUnsavedChanges'
import { cloneable } from '@/composables/useClone'
import { useImageCrop } from '@/composables/useImageCropped'
import MainLayout from '@/layouts/MainLayout.vue'
import router from '@/router'
import { useAssetsStore } from '@/stores/assets.store'
import { useDesignTemplatesStore } from '@/stores/designTemplates.store'
import type { DesignColorVariables } from '@/types/DesignColorVariables'
import type { components } from '@/types/swagger'
import { getImageName } from '@/utils/getImageName'
import { updateColors } from '@/utils/updateClientFrontendColors'

import Show from '../events/client/Show.vue'
import ShowLogin from '../events/client/ShowLogin.vue'

type DesignTemplate = components['schemas']['DesignTemplate']
type UpdateDesignTemplateDto = components['schemas']['UpdateDesignTemplateDto']
type UpdateDesignPreviewDto = components['schemas']['UpdateDesignPreviewDto']

const toast = useToast()

const { t, locale } = useI18n()

/****************************************
* STORES
*****************************************/
const designTemplateStore = useDesignTemplatesStore()
const { currentDesignTemplate, currentDesignTemplateLanguage, previewDesignTemplate } =
  storeToRefs(designTemplateStore)
const {
  createDesignTemplate,
  updateDesignTemplate,
  resetCurrentDesignTemplate,
  resetPreviewDesignTemplate,
  fetchDesignTemplateById,
  updatePreviewDesignTemplate
} = designTemplateStore

const assetStore = useAssetsStore()
const { deleteAsset } = assetStore

const route = useRoute()

/**
 * Computed values
 */
// Use route params to get current designTemplateId
const designTemplateId = computed(() => route.params.id)
const tabs = computed(() => [
  {
    title: t('global.access'),
    name: 'access'
  },
  {
    title: t('global.noaccess'),
    name: 'noaccess'
  }
])

/**
 * Default color variables for the design template if the user has not yet saved a new design template
 */
const defaultDesignColorVariables: Record<string, string> = {
  backgroundColor: '#3863bd',
  primaryTextColor: '#000',
  headerBackgroundColor: '#fff',
  headerTextColor: '#cf181f',
  buttonBackgroundColor: '#cf181f',
  buttonTextColor: '#fff',
  buttonHoverBackgroundColor: '#2F4858',
  formBackgroundColor: '#d0d3d4',
  formTextColor: '#000',
  footerBackgroundColor: '#d0d3d4',
  footerTextColor: '#3863bd',
  boxesBackgroundColor: '#A2A5A6',
  boxesTextColor: '#000'
}

/**
 * Refs
 */

// Clone the initial form data to compare against later
let originalDesignTemplateState = ref<DesignTemplate | null>(null)

/****************************************
* COMPOSABLES
*****************************************/
// Unsaved changes composable
const {
  hasUnsavedChanges,
  isUnsavedChangesModalOpen,
  confirmNavigation,
  triggerUnsavedChangesModal,
  nextRoute
} = useUnsavedChanges()

// Image crop composable
const { imageUploadMessage, showImageUploadMessage, croppedImageFile, imageCropped } =
  useImageCrop()

const showMultilanguageCheckbox = ref<boolean>(!currentDesignTemplate.value.isMultilanguage) // Show the multilanguage checkbox only if the user has yet not created a multilanguage template
const openEditTemplateNameModal = ref<boolean>(false) // Modal to edit the name of the design template
const imageCropperRef = ref<any>(null)
const showRemoveTemporaryImageButton = ref(false)
const imageTemporaryDeleted = ref(false)
const savedTempOldTemplateName = ref<string>('')
const designTemplateHadAlreadyAnImage = ref(false)
const imageHasUnsavedChanges = ref(false)
// refs for the preview design views
const currentTab = ref('access')
const previewMobile = ref<boolean>(true)
const previewDesktop = ref<boolean>(false)
const selectDeviceForPreview = ref<string>('mobile')
const tabNavigatorKey = ref<number>(0)
// Both sides (divs) of the form
const leftSideHeight = ref<number | null>(null)
const showRightSide = ref(false)
const formLeftSideRef = ref<HTMLElement | null>(null)
const formRightSideRef = ref<HTMLElement | null>(null)

/****************************************
* LIFECYCLE HOOKS
*****************************************/
onMounted(async () => {
  if (designTemplateId.value) {
    await fetchDesignTemplateById(+designTemplateId.value)
    showMultilanguageCheckbox.value = !currentDesignTemplate.value.isMultilanguage

    await updatePreviewDesignTemplate(
      currentDesignTemplate.value.designPreview.id,
      currentDesignTemplate.value as UpdateDesignPreviewDto
    )
  } else {
    currentDesignTemplate.value.colors = defaultDesignColorVariables as any as DesignColorVariables

    await handleCreateDesignTemplate()
  }

  // refresh key so that the preview components Show and ShowLogin rerender with the correct values
  tabNavigatorKey.value = 1

  await nextTick() // Ensure the DOM is updated

  adjustSideHeights()

  // Save a deep copy of the original design template state
  originalDesignTemplateState.value = cloneable.deepCopy(currentDesignTemplate.value)
  // Check if the design template has a already an image
  designTemplateHadAlreadyAnImage.value = !!currentDesignTemplate.value.logos?.length
})

onUnmounted(async () => {
  // Reset the current design template and the preview design template when the user leaves the page in order to avoid conflicts with other design templates
  resetCurrentDesignTemplate()
  resetPreviewDesignTemplate()
  nextRoute.value = ''
})

/****************************************
* METHODS
*****************************************/

// This function will open the modal to edit the name of the design template
function openModalAndSaveTempTemplateName() {
  savedTempOldTemplateName.value = currentDesignTemplate.value.name
  openEditTemplateNameModal.value = true
}

async function handleCreateDesignTemplate() {
  if (designTemplateId.value) {
    if (imageTemporaryDeleted.value && currentDesignTemplate.value.logos[0]) {
      await deleteAsset(currentDesignTemplate.value.logos![0].id)
      // Reset the image upload message and cropped image file
      currentDesignTemplate.value.logos = []
    }

    if (
      (currentDesignTemplate.value.logos?.length === 0 && imageCropperRef.value) ||
      imageTemporaryDeleted.value
    ) {
      await imageCropperRef.value.cropImage()
    }

    await updateDesignTemplate(
      currentDesignTemplate.value as UpdateDesignTemplateDto,
      croppedImageFile.value!
    ).then(() => {
      if (imageTemporaryDeleted.value) {
        // Reset the image upload message and cropped image file
        imageTemporaryDeleted.value = false
        showImageUploadMessage.value = false
      }
      // Force rerender of the preview components by updating the key
      tabNavigatorKey.value++
    })

    if (croppedImageFile.value) {
      // Reset the image upload message and cropped image file
      croppedImageFile.value = null
      showImageUploadMessage.value = false
    }

    originalDesignTemplateState.value = cloneable.deepCopy(currentDesignTemplate.value)

    // reset the unsaved changes
    hasUnsavedChanges.value = false
    imageHasUnsavedChanges.value = false
    designTemplateHadAlreadyAnImage.value = !!currentDesignTemplate.value.logos?.length

    toast.success(t('views.templates.design.designTemplateUpdated'))
  } else {
    await createDesignTemplate(currentDesignTemplate.value)

    await router.push({
      name: 'templates-design-update',
      params: { id: currentDesignTemplate.value.id }
    })

    // after route change, reset the unsaved changes
    hasUnsavedChanges.value = false
    imageHasUnsavedChanges.value = false
    designTemplateHadAlreadyAnImage.value = !!currentDesignTemplate.value.logos?.length

    toast.success(t('views.templates.design.designTemplateCreated'))
  }

  showMultilanguageCheckbox.value = !currentDesignTemplate.value.isMultilanguage
}

function handleImagePositionChange(newValue: string) {
  currentDesignTemplate.value.positions.logo = newValue as string
}

// This function will change the image upload message to 'uploaded' after the image is uploaded
function imagePreviewUploaded() {
  imageUploadMessage.value = 'uploaded'
  showRemoveTemporaryImageButton.value = true

  imageHasUnsavedChanges.value =
    !designTemplateHadAlreadyAnImage.value ||
    (designTemplateHadAlreadyAnImage.value && imageTemporaryDeleted.value)

  // Compare old and new event state
  const differences = findDifferences(
    originalDesignTemplateState.value,
    currentDesignTemplate.value
  )
  // Update hasUnsavedChanges by checking if there are differences in the event or if the image has unsaved changes
  hasUnsavedChanges.value = differences.length > 0 || imageHasUnsavedChanges.value
}

// This function will handle the deletion of the temporary image before uploading a new one or deleting the current image definitively
async function handleDeleteImageTemporary() {
  imageTemporaryDeleted.value = true
  showRemoveTemporaryImageButton.value = false

  imageCropperRef.value?.reset()

  imageHasUnsavedChanges.value = designTemplateHadAlreadyAnImage.value

  // Compare old and new design template state
  const differences = findDifferences(
    originalDesignTemplateState.value,
    currentDesignTemplate.value
  )
  // Update hasUnsavedChanges by checking if there are differences in the design template or if the image has unsaved changes
  hasUnsavedChanges.value = differences.length > 0 || imageHasUnsavedChanges.value
}

// change the value of currentDesignTemplateLanguage to the current selected language in the toggle component
function handleDesignTemplateLanguageChange(newValue: string) {
  currentDesignTemplateLanguage.value = newValue as 'de' | 'en'
}

/**
 * Add/remove a second footer to the design
 */

function addSecondFooter() {
  currentDesignTemplate.value.footer.push({
    position: 'right',
    text: { de: '<p></p>', en: '<p></p>' }
  })
  adjustSideHeights()
}

function removeSecondFooter() {
  currentDesignTemplate.value.footer.splice(1, 1)
  adjustSideHeights()
}

function adjustSideHeights() {
  // Use nextTick to ensure DOM updates
  nextTick(() => {
    if (formLeftSideRef.value) {
      leftSideHeight.value = formLeftSideRef.value.offsetHeight
      showRightSide.value = true
    }
  })
}

function handleSelectDeviceForPreview(newValue: string) {
  selectDeviceForPreview.value = newValue
  previewMobile.value = newValue === 'mobile'
  previewDesktop.value = newValue === 'desktop'
}

// watch for changes in the current design and update the preview design and the colors in DOM
watch(
  currentDesignTemplate,
  async (newCurrentDesignTemplate) => {
    if (newCurrentDesignTemplate) {
      // Perform the async update if the previewDesignTemplate has an id
      if (previewDesignTemplate.value.id) {
        await updatePreviewDesignTemplate(
          +previewDesignTemplate.value.id,
          currentDesignTemplate.value as UpdateDesignPreviewDto
        )
      }

      // Update colors after updating the preview design template
      updateColors(previewDesignTemplate.value.colors as any as DesignColorVariables)

      // Adjust side heights whenever the design template changes
      adjustSideHeights()

      let differences
      if (originalDesignTemplateState.value !== null) {
        // Compare each property
        differences = findDifferences(
          originalDesignTemplateState.value,
          currentDesignTemplate.value
        )
        // Update hasUnsavedChanges
        hasUnsavedChanges.value = differences.length > 0 || imageHasUnsavedChanges.value
      }
    }
  },
  { deep: true }
)

// Check for unsaved changes before navigating away
onBeforeRouteLeave((to, from, next) => {
  if (hasUnsavedChanges.value) {
    triggerUnsavedChangesModal(to.path)
    next(false) // Prevent navigation
  } else {
    next() // Allow navigation
  }
})
</script>

<template>
  <MainLayout>
    <section class="w-full text-dark-grey dark:text-light-grey">
      <!-- the class 'group/form' triggers the form validation classes of the submit button -->
      <form @submit.prevent @keydown.enter.prevent class="group/form" novalidate>
        <div class="mb-10 flex flex-col items-start">
          <!-- If the designTemplateId exists, the name of the template will be displayed and it's editable, otherwise an input field will be displayed to give it a name -->
          <div v-if="designTemplateId" class="relative">
            <h1 class="mr-8 text-[32px] font-bold lg:text-[42px] xl:text-[58px]">
              {{ currentDesignTemplate.name || t('views.templates.registration.newForm') }}
            </h1>
            <VButton
              type="button"
              appearance="empty"
              size="medium"
              class="absolute right-0 top-0 cursor-pointer"
              :functionOnClick="openModalAndSaveTempTemplateName"
            >
              <FontAwesomeIcon :icon="['fal', 'pen-circle']" class="size-5 p-1.5" />
            </VButton>
          </div>
          <VInput
            v-else
            v-model="currentDesignTemplate.name"
            :label="t('global.name')"
            placeholder="Design template's name"
            help=""
            class="w-300px"
            type="text"
            inputId="design-template-name"
            :required="true"
            :tooltip="t('global.requiredField')"
            :errorMessage="t('global.invalidValue')"
          />
        </div>
        <div class="prose-ul:list-none">
          <div
            class="grid grid-cols-1 rounded-lg bg-white p-10 shadow xl:grid-cols-2 xl:flex-row xl:gap-16
              dark:bg-dark-grey"
          >
            <div ref="formLeftSideRef" class="w-full">
              <div v-if="currentDesignTemplate.colors" class="flex w-full flex-col gap-4">
                <p class="text-base font-bold text-dark-grey dark:text-light-grey">
                  {{ t('global.colors') }}
                </p>
                <div class="grid w-full gap-4 md:grid-cols-2">
                  <VColorpicker
                    v-for="(color, key) in currentDesignTemplate.colors"
                    :inputId="String(key)"
                    v-model="currentDesignTemplate.colors[key]"
                    :label="t(`views.templates.design.${key}`)"
                    :key="key"
                    :className="'[&_div]:text-left [&_span]:hyphens-auto'"
                  />
                </div>
              </div>
              <hr class="my-10" />
              <div class="mb-10 flex flex-col gap-4 lg:gap-8">
                <div class="flex flex-col gap-2">
                  <div class="flex items-end justify-between">
                    <label
                      class="flex flex-row gap-2 text-base font-bold text-dark-grey dark:text-light-grey"
                    >
                      <span>{{ t('views.templates.design.logo') }}</span>
                      <span class="group relative">
                        <VTooltip
                          :modelValue="t('global.imageDeletedAfterSave')"
                          :position="'center'"
                          :className="'-top-5 text-sm'"
                        />
                        <FontAwesomeIcon :icon="['fal', 'circle-info']" />
                      </span>
                    </label>
                    <VButton
                      v-if="imageUploadMessage === 'uploaded' && showRemoveTemporaryImageButton"
                      type="button"
                      appearance="empty"
                      :function-on-click="handleDeleteImageTemporary"
                    >
                      <FontAwesomeIcon :icon="['fal', 'xmark']" class="size-5" />
                    </VButton>
                  </div>
                  <div
                    v-if="currentDesignTemplate.logos?.[0]?.public_id && !imageTemporaryDeleted"
                    class="mt-2 flex flex-col gap-4"
                  >
                    <div class="flex flex-row items-start gap-4">
                      <CloudImage
                        :imageName="currentDesignTemplate.logos[0].public_id"
                        class="max-h-80 max-w-[calc(100%-3rem)] rounded object-cover"
                      />
                      <VButton
                        type="button"
                        appearance="empty"
                        size="small"
                        :function-on-click="handleDeleteImageTemporary"
                      >
                        <FontAwesomeIcon :icon="['fal', 'trash-can']" class="size-5 p-1.5" />
                      </VButton>
                    </div>
                    <p class="text-xs">
                      {{ getImageName(currentDesignTemplate.logos[0].public_id) }}
                    </p>
                  </div>
                  <div v-else class="flex flex-col">
                    <p
                      v-if="showImageUploadMessage"
                      class="mt-2 flex flex-row items-center justify-start gap-2 text-sm font-bold"
                      :class="{
                        'text-dark-yellow dark:text-light-yellow':
                          imageUploadMessage === 'uploading',
                        'text-dark-green dark:text-light-green': imageUploadMessage !== 'uploading'
                      }"
                    >
                      <FontAwesomeIcon
                        :icon="['fal', 'spinner-third']"
                        class="mr-2 size-5 h-4 w-4 animate-spin p-1.5 text-dark-yellow dark:text-light-yellow"
                      />
                      {{
                        imageUploadMessage === 'uploading'
                          ? t('views.assets.assetBeingUploaded')
                          : t('views.assets.assetSuccessfullyUploaded')
                      }}
                    </p>
                    <div v-else class="flex w-full flex-row items-start gap-2">
                      <ImageCropper
                        ref="imageCropperRef"
                        cropForm="free"
                        @imageCropped="imageCropped"
                        @imagePreviewUploaded="imagePreviewUploaded"
                      />
                    </div>
                  </div>
                </div>
                <VToggleTwoOptions
                  v-model="currentDesignTemplate.positions.logo"
                  :title="t('views.templates.design.logoPosition')"
                  :input-id="'currentDesignTemplate.logo.title'"
                  :leftOptionLabel="t('global.left')"
                  leftOptionValue="left"
                  :rightOptionLabel="t('global.right')"
                  rightOptionValue="right"
                  @change="handleImagePositionChange"
                  class="self-startcc gap-4"
                />
              </div>
              <hr class="my-10" />
              <!-- Footer -->
              <div class="my-5 mr-auto flex w-full flex-row items-center justify-start gap-4">
                <div class="flex w-full flex-row items-end justify-between">
                  <div class="flex flex-col">
                    <VCheckbox
                      v-if="showMultilanguageCheckbox"
                      v-model="currentDesignTemplate.isMultilanguage"
                      input-id="webform-template-multilanguage"
                      :label="t('views.templates.registration.webformTemplateIsMultilanguage')"
                      :help="t('views.templates.registration.webformTemplateIsMultilanguage')"
                      class="w-300px -ml-3"
                    />
                    <VToggleTwoOptions
                      v-if="currentDesignTemplate.isMultilanguage"
                      v-model="currentDesignTemplateLanguage"
                      input-id="currentDesignTemplateLanguage"
                      leftOptionValue="de"
                      rightOptionValue="en"
                      @change="handleDesignTemplateLanguageChange"
                    />
                  </div>
                  <VButton
                    v-if="
                      currentDesignTemplate.footer.length < 2 &&
                      currentDesignTemplate.isMultilanguage
                    "
                    type="button"
                    appearance="default"
                    :label="t('views.templates.design.addFooter')"
                    :disabled="false"
                    size="medium"
                    :function-on-click="addSecondFooter"
                  />
                </div>
              </div>
              <div
                v-if="currentDesignTemplate.footer.length > 0"
                :class="
                  currentDesignTemplate.footer.length === 1
                    ? 'w-full'
                    : 'grid grid-cols-1 gap-4 lg:gap-6'
                "
              >
                <QuillEditor
                  v-for="(footerItem, index) in currentDesignTemplate.footer"
                  :key="index"
                  :className="'col-span-1'"
                  :inputId="'design-template-footer-' + index"
                  ref="quillEditorDesignTemplateFooter"
                  :label="
                    currentDesignTemplate.footer.length === 1
                      ? 'Footer'
                      : index === 0
                        ? `Footer ${t('global.left')}`
                        : `Footer ${t('global.right')}`
                  "
                  v-model:content="footerItem.text[currentDesignTemplateLanguage]"
                  contentType="html"
                >
                  <template #icon>
                    <VButton
                      v-if="index === 1"
                      type="button"
                      appearance="empty"
                      size="medium"
                      :function-on-click="removeSecondFooter"
                    >
                      <FontAwesomeIcon :icon="['fal', 'trash-can']" class="mr-2 size-5 p-1.5" />
                    </VButton>
                  </template>
                </QuillEditor>
              </div>
              <VButton
                v-if="
                  currentDesignTemplate.footer.length < 2 && !currentDesignTemplate.isMultilanguage
                "
                type="button"
                appearance="default"
                :label="t('views.templates.design.addFooter')"
                :disabled="false"
                size="medium"
                :function-on-click="addSecondFooter"
                :class-name="'mt-6'"
              />
            </div>
            <hr class="my-10 xl:hidden" />
            <div
              v-if="showRightSide"
              ref="formRightSideRef"
              class="flex flex-col overflow-hidden"
              :style="{ maxHeight: leftSideHeight + 'px' }"
            >
              <div class="relative">
                <p class="mb-2 text-base font-bold text-dark-grey dark:text-light-grey">
                  {{ t('views.events.design.preview') }}
                </p>
                <VToggleTwoOptions
                  v-model="selectDeviceForPreview"
                  :input-id="'device-for-preview'"
                  :leftOptionLabel="t('global.mobile')"
                  leftOptionValue="mobile"
                  :rightOptionLabel="t('global.desktop')"
                  rightOptionValue="desktop"
                  @change="handleSelectDeviceForPreview"
                  class="absolute right-0 top-0"
                />
                <VTabNavigator :key="tabNavigatorKey" :tabs="tabs" v-model:modelValue="currentTab">
                  <!-- Access Tab Content -->
                  <!-- 133px is the height of the label and info above tab navigator -->
                  <div
                    class="overflow-auto"
                    :class="{ hidden: currentTab !== 'access' }"
                    :style="{ maxHeight: Number(leftSideHeight) - 133 + 'px' }"
                  >
                    <Show
                      :isPreview="true"
                      :view="selectDeviceForPreview"
                      :entityType="'designTemplate'"
                    />
                  </div>
                  <!-- No Access Tab Content -->
                  <!-- 133px is the height of the label and info above tab navigator -->
                  <div
                    class="overflow-auto"
                    :class="{ hidden: currentTab !== 'noaccess' }"
                    :style="{ maxHeight: Number(leftSideHeight) - 133 + 'px' }"
                  >
                    <ShowLogin
                      :isPreview="true"
                      :view="selectDeviceForPreview"
                      :entityType="'designTemplate'"
                    />
                  </div>
                </VTabNavigator>
              </div>
            </div>
          </div>
          <div class="mt-10 flex justify-end gap-8">
            <I18nRouterLink to="/templates/design" class="overflow-hidden">
              <VButton
                type="button"
                appearance="cancel"
                :label="t('global.cancel')"
                :disabled="false"
                size="large"
                :functionOnClick="confirmNavigation"
              />
            </I18nRouterLink>
            <VButton
              type="submit"
              appearance="default"
              :label="currentDesignTemplate.id ? t('global.update') : t('global.save')"
              :disabled="!hasUnsavedChanges"
              size="large"
              :functionOnClick="handleCreateDesignTemplate"
            />
          </div>
        </div>
      </form>
    </section>
    <!-- Modal to edit the name of the design template -->
    <VModal
      v-model:trigger="openEditTemplateNameModal"
      :functionOnClose="
        () => {
          currentDesignTemplate.name === ''
            ? (currentDesignTemplate.name = savedTempOldTemplateName)
            : currentDesignTemplate.name
          savedTempOldTemplateName = ''
          openEditTemplateNameModal = false
        }
      "
      includeForm
    >
      <template #modalBody>
        <VInput
          v-model="currentDesignTemplate.name"
          :label="t('global.name')"
          placeholder="Design template's name"
          help=""
          class="w-300px"
          type="text"
          inputId="design-template-name"
          :required="true"
          :tooltip="t('global.requiredField')"
          :errorMessage="t('global.invalidValue')"
        />
      </template>
      <template #modalFooter>
        <div class="flex justify-end">
          <VButton
            type="submit"
            size="medium"
            appearance="default"
            :label="t('global.close')"
            :functionOnClick="() => (openEditTemplateNameModal = false)"
          />
        </div>
      </template>
    </VModal>
    <!-- Unsaved Changes Modal -->
    <VModal
      :trigger="isUnsavedChangesModalOpen"
      :function-on-close="
        () => {
          isUnsavedChangesModalOpen = false
        }
      "
    >
      <template #modalHeader>
        <p class="text-center text-xl uppercase text-dark-grey dark:text-light-grey">
          {{ t('views.events.index.unsavedChangesTitle') }}
        </p>
      </template>
      <template #modalBody>
        <p class="text-dark-grey dark:text-light-grey">
          {{ t('views.events.index.unsavedChangesMessage') }}
        </p>
      </template>
      <template #modalFooter>
        <div class="flex justify-between">
          <VButton
            type="button"
            appearance="cancel"
            :label="t('global.cancel')"
            size="medium"
            :functionOnClick="
              () => {
                isUnsavedChangesModalOpen = false
              }
            "
          />
          <VButton
            type="button"
            appearance="default"
            :label="t('global.continue')"
            size="medium"
            :functionOnClick="confirmNavigation"
          />
        </div>
      </template>
    </VModal>
  </MainLayout>
</template>
