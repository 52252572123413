<script setup lang="ts">
import { VButton, VCard } from '@techcast/histoire'

import { storeToRefs } from 'pinia'
import { computed, onMounted, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'

import CloudImage from '@/components/utils/CloudImage.vue'
import { useEventSetup } from '@/composables/event/useEventSetup'
import { cloneable } from '@/composables/useClone'
import { asyncDefault, mappedFrontendAccessComponents } from '@/layouts/client/AccessLayouts'
import { useDesignTemplatesStore } from '@/stores/designTemplates.store'
import { useDesignsStore } from '@/stores/designs.store'
import { previewEvent } from '@/stores/objects/previewEvent'
import type { DesignColorVariables } from '@/types/DesignColorVariables'
import type { components } from '@/types/swagger'
import { arraySortedByStringProperty } from '@/utils/arraySortedByStringProperty'
import { getFormattedDate } from '@/utils/getFormattedDate'
import { getImageName } from '@/utils/getImageName'
import { updateColors } from '@/utils/updateClientFrontendColors'

/**
 * Router
 */
const route = useRoute()

/****************************************
* TYPES
*****************************************/
type AgendaItem = components['schemas']['AgendaItem']

/****************************************
* COMPOSABLES
*****************************************/
const { i18n, t, eventStore, eventId, currentEvent, currentEventLanguage, layoutComponent } =
  useEventSetup()

/****************************************
* STORES
*****************************************/
const designStore = useDesignsStore()
const { currentDesign } = storeToRefs(designStore)
const { fetchDesignById, resetCurrentDesign } = designStore
const designTemplateStore = useDesignTemplatesStore()
const { currentDesignTemplate } = storeToRefs(designTemplateStore)

/**
 * ---------- Props ----------
 */
const props = withDefaults(
  defineProps<{
    isPreview: boolean // Prop to determine if it's a preview (child component) or acts as a site
    view?: string // Prop for view type (e.g., mobile, desktop)
    entityType?: string // Type of the entity (e.g., design, designTemplate)
  }>(),
  {
    isPreview: false
  }
)
const isPreviewDesign = props.entityType === 'design'
const isPreviewDesignTemplate = props.entityType === 'designTemplate'

/****************************************
* COMPUTED VARIABLES
*****************************************/
const eventName = computed(() => {
  const name = route.params.eventName || null
  return name ? name.replace(/-$/, '') : null // Remove last '-' character if it exists
})

// Determine the source of data (currentEvent or designTemplate) based on the entity type
const source = computed(() =>
  isPreviewDesignTemplate
    ? {
        ...previewEvent,
        design: { ...currentDesignTemplate.value, logos: currentDesignTemplate.value.logos }
      }
    : { ...currentEvent.value, design: currentDesign.value }
)

/****************************************
* LIFECYCLE HOOKS
*****************************************/
onMounted(async () => {
  if (eventId.value && !isPreviewDesignTemplate) {
    // Update the currentEvent reference with the fetched event
    await eventStore.fetchEventById(+eventId.value, true)

    if (currentEvent.value.design?.id) {
      await fetchDesignById(+currentEvent.value.design.id)
    }
  }

  updateColors(source.value.design.colors as any as DesignColorVariables)

  // Look for the current locale of this event: if the event has a URL with the same name as the event, it means that the event is available in this language
  if (currentEvent.value && currentEvent.value.url) {
    for (const key of Object.keys(currentEvent.value.url)) {
      const eventUrlValue = currentEvent.value.url[key]
      const eventNameValue =
        typeof eventName.value === 'string' ? eventName.value.replace(/-$/, '') : eventName.value
      if (cloneable.isEqual(eventUrlValue, eventNameValue)) {
        // console.log('Event language found: ', key)

        currentEventLanguage.value = key
      }
    }
  }
})

onUnmounted(() => {
  resetCurrentDesign()
})
</script>

<template>
  <div
    v-if="currentEvent"
    :class="[
      'client-container',
      {
        'preview-wrapper': isPreviewDesign || isPreviewDesignTemplate,
        mobile: props.view === 'mobile'
      }
    ]"
  >
    <header class="header-background-color header-text-color relative">
      <nav
        v-if="source.isMultilanguage && source.languages && source.languages.length > 1"
        class="container mx-auto flex max-w-screen-xl justify-end p-3 pb-0 sm:px-8 2xl:px-0"
      >
        <template v-for="language in source.languages" :key="language">
          <a
            v-if="language !== currentEventLanguage"
            target="_blank"
            :href="`/events-public/${source.url[language]}-${source.id}`"
          >
            <VButton
              :input-id="language"
              type="button"
              appearance="empty"
              has-language
              :language="language"
              size="small"
            />
          </a>
        </template>
      </nav>
      <div
        class="container relative z-10 mx-auto flex max-w-screen-xl flex-col items-start justify-between gap-6 p-3
          pb-16 sm:flex-row sm:gap-8 sm:p-8 sm:pb-16 2xl:px-0"
      >
        <div
          :class="{
            'sm:order-2': source.design?.positions?.logo === 'right',
            'flex h-32 w-48 items-start justify-start md:h-48': source.design?.logos?.length
          }"
        >
          <CloudImage
            v-if="source.design?.logos?.length"
            :imageName="source.design?.logos[0]?.['public_id']"
            class="max-h-full max-w-full"
            :alt="getImageName(source.design?.logos[0]?.['public_id'])"
          />
        </div>
        <div
          class="flex flex-col items-start"
          :class="source.design?.positions?.logo === 'right' ? '' : 'sm:items-end'"
        >
          <h1
            v-if="source.name"
            class="mb-1 text-left text-4xl font-bold sm:mb-2"
            :class="{
              'sm:self-end sm:text-right': source.design?.positions?.logo !== 'right'
            }"
          >
            {{ source.name[currentEventLanguage] }}
          </h1>
          <p
            v-if="source.subtitle"
            class="my-1 text-left text-xl sm:my-2"
            :class="{
              'sm:self-end sm:text-right': source.design?.positions?.logo !== 'right'
            }"
          >
            {{ source.subtitle[currentEventLanguage] }}
          </p>
          <p
            v-if="source.startDate"
            class="my-1 text-left text-xl sm:my-2"
            :class="{
              'sm:self-end sm:text-right': source.design?.positions?.logo !== 'right'
            }"
          >
            {{ getFormattedDate(i18n.locale.value, source.startDate) }}
          </p>
        </div>
      </div>
    </header>
    <component
      :is="mappedFrontendAccessComponents[layoutComponent] || asyncDefault"
      class="page-background-color primary-text-color"
    >
      <template #eventVideo>
        <div id="video-container" v-if="source.livestream" class="aspect-video w-full">
          <!-- TODO: Add video player -->
        </div>
        <!-- If there is no streaming, show the previewImage of the event -->
        <CloudImage
          v-else-if="source.previewImages?.length"
          :imageName="source.previewImages?.[0]?.['public_id']"
          class="max-h-full max-w-full"
          :alt="getImageName(source.previewImages?.[0]?.['public_id'])"
        />
        <!-- Fallback image -->
        <img
          v-else
          class="aspect-video w-full"
          src="https://images.unsplash.com/photo-1540575467063-178a50c2df87?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          :alt="source.name?.[currentEventLanguage] || 'Event placeholder'"
        />
      </template>

      <template #eventDescription v-if="source.description">
        <div class="mb-10">
          <p>{{ source.description[currentEventLanguage] }}</p>
        </div>
      </template>

      <template #eventRtaQuestions v-if="source.rta?.interaction">
        <p>#eventRtaQuestions</p>
      </template>

      <template #eventRtaMessages v-if="source.rta?.interaction">
        <p>#eventRtaMessages</p>
      </template>

      <template #eventRtaVideocall v-if="source.rta?.videocall">
        <p>#eventRtaVideocall</p>
      </template>

      <template #eventRtaChat v-if="source.rta?.chat">
        <p>#eventRtaChat</p>
      </template>

      <template #eventAgenda>
        <template v-if="source.agendaItems && source.agendaItems.length > 0">
          <div class="mb-10 w-full min-w-0 flex-col p-6 md:p-10">
            <h3 class="mb-8 mt-0 font-bold uppercase">{{ $t('global.agenda') }}</h3>
            <div
              v-for="(item, index) in arraySortedByStringProperty(
                source.agendaItems as AgendaItem[],
                'startDate'
              )"
              class="flex-auto"
              :key="item.id"
            >
              <div
                class="mb-6 grid grid-cols-4 gap-6 p-2"
                :class="{
                  'background-pause py-4': item.type === 'pause',
                  'has-separator': item.type !== 'pause'
                }"
              >
                <div
                  class="col-span-4 mb-4 w-full sm:col-span-1 md:col-span-4 lg:col-span-1 xl:col-span-1"
                >
                  <p class="mb-0 mt-0 font-bold">
                    <span>{{ getFormattedDate(currentEventLanguage, item.startDate, true) }}</span>
                    -
                    <span>{{ getFormattedDate(currentEventLanguage, item.endDate, true) }}</span>
                  </p>
                </div>
                <div
                  class="col-span-4 w-full sm:col-span-3 md:col-span-4 lg:col-span-3 xl:col-span-3"
                >
                  <p class="mb-4 mt-0 text-xl font-bold">
                    {{ item.title[currentEventLanguage] }}
                  </p>
                  <template v-if="item.type !== 'pause'">
                    <p
                      v-for="(speaker, i) in item.speakers"
                      :key="`${speaker.firstName} ${speaker.lastName}`"
                      class="mb-4 mt-0 inline-block font-bold uppercase"
                    >
                      <span>{{ speaker.firstName }}&nbsp;{{ speaker.lastName }}</span>
                      <span v-if="i < item.speakers.length - 1" class="text-lg">&nbsp;|&nbsp;</span>
                    </p>
                    <p class="mb-4 mt-0 block">
                      {{ item.description[currentEventLanguage] }}
                    </p>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </template>
      </template>

      <template #eventDownloads>
        <template v-if="source.downloads?.length">
          <p>#eventDownloads</p>
        </template>
        <template v-else>
          <p>{{ $t('views.event.show.noDownloads') }}</p>
        </template>
      </template>

      <template #eventLinks>
        <template v-if="source.links?.length">
          <p>#eventLinks</p>
        </template>
        <template v-else>
          <p>{{ $t('views.event.show.noLinks') }}</p>
        </template>
      </template>

      <template #eventSpeakers v-if="source.speakers?.length">
        <h3 class="mb-8 mt-0 font-bold uppercase">{{ $t('global.speakers') }}</h3>
        <div class="grid gap-10 sm:grid-cols-2 lg:grid-cols-3">
          <div
            v-for="(speaker, index) in source.speakers"
            :key="`${speaker.firstName} ${speaker.lastName}`"
            class="sm:col-span-1 lg:col-span-1"
          >
            <VCard
              :title="`${speaker.firstName} ${speaker.lastName}`"
              :subtitle="speaker.position"
              :additionalInfo="speaker.company"
              :description="speaker.vita[currentEventLanguage]"
              :image="{
                url:
                  speaker.images?.[0]?.secure_url ||
                  'https://images.unsplash.com/photo-1534528741775-53994a69daeb?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8cG9ydHJhaXR8ZW58MHx8MHx8fDA%3D',
                title: `${speaker.firstName} ${speaker.lastName}` || 'Example Image'
              }"
              :trigger-label="t('global.more')"
            />
          </div>
        </div>
      </template>
    </component>
    <footer class="footer-background-color footer-text-color">
      <div
        class="container mx-auto max-w-screen-xl flex-row justify-between p-3 sm:flex sm:p-8 2xl:px-0"
      >
        <div class="flex w-full flex-col gap-8 md:flex-row md:justify-between">
          <div
            v-if="source.design?.footer?.[0]"
            :class="
              source.design?.footer?.length === 1
                ? 'flex w-full justify-center text-left'
                : 'w-full text-left md:w-1/2'
            "
          >
            <slot name="footerLeft">
              <div v-html="source.design?.footer?.[0].text[currentEventLanguage]"></div>
            </slot>
          </div>
          <div v-if="source.design?.footer?.[1]" class="w-full text-left md:w-1/2 md:text-right">
            <slot name="footerRight">
              <div v-html="source.design?.footer?.[1].text[currentEventLanguage]"></div>
            </slot>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<style>
.has-separator {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.background-pause {
  background-color: rgba(255, 255, 255, 0.3);
}
.preview-wrapper {
  &.mobile {
    .container {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      margin: 0 auto;
    }
    header {
      .container {
        flex-direction: column;
        > div {
          align-items: flex-start;
          &:nth-of-type(1) {
            order: 1 !important;
          }
          &:nth-of-type(2) {
            order: 2 !important;
          }
          * {
            text-align: left;
            align-self: flex-start;
          }
        }
      }
    }
  }
}
</style>
