<script setup lang="ts">
import { VButton, VModal, VTable, type VTableColumn } from '@techcast/histoire'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { storeToRefs } from 'pinia'
import { type ComputedRef, computed, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouterLink } from 'vue-router'
import { useToast } from 'vue-toastification'

import I18nRouterLink from '@/components/utils/I18nRouterLink.vue'
import { useUrlToFile } from '@/composables/useUrlToFile'
import MainLayout from '@/layouts/MainLayout.vue'
import { useDesignTemplatesStore } from '@/stores/designTemplates.store'
import type { components } from '@/types/swagger'
import { cloneable } from '@/composables/useClone'

/****************************************
* NOTIFICATIONS
*****************************************/
const toast = useToast()

/****************************************
* TRANSLATIONS
*****************************************/
const { t, locale } = useI18n()

/****************************************
* TYPES
*****************************************/
type DesignTemplate = components['schemas']['DesignTemplate']
type CreateDesignTemplateDto = components['schemas']['CreateDesignTemplateDto']

/****************************************
* STORES
*****************************************/
const designTemplateStore = useDesignTemplatesStore()
const { designTemplates } = storeToRefs(designTemplateStore)
const {
  fetchDesignTemplateById,
  fetchAllDesignTemplates,
  createDesignTemplate,
  deleteDesignTemplate,
  resetCurrentDesignTemplate
} = designTemplateStore

/****************************************
* LIFECYCLE HOOKS
*****************************************/
onMounted(async () => {
  await fetchAllDesignTemplates()
})

onUnmounted(() => {
  resetCurrentDesignTemplate()
})

/****************************************
* COMPOSABLES
*****************************************/
const { urlToFile } = useUrlToFile()

/****************************************
* REFS
*****************************************/
const isDeleteModalOpen = ref<boolean>(false) // State to track whether the delete confirmation modal is open.
const designTemplateToDelete = ref<DesignTemplate | null>(null) // The design template to be deleted.

/****************************************
* COMPUTED VARIABLES
*****************************************/
const columns: ComputedRef<VTableColumn[]> = computed(() => [
  // Define the columns for the table displaying design templates.
  {
    title: t('global.title'),
    name: 'name',
    sortable: true,
    align: 'left',
    valign: 'middle',
    keys: ['name'],
    showTooltip: false,
    keyPositionInSmallDevices: 3
  },
  {
    title: t('global.actions'),
    name: 'id',
    align: 'right',
    valign: 'middle',
    keys: ['id'],
    keyPositionInSmallDevices: 1
  }
])

/****************************************
* METHODS
*****************************************/
/**
 * Open the delete confirmation modal for a specific design template.
 * @param designTemplateId - The ID of the design template to delete.
 */
async function openDeleteModal(designTemplateId: number) {
  await fetchDesignTemplateById(designTemplateId).then((response) => {
    designTemplateToDelete.value = response
    isDeleteModalOpen.value = true
  })
}

/**
 * Close the delete confirmation modal.
 */
function closeModal() {
  isDeleteModalOpen.value = false
}

/**
 * Handle the deletion of a design template.
 * @param designTemplateId - The ID of the design template to delete.
 */
async function handleDeleteDesignTemplate(designTemplateId: number) {
  await deleteDesignTemplate(designTemplateId).then((response) => {
    if (response.ok) {
      closeModal()
      designTemplateToDelete.value = null
      toast.success(t('views.templates.design.designTemplateDeleted'))
    }
  })
}

/**
 * Clone an existing design template.
 * @param templateId - The ID of the design template to clone.
 */
async function cloneTemplate(templateId: number) {
  // Fetch the design template to be cloned
  const originalTemplate = await fetchDesignTemplateById(templateId)

  // Create a deep copy of the original template, excluding the `id` field
  const { id, logos, ...clonedTemplateData } = cloneable.deepCopy(originalTemplate)

  const imageFile = ref<File | null>(null)

  // Check if there is exactly one logo
  if (originalTemplate.logos && originalTemplate.logos.length === 1) {
    const logo = originalTemplate.logos[0]
    const { secure_url: url, public_id, format } = logo
    const filename = `${public_id}.${format}`

    // Convert the logo URL to a File object
    imageFile.value = await urlToFile(url, filename, `image/${format}`)
  }

  // Optionally rename the clone
  const clonedTemplate: CreateDesignTemplateDto = {
    ...clonedTemplateData,
    name: `${t('global.cloneOf')} ${originalTemplate.name}`
  }

  // Create the cloned design template on the backend
  await createDesignTemplate(clonedTemplate, imageFile.value!)
}
</script>

<template>
  <MainLayout>
    <section class="w-full text-dark-grey dark:text-light-grey">
      <div class="mb-10 flex flex-wrap items-center">
        <h1 class="mr-8 text-[32px] font-bold lg:text-[42px] xl:text-[58px]">
          {{ t('global.design') }}
        </h1>
        <I18nRouterLink to="/templates/design/new">
          <VButton
            type="button"
            appearance="default"
            :label="t('views.templates.design.newDesignTemplate')"
            size="large"
          >
            <FontAwesomeIcon :icon="['fal', 'circle-plus']" />
          </VButton>
        </I18nRouterLink>
      </div>
      <VTable
        v-if="designTemplates.length > 0"
        :columns="columns"
        :data="designTemplates"
        :filterButtonsInSmallDevices="['name']"
      >
        <template #name="{ value }">
          <span class="text-lg font-semibold">
            {{ value }}
          </span>
        </template>
        <template #id="{ value }">
          <VButton type="button" appearance="empty">
            <RouterLink :to="`/${locale}/templates/design/${value}/update`">
              <FontAwesomeIcon :icon="['fal', 'pen-circle']" class="mr-2 size-5 p-1.5" />
            </RouterLink>
          </VButton>
          <VButton type="button" appearance="empty" :function-on-click="() => cloneTemplate(value)">
            <FontAwesomeIcon :icon="['fal', 'clone']" class="mr-2 size-5 p-1.5" />
          </VButton>
          <VButton
            type="button"
            appearance="empty"
            size="medium"
            class="cursor-pointer"
            :functionOnClick="() => openDeleteModal(Number(value))"
          >
            <FontAwesomeIcon :icon="['fal', 'trash-can']" class="mr-2 size-5 p-1.5" />
          </VButton>
        </template>
      </VTable>
      <div
        v-else
        class="h-[calc(100svh-18.5rem)] overflow-y-auto rounded-lg bg-white p-10 shadow dark:bg-dark-grey"
      >
        <div>
          <p class="mb-4">{{ t('views.templates.design.noDesignTemplatesFound') }}</p>
        </div>
      </div>
    </section>
    <template #modal>
      <VModal v-model:trigger="isDeleteModalOpen" @update:trigger="isDeleteModalOpen = $event">
        <template #modalHeader>
          <p
            v-if="designTemplateToDelete"
            class="text-center text-xl uppercase text-dark-grey dark:text-light-grey"
          >
            {{ designTemplateToDelete.name }}
          </p>
        </template>
        <template #modalBody>
          <p class="text-dark-grey dark:text-light-grey">
            {{ t('views.templates.design.confirmDeleteDesignTemplate') }}
          </p>
        </template>
        <template #modalFooter>
          <div class="flex justify-between">
            <VButton
              type="button"
              appearance="cancel"
              :label="t('global.cancel')"
              size="medium"
              :functionOnClick="closeModal"
            />
            <VButton
              v-if="designTemplateToDelete"
              type="button"
              appearance="default"
              :label="t('global.delete')"
              size="medium"
              :functionOnClick="
                () => {
                  handleDeleteDesignTemplate(Number(designTemplateToDelete!.id))
                }
              "
            />
          </div>
        </template>
      </VModal>
    </template>
  </MainLayout>
</template>
