<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { twMerge } from 'tailwind-merge'
import { computed, provide } from 'vue'
import { useI18n } from 'vue-i18n'

import I18nRouterLink from '@/components/utils/I18nRouterLink.vue'
import { useNavStore } from '@/stores/nav.store'
import { useLocalePath } from '@/utils/useLocalePath'

/**
 * This Vue component creates a navigation link for the left menu. It determines if the link is active
 * by comparing the current route to the target route or checking if the target route is a parent of
 * the current route. The `I18nRouterLink` is used to manage the locale in the path, also css classes can be
 * added for styling. The `isActive` value is provided to child components via the provide/inject API
 * and is computed based on route comparisons. The `arePathsEqual` function checks for exact matches,
 * while `isTargetParentPath` checks if the target is a parent route.
 */

const props = withDefaults(
  defineProps<{
    /** The route to navigate to */
    to: string
    /** Whether to show the locale in the path */
    noLocale?: boolean
    /** Class attribute */
    class?: string
  }>(),
  {
    class: ''
  }
)

/**
 * We use isOpen from the nav store to determine if the left menu is open.
 */
const navStore = useNavStore()
const { currentRoute, isOpen } = storeToRefs(navStore)
const i18n = useI18n()
const localePath = useLocalePath(props.to, i18n)

/**
 * Check if the current route and the target route are equal.
 */
const arePathsEqual = () => {
  const currentPath = currentRoute.value.replace(/\/$/, '')
  const targetPath = localePath.value.replace(/\/$/, '')

  return currentPath === targetPath
}

/**
 * Check if the target route is a parent of the current route.
 */
const isTargetParentPath = () => {
  const currentPath = currentRoute.value.replace(/\/$/, '')
  const targetPath = localePath.value.replace(/\/$/, '')

  const currentPathSegments = currentPath.split('/')
  const targetPathSegments = targetPath.split('/')

  /**
   * The target path should have fewer segments than the current path to be a parent
   */
  if (targetPathSegments.length >= currentPathSegments.length) {
    return false
  }

  /**
   * Compare each segment of the target path with the current path.
   * If all segments match, the target path is a parent of the current path.
   */
  return targetPathSegments.every((segment, index) => segment === currentPathSegments[index])
}

/**
 * Combine `arePathsEqual` and `isTargetParentPath` to determine if the link is active.
 */
const isActive = computed(() => {
  return arePathsEqual() || isTargetParentPath()
})

/**
 * While RouterLink has its own internal active value, it cannot be accessed directly.
 * Therefore we provide the `isActive` value here and inject it in child components.
 *
 * @example
 * ```ts
 * const isActive = inject('isActive', ref(false))
 * ```
 */
provide('isActive', isActive)
</script>

<template>
  <I18nRouterLink
    v-if="!noLocale"
    :to="to"
    :class="
      twMerge(
        'flex items-center gap-4 px-3.5 text-[22px] text-light-grey [&>*]:shrink-0',
        !isOpen && '[&>span:last-child]:hidden',
        isActive && 'font-bold'
      )
    "
  >
    <slot />
  </I18nRouterLink>
  <RouterLink
    v-if="noLocale"
    :to="to"
    :class="
      twMerge(
        'flex items-center gap-4 px-3.5 text-[22px] text-light-grey [&>*]:shrink-0',
        !isOpen && '[&>span:last-child]:hidden',
        isActive && 'font-bold'
      )
    "
  >
    <slot />
  </RouterLink>
</template>
