<script setup lang="ts">
import TechcastLogoDark from '@/assets/icons/techcast_logo_dark.svg'
import TechcastLogoLight from '@/assets/icons/techcast_logo_light.svg'
import { useDarkMode } from '@/utils/useDarkMode'

const { isDarkMode } = useDarkMode()
</script>

<template>
  <main
    class="font-plus-jakarta flex h-svh w-svw flex-col items-center justify-center bg-ice-grey
      dark:bg-dark-grey"
  >
    <TechcastLogoLight v-if="isDarkMode" />
    <TechcastLogoDark v-if="!isDarkMode" />
    <h1
      class="my-2.5 rounded-lg border border-current px-3.5 py-1.5 text-dark-grey dark:text-light-grey"
    >
      techcast.event.cloud
    </h1>
    <slot />
  </main>
</template>
