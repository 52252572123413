export class cloneable {
	/**
	 * Deep copy an object
	 * @param source The object to copy
	 * @returns The copied object
	 * @example
	 * const obj = { a: 1, b: { c: 2 } };
	 * const copy = cloneable.deepCopy(obj);
	 * 
	 * https://javascript.plainenglish.io/deep-clone-an-object-and-preserve-its-type-with-typescript-d488c35e5574
	 * 
	 */
	public static deepCopy<T>(source: T): T {
		return Array.isArray(source)
			? source.map(item => this.deepCopy(item))
			: source instanceof Date
				? new Date(source.getTime())
				: source && typeof source === 'object'
					? Object.getOwnPropertyNames(source).reduce((o, prop) => {
						Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop)!);
						o[prop] = this.deepCopy((source as { [key: string]: any })[prop]);
						return o;
					}, Object.create(Object.getPrototypeOf(source)))
					: source as T;
	}

	/**
	 * Compare two objects for equality
	 * @param x The first object to compare
	 * @param y The second object to compare
	 * @returns True if both objects are deeply equal, false otherwise
	 * @example
	 * const obj1 = { a: 1, b: { c: 2 } };
	 * const obj2 = { a: 1, b: { c: 2 } };
	 * const isEqual = cloneable.isEqual(obj1, obj2);
	 * 
	 * https://javascript.plainenglish.io/you-dont-need-lodash-how-i-gave-up-lodash-693c8b96a07c
	 * 
	 */
	public static isEqual<T>(x: T, y: T): boolean {
		const ok = Object.keys, tx = typeof x, ty = typeof y;
		if (x && y && tx === 'object' && tx === ty) {
			return ok(x).length === ok(y).length &&
				ok(x).every(key => this.isEqual((x as any)[key], (y as any)[key]));
		} else {
			return x === y;
		}
	}
}