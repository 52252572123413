<script setup lang="ts">
import { VButton, VModal, VTable, type VTableColumn } from '@techcast/histoire'

import { storeToRefs } from 'pinia'
import { type ComputedRef, computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import MainLayout from '@/layouts/MainLayout.vue'
import { useEventsStore } from '@/stores/events.store'
import { useRegistrationsAndWebformSubmissionsStore } from '@/stores/registrationsAndWebformSubmissions.store'

const eventStore = useEventsStore()
const { currentEvent } = storeToRefs(eventStore)
const { fetchEventById } = eventStore
const registrationStore = useRegistrationsAndWebformSubmissionsStore()
const { currentRegistration } = storeToRefs(registrationStore)
const { fetchRegistrationById, deleteRegistration } = registrationStore

const route = useRoute()
const eventId = computed(() => +route.params.id)

const { t } = useI18n()

onMounted(async () => {
  if (eventId.value) {
    await fetchEventById(+eventId.value)
  }
})

const columns: ComputedRef<VTableColumn[]> = computed(() => [
  {
    title: 'id',
    name: 'id',
    align: 'right',
    valign: 'middle',
    keys: ['id'],
    sortable: true,
    keyPositionInSmallDevices: 1
  },
  {
    title: t('global.name'),
    name: 'fullName',
    align: 'right',
    valign: 'middle',
    keys: ['firstName', 'lastName'],
    sortable: true,
    sortBy: 'lastName',
    keyPositionInSmallDevices: 3
  },
  {
    title: t('global.email'),
    name: 'email',
    align: 'right',
    valign: 'middle',
    keys: ['email'],
    sortable: true,
    keyPositionInSmallDevices: 4
  },
  {
    title: t('global.actions'),
    name: 'actions',
    align: 'right',
    valign: 'middle',
    keys: ['id'],
    keyPositionInSmallDevices: 2
  }
])

const isInfoModalOpen = ref<boolean>(false)
const isDeleteModalOpen = ref<boolean>(false)

async function openDeleteModal(registrationId: number) {
  currentRegistration.value = await fetchRegistrationById(registrationId)
  isDeleteModalOpen.value = true
}

async function openInfoModal(registrationId: number) {
  currentRegistration.value = await fetchRegistrationById(registrationId)
  isInfoModalOpen.value = true
}

async function handleDeleteRegistration(registrationId: number) {
  await deleteRegistration(registrationId).then(() => {
    isDeleteModalOpen.value = false
  })
}
</script>

<template>
  <MainLayout>
    <section class="w-full text-dark-grey dark:text-light-grey">
      <div class="mb-10 flex flex-wrap items-center">
        <h1 class="mr-8 text-[32px] font-bold lg:text-[42px] xl:text-[58px]">
          {{ t('global.registrations') }}
        </h1>
      </div>
      <VTable
        v-if="currentEvent.registrations && currentEvent.registrations.length > 0"
        :columns="columns"
        :data="currentEvent.registrations"
      >
        <template #id="{ value }">
          <span class="text-lg font-semibold">
            {{ value }}
          </span>
        </template>
        <template #fullName="{ row, col }">
          <span class="text-lg font-semibold">
            {{ row[col.keys[1]] }}, {{ row[col.keys[0]] }}
          </span>
        </template>
        <template #email="{ value }">
          <span class="text-lg font-semibold">
            {{ value }}
          </span>
        </template>
        <template #actions="{ value }">
          <div class="flex items-center justify-end gap-4">
            <VButton
              type="button"
              appearance="empty"
              size="medium"
              class="cursor-pointer"
              :functionOnClick="() => openInfoModal(Number(value))"
            >
              <FontAwesomeIcon :icon="['fal', 'eye']" class="mr-2 size-5 p-1.5" />
            </VButton>
            <VButton
              type="button"
              appearance="empty"
              size="medium"
              class="cursor-pointer"
              :functionOnClick="() => openDeleteModal(Number(value))"
            >
              <FontAwesomeIcon :icon="['fal', 'trash-can']" class="mr-2 size-5 p-1.5" />
            </VButton>
          </div>
        </template>
      </VTable>
      <div
        v-else
        class="h-[calc(100svh-18.5rem)] overflow-y-auto rounded-lg bg-white p-10 shadow dark:bg-dark-grey"
      >
        <div>
          <p class="mb-4">
            {{ t('views.events.analytics.currentlyNoRegistrations') }}
          </p>
        </div>
      </div>
    </section>
    <template #modal>
      <!-- Info Modal -->
      <VModal :trigger="isInfoModalOpen" @update:trigger="isInfoModalOpen = $event">
        <template #modalHeader>
          <p class="text-center text-xl uppercase text-dark-grey dark:text-light-grey">
            {{ t('global.registration') }} {{ currentRegistration.id }}
          </p>
        </template>
        <template #modalBody>
          <div class="mb-4">
            <p class="text-sm text-dark-grey dark:text-light-grey">
              {{ t('global.name') }}
            </p>
            <p class="text-normal font-semibold text-dark-grey dark:text-light-grey">
              {{ currentRegistration.salutation }} {{ currentRegistration.firstName }}
              {{ currentRegistration.lastName }}
            </p>
          </div>
          <div class="mb-4">
            <p class="text-sm text-dark-grey dark:text-light-grey">
              {{ t('global.email') }}
            </p>
            <p class="text-normal font-semibold text-dark-grey dark:text-light-grey">
              {{ currentRegistration.email }}
            </p>
          </div>
          <div v-for="(value, key) in currentRegistration.webformSubmission?.elements" class="mb-4">
            <p class="text-sm text-dark-grey dark:text-light-grey">
              {{ key }}
            </p>
            <p class="text-normal font-semibold text-dark-grey dark:text-light-grey">
              {{ value }}
            </p>
          </div>
        </template>
        <template #modalFooter>
          <div class="flex justify-end">
            <VButton
              type="button"
              appearance="default"
              :label="t('global.close')"
              size="medium"
              :functionOnClick="
                () => {
                  isInfoModalOpen = false
                }
              "
            />
          </div>
        </template>
      </VModal>
      <!-- Delete Modal -->
      <VModal :trigger="isDeleteModalOpen" @update:trigger="isDeleteModalOpen = $event">
        <template #modalHeader>
          <p class="text-center text-xl uppercase text-dark-grey dark:text-light-grey">
            {{ t('global.registration') }} {{ currentRegistration.id }}
          </p>
        </template>
        <template #modalBody>
          <p class="text-dark-grey dark:text-light-grey">
            {{ t('views.events.analytics.confirmDeleteRegistration') }}
          </p>
        </template>
        <template #modalFooter>
          <div class="flex justify-between">
            <VButton
              type="button"
              appearance="cancel"
              :label="t('global.cancel')"
              size="medium"
              :functionOnClick="
                () => {
                  isDeleteModalOpen = false
                }
              "
            />
            <VButton
              type="button"
              appearance="default"
              :label="t('global.delete')"
              size="medium"
              :functionOnClick="
                () => {
                  handleDeleteRegistration(Number(currentRegistration.id))
                }
              "
            />
          </div>
        </template>
      </VModal>
    </template>
  </MainLayout>
</template>
