import { defineStore } from 'pinia'
import { ref, watch, watchEffect } from 'vue'
import { type RouteRecordName, useRoute } from 'vue-router'

import { useWidth } from '@/utils/useWidth'

export const useNavStore = defineStore('nav', () => {
  const route = useRoute()
  const currentRoute = ref(route.fullPath)
  const currentRouteName = ref<RouteRecordName>(route.name || '')
  const width = useWidth()
  const isOpen = ref<boolean>(width.value >= 1024)
  const manuallyClosed = ref<boolean>(false)

  // Watch for route changes
  watch(route, () => {
    currentRoute.value = route.path
    currentRouteName.value = route.name || ''
  })
  
  // Toggle the nav
  const toggleNav = () => {
    isOpen.value = !isOpen.value
    if (isOpen.value) {
      manuallyClosed.value = false // Reset manuallyClosed flag when opening
    } else {
      manuallyClosed.value = true // Set manuallyClosed flag when closing
    }
  }

  return {
    currentRoute,
    currentRouteName,
    isOpen,
    toggleNav,
    width
  }
})
