// TODO: remove color classes and add them in Show and ShowLogin somehow
const textClassification = {
  label: 'block mb-2 font-bold text-md',
  inner: `
    border
    rounded-md
    overflow-hidden
  `,
  input:
    'w-full h-10 px-3 rounded-md border-none text-base disabled:shadow-none',
  option: 'text-lg'
}
const boxClassification = {
  fieldset: '',
  legend: 'font-bold text-md mb-2',
  wrapper: 'flex items-start mb-2 cursor-pointer',
  help: 'mb-2',
  input:
    'relative top-[1px] form-check-input h-5 w-5 mr-2 !border !border-solid rounded-sm bg-white focus:outline-none focus:ring-0 transition duration-200 cursor-pointer',
  label: 'text-md [&_a]:underline',
  inner: 'relative top-[2px]'
}
const submitClassification = {
  outer: 'my-4',
  wrapper: 'flex justify-center',
  input: 'button-text-color button-background-color button-hover-color rounded uppercase [text-shadow:0_1px_1px_rgba(0,0,0,.3)] px-2 py-2 md:text-sm lg:px-5 lg:py-3 lg:text-base duration-300'
}

export default {
  // the global key will apply to _all_ inputs
  global: {
    outer: 'formkit-disabled:opacity-50',
    help: 'text-xs',
    messages: 'rounded border-s-4 p-2',
    message: 'block font-medium',
    input:
    'border-0'
  },
  date: textClassification,
  'datetime-local': textClassification,
  checkbox: boxClassification,
  email: textClassification,
  month: textClassification,
  number: textClassification,
  password: textClassification,
  radio: boxClassification,
  select: textClassification,
  submit: submitClassification,
  tel: textClassification,
  text: textClassification,
  textarea: {
    ...textClassification,
    input:
      'block w-full h-32 p-4 border-none text-base focus:shadow-outline rounded-md'
  }
}
