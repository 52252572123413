<script setup lang="ts">
import { twMerge } from 'tailwind-merge'
import { inject, ref } from 'vue'

/**
 * RouterLink has it's internal active value but it can't be accessed.
 * So isActive is provided by NavLink and injected here (computed value)
 * See NavLink.vue for more info
 */
const isActive = inject('isActive', ref(false))
</script>

<template>
  <span
    :class="
      twMerge(
        'flex h-12 w-12 items-center justify-center rounded-lg bg-dark-grey text-light-grey shadow',
        isActive && 'bg-light-grey text-dark-grey'
      )
    "
  >
    <slot />
  </span>
</template>
