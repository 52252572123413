import type { WritableComputedRef } from 'vue'
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

import { SUPPORT_LOCALES, loadLocaleMessages, setI18nLanguage, setupI18n } from '@/utils/i18n'

const i18n = setupI18n()

/**
 * Route guard to handle locale changes. This will load the locale messages
 * if they are not already loaded and set the i18n language.
 */
export const handleLocale = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) => {
  let locale: string

  // Because of the I18n union type we need to check if the 'global' property exists
  if ('global' in i18n) {
    locale = (i18n.global.locale as WritableComputedRef<string>).value
  } else {
    locale = i18n.locale.value
  }

  let paramsLocale = to.params.locale

  // If paramsLocale is an array, use the first element
  if (Array.isArray(paramsLocale)) {
    paramsLocale = paramsLocale[0]
  }

  // If paramsLocale is undefined, use the default locale
  if (!paramsLocale) {
    paramsLocale = locale
  }

  // Check if the locale the user is trying to access is available.
  // if (!SUPPORT_LOCALES.includes(paramsLocale)) {
  //   return next(locale)
  // } else {
    setI18nLanguage(i18n, paramsLocale)
  // }

  // load locale messages if they are not already loaded
  if ('global' in i18n && !i18n.global.availableLocales.includes(paramsLocale)) {
    await loadLocaleMessages(i18n, paramsLocale)
  } else if ('availableLocales' in i18n && !i18n.availableLocales.includes(paramsLocale)) {
    await loadLocaleMessages(i18n, paramsLocale)
  }

  // Changing the language from the URl (either manually or with a link) is possible this way
  if ('global' in i18n) {
    ;(i18n.global.locale as WritableComputedRef<string>).value = paramsLocale
  } else {
    i18n.locale.value = paramsLocale
  }

  // return next()
}
