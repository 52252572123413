import type { components } from '@/types/swagger'

type Registration = components['schemas']['Registration']

export const emptyRegistration: Partial<Registration> = {
  id: undefined,
  email: '',
  salutation: '',
  firstName: '',
  lastName: '',
  locale: '',
  token: '',
  eventId: undefined,
  webformSubmission: undefined
}
