import type { components } from '@/types/swagger'

type Event = components['schemas']['Event']
type Webform = components['schemas']['Webform']
type Design = components['schemas']['Design']
type Asset = components['schemas']['Asset']

export const previewEvent: Partial<Event> = {
  id: undefined,
  name: { de: "Lorem Ipsum dolor si amet DE", en: "Lorem Ipsum dolor si amet EN" },
  description: { de: "Conqueror dapifer sollicito ventito statim aegrus adfectus.", en: "Conqueror dapifer sollicito ventito statim aegrus adfectus." },
  startDate: '2025-01-01T08:00:00.000Z',
  endDate: '2025-01-01T16:00:00.000Z',
  subtitle: { de: "At vero eos et accusam et justo DE", en: "At vero eos et accusam et justo EN" },
  url: { de: '', en: '' },
  languages: ['en', 'de'],
  protected: true,
  created_at: '',
  updated_at: '',
  speakers: [
    {
      id: 6,
      firstName: "Adrian",
      lastName: "Grant",
      company: "Kassulke, Beier and Wiza",
      vita: {
        de: "Ascisco auctor abstergo verecundia adnuo canto repellendus arceo culpo acquiro.",
        en: "Ascisco auctor abstergo verecundia adnuo canto repellendus arceo culpo acquiro."
      },
      position: "Principal Implementation Supervisor",
      created_at: "2024-08-26T10:43:21.712Z",
      updated_at: "2024-08-26T10:43:21.712Z",
      images: [],
      events: [],
      agendaItems: []
    },
    {
      id: 8,
      firstName: "Ellie",
      lastName: "Feeney",
      company: "Keeling - Hoppe",
      vita: {
        de: "In crepusculum acsi somniculosus deprecator venia reiciendis cultura adopto quidem cedo studio voluptates incidunt cuius vesper audax decimus virga minima.",
        en: "In crepusculum acsi somniculosus deprecator venia reiciendis cultura adopto quidem cedo studio voluptates incidunt cuius vesper audax decimus virga minima."
      },
      position: "Future Assurance Liaison",
      created_at: "2024-08-26T10:43:21.712Z",
      updated_at: "2024-08-26T10:43:21.712Z",
      images: [],
      events: [],
      agendaItems: []
    },
    {
      id: 15,
      firstName: "Margarett",
      lastName: "Barton",
      company: "Paucek, Gutmann and Nader",
      vita: {
        de: "Patria convoco certe thymbra cultura voro ocer arca conduco animus blandior quod cognomen magnam ambitus barba ascit succurro vir alienus iste quidem vobis.",
        en: "Patria convoco certe thymbra cultura voro ocer arca conduco animus blandior quod cognomen magnam ambitus barba ascit succurro vir alienus iste quidem vobis."
      },
      position: "Investor Infrastructure Assistant",
      created_at: "2024-08-26T10:43:21.729Z",
      updated_at: "2024-08-26T10:43:21.729Z",
      images: [],
      events: [],
      agendaItems: []
    },
    {
      id: 17,
      firstName: "Modesto",
      lastName: "Breitenberg",
      company: "Wiegand and Sons",
      vita: {
        de: "Ambitus cunctatio demoror vomica corrupti speciosus caput clarus.",
        en: "Ambitus cunctatio demoror vomica corrupti speciosus caput clarus."
      },
      position: "Chief Division Planner",
      created_at: "2024-08-26T10:43:21.730Z",
      updated_at: "2024-08-26T10:43:21.730Z",
      images: [],
      events: [],
      agendaItems: []
    }
  ],
  webform: {} as Webform,
  previewImages: [
    {
      id: 10,
      asset_id: "d7ca8e76eadac6277c8a325c13f07752",
      public_id: "techcast_staging_jaime/event_previews/5c12fb0d-6f0e-4baa-91e7-8f8d2774ada8/preview",
      signature: "eea5ac85096f9b564a5556f0037d7e1b354df01a",
      width: 1920,
      height: 1080,
      format: "jpg",
      resource_type: "image",
      secure_url: "https://res.cloudinary.com/detvrmulg/image/upload/v1724678722/techcast_staging_jaime/event_previews/5c12fb0d-6f0e-4baa-91e7-8f8d2774ada8/preview.jpg",
      folder: "techcast_staging_jaime/event_previews/5c12fb0d-6f0e-4baa-91e7-8f8d2774ada8",
      created_at: "2024-08-26T13:25:22.000Z",
      updated_at: "2024-08-26T13:25:23.433Z",
      entityType: "event",
      entityId: 13
    }
  ],
  agendaItems: [
    {
      id: 1,
      title: {
        de: "Item 1 DE",
        en: "Item 1 EN"
      },
      description: {
        de: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat DE.",
        en: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat EN."
      },
      type: "presentation",
      startDate: "2025-01-01T09:00:00.000Z",
      endDate: "2025-01-01T10:30:00.000Z",
      created_at: "2024-08-26T13:29:27.449Z",
      updated_at: "2024-08-26T13:29:27.449Z",
      eventId: 13,
      speakers: [
        {
          id: 6,
          firstName: "Adrian",
          lastName: "Grant",
          company: "Kassulke, Beier and Wiza",
          vita: {
            de: "Ascisco auctor abstergo verecundia adnuo canto repellendus arceo culpo acquiro.",
            en: "Ascisco auctor abstergo verecundia adnuo canto repellendus arceo culpo acquiro."
          },
          position: "Principal Implementation Supervisor",
          created_at: "2024-08-26T10:43:21.712Z",
          updated_at: "2024-08-26T10:43:21.712Z"
        }
      ]
    },
    {
      id: 3,
      title: {
        de: "Item 2 DE",
        en: "Item 2 EN"
      },
      description: {
        de: "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet DE.",
        en: "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet EN."
      },
      type: "presentation",
      startDate: "2025-01-01T11:00:00.000Z",
      endDate: "2025-01-01T13:00:00.000Z",
      created_at: "2024-08-26T13:28:26.298Z",
      updated_at: "2024-08-26T13:28:36.881Z",
      eventId: 13,
      speakers: [
        {
          id: 8,
          firstName: "Ellie",
          lastName: "Feeney",
          company: "Keeling - Hoppe",
          vita: {
            de: "In crepusculum acsi somniculosus deprecator venia reiciendis cultura adopto quidem cedo studio voluptates incidunt cuius vesper audax decimus virga minima.",
            en: "In crepusculum acsi somniculosus deprecator venia reiciendis cultura adopto quidem cedo studio voluptates incidunt cuius vesper audax decimus virga minima."
          },
          position: "Future Assurance Liaison",
          created_at: "2024-08-26T10:43:21.712Z",
          updated_at: "2024-08-26T10:43:21.712Z"
        },
        {
          id: 17,
          firstName: "Modesto",
          lastName: "Breitenberg",
          company: "Wiegand and Sons",
          vita: {
            de: "Ambitus cunctatio demoror vomica corrupti speciosus caput clarus.",
            en: "Ambitus cunctatio demoror vomica corrupti speciosus caput clarus."
          },
          position: "Chief Division Planner",
          created_at: "2024-08-26T10:43:21.730Z",
          updated_at: "2024-08-26T10:43:21.730Z"
        }
      ]
    },
    {
      id: 4,
      title: {
        de: "Item 3 DE",
        en: "Item 3 EN"
      },
      description: {
        de: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat DE.",
        en: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat EN."
      },
      type: "presentation",
      startDate: "2025-01-01T13:00:00.000Z",
      endDate: "2025-01-01T15:30:00.000Z",
      created_at: "2024-08-26T13:29:27.449Z",
      updated_at: "2024-08-26T13:29:27.449Z",
      eventId: 13,
      speakers: [
        {
          id: 17,
          firstName: "Modesto",
          lastName: "Breitenberg",
          company: "Wiegand and Sons",
          vita: {
            de: "Ambitus cunctatio demoror vomica corrupti speciosus caput clarus.",
            en: "Ambitus cunctatio demoror vomica corrupti speciosus caput clarus."
          },
          position: "Chief Division Planner",
          created_at: "2024-08-26T10:43:21.730Z",
          updated_at: "2024-08-26T10:43:21.730Z"
        },
        {
          id: 6,
          firstName: "Adrian",
          lastName: "Grant",
          company: "Kassulke, Beier and Wiza",
          vita: {
            de: "Ascisco auctor abstergo verecundia adnuo canto repellendus arceo culpo acquiro.",
            en: "Ascisco auctor abstergo verecundia adnuo canto repellendus arceo culpo acquiro."
          },
          position: "Principal Implementation Supervisor",
          created_at: "2024-08-26T10:43:21.712Z",
          updated_at: "2024-08-26T10:43:21.712Z"
        }
      ]
    },
  ],
  downloads: [],
  registrations: [],
  links: [],
  webformSubmissions: [],
  isMultilanguage: true,
  design: {
    logos: [] as Asset[]
  } as Design
}
