<script setup lang="ts">
import { VButton, VInput } from '@techcast/histoire'

import { nextTick, onMounted, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import LoginLayout from '@/layouts/LoginLayout.vue'
import { useAuthStore } from '@/stores/auth.store'
import { usePasswordVisibility } from '@/utils/passwordVisibility'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const router = useRouter()
const authStore = useAuthStore()
const emailInputComponent = ref<any>(null)

onMounted(async () => {
  await nextTick(() => {
    // Access the exposed inputRef from the VInput component and focus it when the site is loaded
    ;(emailInputComponent.value as { inputRef: HTMLInputElement | null })?.inputRef?.focus()
  })
})

const loginForm = reactive({
  email: '',
  password: ''
})

// Block UI elements when submitting the form
const isLoading = ref(false)

const { t, locale } = useI18n()

// Use the composable for password visibility
const { showPassword, togglePasswordVisibility } = usePasswordVisibility()

const onSubmit = async () => {
  isLoading.value = true

  await authStore.login(loginForm.email, loginForm.password)

  await nextTick()

  if (authStore.user) {
    await router.push({ name: 'home', params: { locale: locale.value } })
  } else {
    console.log('Unauthorized. Redirecting to login...')
  }

  isLoading.value = false
}
</script>

<template>
  <LoginLayout>
    <form class="text-tc-light-grey flex w-[300px] flex-col items-end gap-1">
      <VInput
        v-model="loginForm.email"
        type="email"
        input-id="loginEmail"
        label="Email"
        placeholder="login@email.com"
        :disabled="isLoading"
        ref="emailInputComponent"
        :error-message="t('global.mustBeEmail')"
        class="h-24"
      />
      <div class="relative w-full">
        <VInput
          v-model="loginForm.password"
          :type="showPassword ? 'text' : 'password'"
          input-id="loginPassword"
          label="Password"
          placeholder="login@email.com"
          :disabled="isLoading"
        />
        <VButton
          type="button"
          appearance="empty"
          size="medium"
          :functionOnClick="togglePasswordVisibility"
          class="absolute right-1 top-1"
        >
          <FontAwesomeIcon
            v-if="showPassword"
            :icon="['fal', 'eye']"
            class="absolute right-1 top-1 [&_path]:fill-dark-grey [&_path]:dark:fill-light-grey"
          />
          <FontAwesomeIcon v-else :icon="['fal', 'eye-slash']" class="absolute right-1 top-1 [&_path]:fill-dark-grey [&_path]:dark:fill-light-grey" />
        </VButton>
      </div>
      <VButton
        type="submit"
        appearance="default"
        label="Login"
        :disabled="isLoading"
        size="small"
        :function-on-click="onSubmit"
      />
    </form>
  </LoginLayout>
</template>
