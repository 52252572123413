import { defineStore } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toastification'

import { tcFetch } from '@/utils/tcFetch'

export const useAssetsStore = defineStore('assets', () => {
  /**
   * ----- Internal Variables -----
   */

  const url = import.meta.env.VITE_BACKEND_URL
  const { t } = useI18n()
  const toast = useToast()

  /**
   * ----- CRUD Actions -----
   */

  /**
   * Delete an asset from the backend.
   * If the request is successful, it will be also removed from the event where it was used.
   *
   * @param id - The ID of the asset to delete.
   */
  const deleteAsset = async (id: number): Promise<Response> => {
    const response = await tcFetch('DELETE', `${url}/assets/${id}`)

    if (!response.ok) {
      const errorText = await response.json()
      // Check if errorText is an array
      if (Array.isArray(errorText.message) && errorText.message.length > 0) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(`${t('views.assets.failedDeleteAsset')}: ${errorMessage.message}`)
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(`${t('views.assets.failedDeleteAsset')}: ${errorText.message}`)
      }
    }

    return response
  }

  return {
    deleteAsset
  }
})
