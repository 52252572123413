import type { components } from '@/types/swagger'

type Link = components['schemas']['Link']

export const emptyLink: Partial<Link> = {
  id: undefined,
  name: { de: '', en: '' },
  url: { de: '', en: '' },
  eventId: undefined
}
