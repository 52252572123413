import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toastification'

import { emptyAgendaItem } from '@/stores/objects/emptyAgendaItem'
import type { components } from '@/types/swagger'
import { tcFetch } from '@/utils/tcFetch'

type Speaker = components['schemas']['Speaker']
type Event = components['schemas']['Event']
type AgendaItem = components['schemas']['AgendaItem']
type CreateAgendaItemDto = components['schemas']['CreateAgendaItemDto']
type UpdateAgendaItemDto = components['schemas']['UpdateAgendaItemDto']

export const useAgendaItemsStore = defineStore('agenda-items', () => {
  /**
   * ----- Internal Variables -----
   */

  const url = import.meta.env.VITE_BACKEND_URL
  const { t } = useI18n()
  const toast = useToast()

  /**
   * ----- Reactive Variables -----
   */

  const currentAgendaItem = ref<AgendaItem>(structuredClone(emptyAgendaItem) as AgendaItem)
  const currentAgendaItemLanguage = ref<'de' | 'en'>('de')

  /**
   * ----- CRUD Actions -----
   */

  /**
   * Add a agenda items to the current event.
   *
   * @param eventId - The ID of the event.
   * @param createAgendaItemDto - The agenda item to add.
   * @param speakersList - (optional) The list of speakers to add to the agenda item.
   *
   * @returns A promise with the saved agenda item.
   */
  const createAgendaItem = async (
    createAgendaItemDto: CreateAgendaItemDto,
    eventId: number,
    speakersList?: Speaker[]
  ): Promise<AgendaItem | undefined> => {
    const response = await tcFetch(
      'POST',
      `${url}/events/${eventId}/agenda-items`,
      createAgendaItemDto
    )

    //noinspection Duplicates
    if (!response.ok) {
      // TODO: Bind validation messages to forms
      const errorText = await response.json()
      if (Array.isArray(errorText.message)) {
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(`${t('views.events.agenda.failedCreateAgendaItem')}: ${errorMessage.message}`)
        })
      } else {
        toast.error(`${t('views.events.agenda.failedCreateAgendaItem')}: ${errorText.message}`)
      }
      return
    }

    let data = await response.json()

    // add speakers to the agenda item if any
    if (speakersList) {
      await addSpeakersToAgendaItem(data as AgendaItem, speakersList)
    }

    currentAgendaItem.value = structuredClone(data) as AgendaItem

    return data
  }

  /**
   * Fetch an agenda item by id.
   *
   * @param agendaItemId - The ID of the agenda item to fetch.
   *
   * @returns A promise with the fetched agenda item.
   */
  const fetchAgendaItemById = async (agendaItemId: number): Promise<AgendaItem> => {
    const response = await tcFetch('GET', `${url}/agenda-items/${agendaItemId}`)

    if (!response.ok) {
      throw new Error(
        `Failed to fetch agenda item. Status: ${response.status} ${response.statusText}`
      )
    }

    const data = await response.json()
    // Update the currentAgendaItem with the fetched data
    currentAgendaItem.value = structuredClone(data) as AgendaItem

    return data
  }

  /**
   * Update one agenda item by id.
   *
   * @param updateAgendaItemDto - The agenda item to update.
   * @param speakersList - (optional) The list of speakers to add to the agenda item.
   *
   * @returns The updated agenda item.
   */
  const updateAgendaItem = async (
    updateAgendaItemDto: UpdateAgendaItemDto,
    speakersList?: Speaker[]
  ): Promise<AgendaItem | undefined> => {
    const response = await tcFetch(
      'PATCH',
      `${url}/agenda-items/${currentAgendaItem.value.id}`,
      updateAgendaItemDto
    )

    //noinspection Duplicates
    if (!response.ok) {
      const errorText = await response.json()
      if (Array.isArray(errorText.message)) {
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(`${t('views.events.agenda.failedUpdateAgendaItem')}: ${errorMessage.message}`)
        })
      } else {
        toast.error(`${t('views.events.agenda.failedUpdateAgendaItem')}: ${errorText.message}`)
      }
      return
    }

    let data = await response.json()

    // edit speakers of the agenda item
    if (speakersList) {
      data = await addSpeakersToAgendaItem(data as AgendaItem, speakersList)
    }

    currentAgendaItem.value = structuredClone(data) as AgendaItem

    return data
  }

  /**
   * Delete an agenda item.
   *
   * @param agendaItemId - The ID of the agenda item to delete.
   *
   * @returns The response from the backend (200, 401, 500).
   */
  const deleteAgendaItem = async (agendaItemId: number): Promise<Response> => {
    const response = await tcFetch('DELETE', `${url}/agenda-items/${agendaItemId}`)

    if (!response.ok) {
      const errorText = await response.json()
      if (Array.isArray(errorText.message)) {
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(`${t('views.events.agenda.failedDeleteAgendaItem')}: ${errorMessage.message}`)
        })
      } else {
        toast.error(`${t('views.events.agenda.failedDeleteAgendaItem')}: ${errorText.message}`)
      }
    }

    // Reset the currentAgendaItem
    resetCurrentAgendaItem()

    return response
  }

  /**
   * ----- NON CRUD Actions -----
   */

  /**
   * Add speakers into an agendaItem.
   * If the speaker is already in the list, it will not be added again.
   * If the speaker is not in the list, it will be added.
   * If the speaker is in the list, but not in the speakersList list, it will be removed.
   *
   * @param agendaItem - The agenda item to add the speakers to.
   * @param speakersList - The ID of the speaker to add.
   *
   * @returns The updated agenda item.
   */
  const addSpeakersToAgendaItem = async (
    agendaItem: AgendaItem,
    speakersList: Speaker[]
  ): Promise<AgendaItem | undefined> => {
    // Get current speaker IDs
    const currentSpeakers = agendaItem.speakers.map((speaker: { id: number }) => speaker.id)

    // Get unique speaker IDs from the new speakers list
    const speakersListIds = Array.from(
      new Set(speakersList.map((speaker: { id: number }) => speaker.id))
    )

    // Determine speakers to add and remove
    const speakersToAdd = speakersListIds.filter(
      (speakerId: number) => !currentSpeakers.includes(speakerId)
    )

    const speakersToRemove = currentSpeakers.filter(
      (speakerId: number) => !speakersListIds.includes(speakerId)
    )

    // Prepare promises for adding new speakers
    const addSpeakerPromises = speakersToAdd.map(async (speakerId: number) => {
      try {
        const response = await tcFetch('POST', `${url}/agenda-items/${agendaItem.id}/speakers`, {
          speakerId: +speakerId
        })

        if (!response.ok) {
          const errorText = await response.json()
          if (Array.isArray(errorText.message)) {
            errorText.message.forEach((errorMessage: { message: string }) => {
              toast.error(
                `${t('views.events.agenda.failedAddSpeakersToAgendaItem')}: ${errorMessage.message}`
              )
            })
          } else {
            toast.error(
              `${t('views.events.agenda.failedAddSpeakersToAgendaItem')}: ${errorText.message}`
            )
          }
          throw new Error('Failed to add speaker')
        }

        const data = await response.json()
        return structuredClone(data) as AgendaItem
      } catch (error) {
        console.error('Failed to add speaker:', error)
        throw error
      }
    })

    // Prepare promises for removing old speakers
    const removeSpeakerPromises = speakersToRemove.map(async (speakerId: number) => {
      try {
        const data = await removeSpeakerFromAgendaItem(agendaItem.id, +speakerId)
        return structuredClone(data) as AgendaItem
      } catch (error) {
        console.error('Failed to remove speaker:', error)
        throw error
      }
    })

    try {
      // Run all add and remove speaker promises in parallel
      const [updatedAgendaItemsOnAdd, updatedAgendaItemsOnRemove] = await Promise.all([
        Promise.all(addSpeakerPromises),
        Promise.all(removeSpeakerPromises)
      ])

      // Combine results
      const updatedAgendaItem = updatedAgendaItemsOnAdd.length > 0
        ? updatedAgendaItemsOnAdd[updatedAgendaItemsOnAdd.length - 1]
        : agendaItem

      return updatedAgendaItem

    } catch (error) {
      console.error('Failed to update agenda item:', error)
      return
    }
  }

  /**
   * Delete a speaker from an agenda item.
   *
   * @param agendaItemId - The ID of the agendaItem where the speaker will be deleted.
   * @param speakerId - The ID of the speaker to delete.
   *
   * @returns The updated agenda item.
   */
  const removeSpeakerFromAgendaItem = async (
    agendaItemId: number,
    speakerId: number
  ): Promise<AgendaItem | undefined> => {
    const response = await tcFetch(
      'DELETE',
      `${url}/agenda-items/${agendaItemId}/speakers/${speakerId}`
    )

    if (!response.ok) {
      const errorText = await response.json()
      if (Array.isArray(errorText.message)) {
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(
            `${t('views.events.agenda.failedRemoveSpeakerFromAgendaItem')}: ${errorMessage.message}`
          )
        })
      } else {
        toast.error(
          `${t('views.events.agenda.failedRemoveSpeakerFromAgendaItem')}: ${errorText.message}`
        )
      }
      return
    }

    return await response.json()
  }

  /**
   * Remove a speaker from an agenda item.
   *
   * @param event
   * @param speakerId
   */
  const removeSpeakerFromAllAgendaItems = async (event: Event, speakerId: number) => {
    // Create an array of promises for removing the speaker from each agenda item
    const removalPromises = event.agendaItems.map(async (agendaItem: AgendaItem) => {
      try {
        const data = await removeSpeakerFromAgendaItem(agendaItem.id, speakerId)
        return structuredClone(data) as AgendaItem
      } catch (error) {
        console.error(`Failed to remove speaker from agenda item ${agendaItem.id}:`, error)
        // You can handle individual errors here if needed
      }
    })

    try {
      // Wait for all removal promises to complete
      const results = await Promise.all(removalPromises)

      // Optionally process the results if needed
      // e.g., update the event or perform some other action
      return results
    } catch (error) {
      console.error('Failed to remove speaker from all agenda items:', error)
    }
  }


  /**
   * ----- Helper Functions -----
   */

  /**
   * Reset the currentAgendaItem object to its initial state (emptyAgendaItem).
   */
  const resetCurrentAgendaItem = () => {
    // Object.assign(currentAgendaItem, structuredClone(emptyAgendaItem))
    currentAgendaItem.value = structuredClone(emptyAgendaItem) as AgendaItem
  }

  /**
   * Sort agenda items by startDate
   *
   * @param agendaItems - The list of agenda items to sort.
   */
  const sortAgendaItems = (agendaItems: AgendaItem[]): AgendaItem[] => {
    return agendaItems.sort((a, b) => {
      return new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
    })
  }

  return {
    currentAgendaItem,
    currentAgendaItemLanguage,
    createAgendaItem,
    fetchAgendaItemById,
    updateAgendaItem,
    deleteAgendaItem,
    addSpeakersToAgendaItem,
    removeSpeakerFromAgendaItem,
    removeSpeakerFromAllAgendaItems,
    resetCurrentAgendaItem,
    sortAgendaItems
  }
})
