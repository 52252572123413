import { storeToRefs } from 'pinia'
import { type ComputedRef, type Ref, computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { SUPPORT_LOCALES } from '@/utils/i18n'

import { useEventsStore } from '@/stores/events.store'
import type { components } from '@/types/swagger'
import { loadLocaleMessages, setI18nLanguage } from '@/utils/i18n'

type Event = components['schemas']['Event']

interface UseEventSetupReturn {
  i18n: ReturnType<typeof useI18n>
  t: typeof useI18n.prototype.t
  locale: Ref<string>
  switchLanguage: () => void
  eventStore: ReturnType<typeof useEventsStore>
  eventId: Ref<string | number>
  currentEventLanguage: Ref<string | null>
  currentEvent: Ref<Event | Partial<Event>>
  layoutComponent: ComputedRef<string>
  supportedLocales: string[]
}

export function useEventSetup(): UseEventSetupReturn {
  const i18n = useI18n()
  const { t, locale } = useI18n()
  const supportedLocales = SUPPORT_LOCALES
  const route = useRoute()
  const router = useRouter()

  const switchLanguage = () => {
    const currentLanguage = i18n.locale.value
    const newLanguage = currentLanguage === 'en' ? 'de' : 'en'

    const currentParams = route.params.id ? { id: route.params.id } : {}
    router.push({ params: { ...currentParams, locale: newLanguage } })

    setI18nLanguage(i18n, newLanguage)
    loadLocaleMessages(i18n, newLanguage)
  }

  const eventStore = useEventsStore()
  const { currentEvent, currentEventLanguage } = storeToRefs(eventStore)
  const eventId = ref(route.params.id as string)

  const layoutComponent = computed(() => 'default')

  return {
    i18n,
    t,
    locale,
    supportedLocales,
    currentEventLanguage,
    switchLanguage,
    eventStore,
    eventId,
    currentEvent,
    layoutComponent
  }
}
