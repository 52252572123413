<script setup lang="ts">
import {
  VButton,
  VColorpicker,
  VModal,
  VSelect,
  VTabNavigator,
  VToggleTwoOptions,
  VTooltip
} from '@techcast/histoire'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { storeToRefs } from 'pinia'
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { onBeforeRouteLeave, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification'

import CloudImage from '@/components/utils/CloudImage.vue'
import I18nRouterLink from '@/components/utils/I18nRouterLink.vue'
import ImageCropper from '@/components/utils/ImageCropper.vue'
import QuillEditor from '@/components/utils/QuillEditor.vue'
import { findDifferences } from '@/composables/unsaved-changes/useFindDifferences'
import { useUnsavedChanges } from '@/composables/unsaved-changes/useUnsavedChanges'
import { cloneable } from '@/composables/useClone'
import { useImageCrop } from '@/composables/useImageCropped'
import MainLayout from '@/layouts/MainLayout.vue'
import { useAssetsStore } from '@/stores/assets.store'
import { useDesignTemplatesStore } from '@/stores/designTemplates.store'
import { useDesignsStore } from '@/stores/designs.store'
import { useEventsStore } from '@/stores/events.store'
import type { DesignColorVariables } from '@/types/DesignColorVariables'
import type { components } from '@/types/swagger'
import { getImageName } from '@/utils/getImageName'
import { updateColors } from '@/utils/updateClientFrontendColors'

import Show from '../events/client/Show.vue'
import ShowLogin from '../events/client/ShowLogin.vue'

type UpdateEventDto = components['schemas']['UpdateEventDto']
type Design = components['schemas']['Design']
type CreateDesignDto = components['schemas']['CreateDesignDto']
type UpdateDesignDto = components['schemas']['UpdateDesignDto']
type DesignTemplate = components['schemas']['DesignTemplate']
type UpdateDesignPreviewDto = components['schemas']['UpdateDesignPreviewDto']

const toast = useToast()

const { t, locale } = useI18n()

/****************************************
* STORES
*****************************************/
const eventStore = useEventsStore()
const { currentEvent, currentEventLanguage } = storeToRefs(eventStore)
const { fetchEventById, resetCurrentEvent, updateEvent } = eventStore

const designTemplateStore = useDesignTemplatesStore()
const { designTemplates } = storeToRefs(designTemplateStore)
const { fetchAllDesignTemplates, fetchDesignTemplateById, resetCurrentDesignTemplate } =
  designTemplateStore

const designStore = useDesignsStore()
const { currentDesign, previewDesign } = storeToRefs(designStore)
const {
  fetchDesignById,
  createDesign,
  updateDesign,
  deleteDesign,
  updatePreviewDesign,
  resetCurrentDesign,
  resetPreviewDesign
} = designStore

const assetStore = useAssetsStore()
const { deleteAsset } = assetStore

const route = useRoute()

/**
 * Use route params to get current event id
 */
const eventId = route.params.id

/**
 * Computed values
 */
const designId = computed(() => currentDesign.value?.id as number)
const tabs = computed(() => [
  {
    title: t('global.access'),
    name: 'access'
  },
  {
    title: t('global.noaccess'),
    name: 'noaccess'
  }
])

/**
 * Refs
 */
// Clone the initial form data to compare against later
let originalDesignState = ref<Design | null>(null)

/****************************************
* COMPOSABLES
*****************************************/
// Unsaved changes composable
const {
  hasUnsavedChanges,
  isUnsavedChangesModalOpen,
  confirmNavigation,
  triggerUnsavedChangesModal,
  forgetUnsavedChanges,
  nextRoute
} = useUnsavedChanges()

// Image crop composable
const { imageUploadMessage, showImageUploadMessage, croppedImageFile, imageCropped } =
  useImageCrop()

/****************************************
* REFS
*****************************************/
const showForm = ref(false) // Show the design form when a template is selected
const imageCropperRef = ref<any>(null)
const showRemoveTemporaryImageButton = ref(false)
const imageTemporaryDeleted = ref(false)
const designHadAlreadyAnImage = ref(false)
const imageHasUnsavedChanges = ref(false)
const openModalDeleteDesign = ref(false) // Modal to confirm the deletion of the design
const selectedTemplate = ref<DesignTemplate | null>(null)
// refs for the preview design views
const currentTab = ref('access')
const previewMobile = ref<boolean>(true)
const previewDesktop = ref<boolean>(false)
const selectDeviceForPreview = ref<string>('mobile')
// Both sides (divs) of the form
const leftSideHeight = ref<number | null>(null)
const showRightSide = ref(false)
const formLeftSideRef = ref<HTMLElement | null>(null)
const formRightSideRef = ref<HTMLElement | null>(null)

/****************************************
* LIFECYCLE HOOKS
*****************************************/
onMounted(async () => {
  // initialize the current event after the component is mounted
  if (eventId) {
    const response = await fetchEventById(+eventId)

    if (response.design) {
      await fetchDesignById(+response.design.id)

      await updatePreviewDesign(
        currentDesign.value.designPreview.id,
        currentDesign.value as UpdateDesignPreviewDto
      )
    } else {
      // show a select input with all available design templates
      await fetchAllDesignTemplates()
    }

    // set the current webform template language to the first language of the supported locales always on onMounted
    currentEventLanguage.value = 'de'

    await nextTick() // Ensure the DOM is updated

    adjustSideHeights()

    // Save a deep copy of the original design template state
    originalDesignState.value = cloneable.deepCopy(currentDesign.value)
    // Check if the design has a already an image
    designHadAlreadyAnImage.value = !!currentDesign.value.logos?.length
  }
})

onUnmounted(() => {
  console.log('onunmounted')

  resetCurrentEvent()
  resetCurrentDesign()
  resetPreviewDesign()
  resetCurrentDesignTemplate()
  nextRoute.value = ''
})

// Check for unsaved changes before navigating away
onBeforeRouteLeave((to, from, next) => {
  console.log('onBeforeRouteLeave')
  console.log('forgetUnsavedChanges.value', forgetUnsavedChanges.value)

  if (!forgetUnsavedChanges.value && hasUnsavedChanges.value) {
    triggerUnsavedChangesModal(to.path)
    next(false) // Prevent navigation
  } else {
    next() // Allow navigation
  }

  forgetUnsavedChanges.value = false
})

/****************************************
* METHODS
*****************************************/
async function handleCreateDesign() {
  if ((croppedImageFile.value && imageCropperRef.value) || imageTemporaryDeleted.value) {
    await imageCropperRef.value.cropImage()
  }

  await createDesign(+eventId, selectedTemplate.value as CreateDesignDto, croppedImageFile.value!)

  if (croppedImageFile.value) {
    // Reset the image upload message and cropped image file
    croppedImageFile.value = null
    showImageUploadMessage.value = false
  }

  originalDesignState.value = cloneable.deepCopy(currentDesign.value)
  currentEvent.value.design = currentDesign.value
  await updateEvent(currentEvent.value as UpdateEventDto, croppedImageFile.value!)

  toast.success(t('views.templates.design.designTemplateCreated'))
}

// This function will update the current design
async function handleUpdateDesign() {
  if (imageTemporaryDeleted.value && currentDesign.value.logos[0]) {
    await deleteAsset(currentDesign.value.logos![0].id)
    // Reset the image upload message and cropped image file
    currentDesign.value.logos = []
  }

  if (currentDesign.value.logos?.length === 0 && imageCropperRef.value) {
    await imageCropperRef.value.cropImage()
  }

  await updateDesign(
    +designId.value,
    currentDesign.value as UpdateDesignDto,
    croppedImageFile.value!
  ).then(() => {
    if (imageTemporaryDeleted.value) {
      // Reset the image upload message and cropped image file
      imageTemporaryDeleted.value = false
      showImageUploadMessage.value = false
    }
    // update the event with the new design
    currentEvent.value.design = currentDesign.value
  })

  if (croppedImageFile.value) {
    // Reset the image upload message and cropped image file
    croppedImageFile.value = null
    showImageUploadMessage.value = false
  }

  originalDesignState.value = cloneable.deepCopy(currentDesign.value)

  // reset the unsaved changes
  hasUnsavedChanges.value = false
  imageHasUnsavedChanges.value = false
  designHadAlreadyAnImage.value = !!currentDesign.value.logos?.length

  toast.success(t('views.templates.design.designTemplateUpdated'))
}

// This function will delete the current design
async function handleDeleteDesign() {
  await deleteDesign(+designId.value)
    .then(async (response) => {
      if (response.ok) {
        openModalDeleteDesign.value = false
        showForm.value = false
        hasUnsavedChanges.value = false
        forgetUnsavedChanges.value = true
        resetCurrentDesign()
        resetPreviewDesign()
      }
    })
    .finally(() => {
      toast.success(t('views.templates.design.designTemplateDeleted'))
    })
}

// This function will select a template from the list of available design templates
async function onSelectTemplate(e: any) {
  selectedTemplate.value = await fetchDesignTemplateById(+e.value)

  if (selectedTemplate.value) {
    if (selectedTemplate.value.logos?.length > 0) {
      croppedImageFile.value = selectedTemplate.value.logos[0]
    }

    handleCreateDesign()
    showForm.value = true
  }
}

function handleImagePositionChange(newValue: string) {
  currentDesign.value.positions.logo = newValue as string
}

// This function will change the image upload message to 'uploaded' after the image is uploaded
function imagePreviewUploaded() {
  imageUploadMessage.value = 'uploaded'
  showRemoveTemporaryImageButton.value = true

  imageHasUnsavedChanges.value =
    !designHadAlreadyAnImage.value || (designHadAlreadyAnImage.value && imageTemporaryDeleted.value)

  // Compare old and new event state
  const differences = findDifferences(originalDesignState.value, currentDesign.value)
  // Update hasUnsavedChanges by checking if there are differences in the event or if the image has unsaved changes
  hasUnsavedChanges.value = differences.length > 0 || imageHasUnsavedChanges.value
}

// This function will handle the deletion of the temporary image before uploading a new one or deleting the current image definitively
async function handleDeleteImageTemporary() {
  imageTemporaryDeleted.value = true
  showRemoveTemporaryImageButton.value = false

  imageCropperRef.value?.reset()

  imageHasUnsavedChanges.value = designHadAlreadyAnImage.value

  // Compare old and new design template state
  const differences = findDifferences(originalDesignState.value, currentDesign.value)
  // Update hasUnsavedChanges by checking if there are differences in the design template or if the image has unsaved changes
  hasUnsavedChanges.value = differences.length > 0 || imageHasUnsavedChanges.value
}

// change the value of currentEventLanguage to the current selected language in the toggle component
function handleEventLanguageChange(newValue: string) {
  currentEventLanguage.value = newValue as string
}

/**
 * Add/remove a second footer to the design
 */
function addSecondFooter() {
  currentDesign.value.footer.push({
    position: 'right',
    text: { de: '<p></p>', en: '<p></p>' }
  })
  adjustSideHeights()
}

function removeSecondFooter() {
  currentDesign.value.footer.splice(1, 1)
  adjustSideHeights()
}

function adjustSideHeights() {
  // Use nextTick to ensure DOM updates
  nextTick(() => {
    if (formLeftSideRef.value) {
      leftSideHeight.value = formLeftSideRef.value.offsetHeight
      showRightSide.value = true
    }
  })
}

function handleSelectDeviceForPreview(newValue: string) {
  selectDeviceForPreview.value = newValue
  previewMobile.value = newValue === 'mobile'
  previewDesktop.value = newValue === 'desktop'
}

/****************************************
* WATCHERS
*****************************************/
// watch for changes in the current design and update the preview design and the colors in DOM
watch(
  currentDesign,
  async (newCurrentDesign) => {
    if (newCurrentDesign) {
      // Ensure that the design preview ID is valid before calling updatePreviewDesign
      if (previewDesign.value.id) {
        await updatePreviewDesign(
          +previewDesign.value.id,
          newCurrentDesign as UpdateDesignPreviewDto
        )
      }

      // Update colors after updating the preview design
      updateColors(previewDesign.value.colors as any as DesignColorVariables)

      // Adjust side heights whenever the design changes
      adjustSideHeights()

      let differences
      if (originalDesignState.value !== null) {
        differences = findDifferences(originalDesignState.value, currentDesign.value)
        hasUnsavedChanges.value = differences.length > 0 || imageHasUnsavedChanges.value
      }
    }
  },
  { deep: true }
)
</script>

<template>
  <MainLayout>
    <section class="w-full text-dark-grey dark:text-light-grey">
      <div class="mb-10 flex flex-wrap items-center">
        <h1 class="mr-8 text-[32px] font-bold lg:text-[42px] xl:text-[58px]">
          {{ t('global.designTemplate') }}
        </h1>
      </div>
      <div v-if="!designId && !showForm">
        <div v-if="designTemplates.length > 0" class="mb-6">
          <VSelect
            :label="`${t('views.events.design.selectTemplate')}:`"
            :placeholder="t('views.events.design.selectTemplate')"
            @selectOption="onSelectTemplate"
            :options="designTemplates"
            labelKey="name"
            valueKey="id"
          />
        </div>
        <I18nRouterLink v-else to="/templates/design">
          <VButton
            type="button"
            appearance="default"
            :label="t('views.events.design.goToDesignManagement')"
            size="medium"
          >
            <FontAwesomeIcon :icon="['fal', 'brush']" />
          </VButton>
        </I18nRouterLink>
      </div>
      <form v-if="designId || showForm" @submit.prevent class="prose-ul:list-none" novalidate>
        <div
          class="grid grid-cols-1 rounded-lg bg-white p-10 shadow xl:grid-cols-2 xl:flex-row xl:gap-16
            dark:bg-dark-grey"
        >
          <div ref="formLeftSideRef" class="w-full">
            <div class="flex w-full flex-col gap-4">
              <p class="text-base font-bold text-dark-grey dark:text-light-grey">
                {{ t('global.colors') }}
              </p>
              <div :class="'grid w-full gap-4 md:grid-cols-2'">
                <VColorpicker
                  v-for="(color, key) in currentDesign.colors"
                  :inputId="String(key)"
                  v-model="currentDesign.colors[key]"
                  :label="t(`views.templates.design.${key}`)"
                  :key="key"
                  :className="'[&_div]:text-left [&_span]:hyphens-auto'"
                />
              </div>
            </div>
            <hr class="my-10" />
            <div class="mb-10 flex flex-col gap-4 lg:gap-8">
              <div class="flex flex-col gap-2">
                <div class="flex items-end justify-between">
                  <label
                    class="flex flex-row gap-2 text-base font-bold text-dark-grey dark:text-light-grey"
                    ><span>{{ t('views.templates.design.logo') }}</span>
                    <span class="group relative">
                      <VTooltip
                        :modelValue="t('global.imageDeletedAfterSave')"
                        :position="'center'"
                        :className="'-top-5 text-sm'"
                      />
                      <FontAwesomeIcon :icon="['fal', 'circle-info']" /> </span
                  ></label>
                  <VButton
                    v-if="imageUploadMessage === 'uploaded' && showRemoveTemporaryImageButton"
                    type="button"
                    appearance="empty"
                    :function-on-click="handleDeleteImageTemporary"
                  >
                    <FontAwesomeIcon :icon="['fal', 'xmark']" class="size-5" />
                  </VButton>
                </div>
                <div
                  v-if="currentDesign.logos?.[0]?.public_id && !imageTemporaryDeleted"
                  class="mt-2 flex flex-col gap-4"
                >
                  <div class="flex flex-row items-start gap-4">
                    <CloudImage
                      :imageName="currentDesign.logos[0].public_id"
                      class="max-h-80 max-w-[calc(100%-3rem)] rounded object-cover"
                    />
                    <VButton
                      type="button"
                      appearance="empty"
                      size="small"
                      :function-on-click="handleDeleteImageTemporary"
                    >
                      <FontAwesomeIcon :icon="['fal', 'trash-can']" class="size-5 p-1.5" />
                    </VButton>
                  </div>
                  <p class="text-xs">{{ getImageName(currentDesign.logos[0].public_id) }}</p>
                </div>
                <div v-else class="flex flex-col">
                  <p
                    v-if="showImageUploadMessage"
                    class="mt-2 flex flex-row items-center justify-start gap-2 text-sm font-bold"
                    :class="{
                      'text-dark-yellow dark:text-light-yellow': imageUploadMessage === 'uploading',
                      'text-dark-green dark:text-light-green': imageUploadMessage !== 'uploading'
                    }"
                  >
                    <FontAwesomeIcon
                      :icon="['fal', 'spinner-third']"
                      class="mr-2 size-5 h-4 w-4 animate-spin p-1.5 text-dark-yellow dark:text-light-yellow"
                    />
                    {{
                      imageUploadMessage === 'uploading'
                        ? t('views.assets.assetBeingUploaded')
                        : t('views.assets.assetSuccessfullyUploaded')
                    }}
                  </p>
                  <div v-else class="flex w-full flex-row items-start gap-2">
                    <ImageCropper
                      ref="imageCropperRef"
                      cropForm="free"
                      @imageCropped="imageCropped"
                      @imagePreviewUploaded="imagePreviewUploaded"
                    />
                  </div>
                </div>
              </div>
              <VToggleTwoOptions
                v-model="currentDesign.positions.logo"
                :title="t('views.templates.design.logoPosition')"
                :input-id="'currentDesignTemplate.logo.title'"
                :leftOptionLabel="t('global.left')"
                leftOptionValue="left"
                :rightOptionLabel="t('global.right')"
                rightOptionValue="right"
                @change="handleImagePositionChange"
                class="gap-4 self-start"
              />
            </div>
            <hr class="my-10" />
            <!-- Footers -->
            <div class="my-5 mr-auto flex w-full flex-row items-center justify-start gap-4">
              <div class="flex w-full flex-row items-center justify-between">
                <VToggleTwoOptions
                  v-if="currentEvent.isMultilanguage"
                  v-model="currentEventLanguage"
                  input-id="currentEventLanguage"
                  leftOptionValue="de"
                  rightOptionValue="en"
                  @change="handleEventLanguageChange"
                />
                <VButton
                  v-if="currentDesign.footer.length < 2"
                  type="button"
                  appearance="default"
                  :label="t('views.templates.design.addFooter')"
                  :disabled="false"
                  size="medium"
                  :function-on-click="addSecondFooter"
                />
              </div>
            </div>
            <div
              v-if="currentDesign.footer.length > 0"
              :class="
                currentDesign.footer.length === 1 ? 'w-full' : 'grid grid-cols-1 gap-4 lg:gap-6'
              "
            >
              <QuillEditor
                v-for="(footerItem, index) in currentDesign.footer"
                :key="index"
                :className="'col-span-1'"
                :inputId="'design-template-footer-' + index"
                ref="quillEditorDesignTemplateFooter"
                :label="
                  currentDesign.footer.length === 1
                    ? 'Footer'
                    : index === 0
                      ? `Footer ${t('global.left')}`
                      : `Footer ${t('global.right')}`
                "
                v-model:content="currentDesign.footer[index].text[currentEventLanguage]"
                contentType="html"
              >
                <template #icon>
                  <VButton
                    v-if="index === 1"
                    type="button"
                    appearance="empty"
                    size="medium"
                    :function-on-click="removeSecondFooter"
                  >
                    <FontAwesomeIcon :icon="['fal', 'trash-can']" class="mr-2 size-5 p-1.5" />
                  </VButton>
                </template>
              </QuillEditor>
            </div>
          </div>
          <hr class="my-10 xl:hidden" />
          <div
            v-if="showRightSide"
            ref="formRightSideRef"
            class="flex flex-col overflow-hidden"
            :style="{ maxHeight: leftSideHeight + 'px' }"
          >
            <div class="relative">
              <p class="mb-2 text-base font-bold text-dark-grey dark:text-light-grey">
                {{ t('views.events.design.preview') }}
              </p>
              <VToggleTwoOptions
                v-model="selectDeviceForPreview"
                :input-id="'device-for-preview'"
                :leftOptionLabel="t('global.mobile')"
                leftOptionValue="mobile"
                :rightOptionLabel="t('global.desktop')"
                rightOptionValue="desktop"
                @change="handleSelectDeviceForPreview"
                class="absolute right-0 top-0"
              />
              <VTabNavigator :tabs="tabs" v-model:modelValue="currentTab">
                <!-- Access Tab Content -->
                <!-- 133px is the height of the label and info above tab navigator -->
                <div
                  class="overflow-auto"
                  :class="{ hidden: currentTab !== 'access' }"
                  :style="{ maxHeight: Number(leftSideHeight) - 133 + 'px' }"
                >
                  <Show :isPreview="true" :view="selectDeviceForPreview" :entityType="'design'" />
                </div>
                <!-- No Access Tab Content -->
                <!-- 133px is the height of the label and info above tab navigator -->
                <div
                  class="overflow-auto"
                  :class="{ hidden: currentTab !== 'noaccess' }"
                  :style="{ maxHeight: Number(leftSideHeight) - 133 + 'px' }"
                >
                  <ShowLogin
                    :isPreview="true"
                    :view="selectDeviceForPreview"
                    :entityType="'design'"
                  />
                </div>
              </VTabNavigator>
            </div>
          </div>
        </div>
        <div class="relative z-10 flex justify-end gap-8 py-5">
          <div v-if="designId" class="flex items-center gap-4">
            <VButton
              type="button"
              appearance="cancel"
              :label="t('global.delete')"
              :disabled="false"
              size="large"
              :functionOnClick="() => (openModalDeleteDesign = true)"
            />
            <VButton
              type="submit"
              appearance="default"
              :label="t('global.update')"
              :disabled="!hasUnsavedChanges"
              size="large"
              :functionOnClick="handleUpdateDesign"
            />
          </div>
          <VButton
            v-if="!designId"
            type="submit"
            appearance="default"
            :label="t('global.save')"
            :disabled="!hasUnsavedChanges"
            size="large"
            :functionOnClick="handleCreateDesign"
          />
        </div>
      </form>
    </section>
    <VModal
      v-model:trigger="openModalDeleteDesign"
      :title="t('views.events.design.modalDeleteDesign')"
    >
      <template #modalHeader>
        <p>
          <strong>{{ t('views.events.design.modalDeleteDesign') }}</strong>
        </p>
      </template>
      <template #modalBody>
        <p>
          {{ t('views.events.design.confirmDeleteDesign') }}
        </p>
      </template>
      <template #modalFooter>
        <div class="flex justify-between">
          <VButton
            type="button"
            appearance="cancel"
            :label="t('global.cancel')"
            :disabled="false"
            size="medium"
            :functionOnClick="() => (openModalDeleteDesign = false)"
          />
          <VButton
            type="button"
            appearance="default"
            :label="t('global.yes')"
            :disabled="false"
            size="medium"
            :functionOnClick="handleDeleteDesign"
          />
        </div>
      </template>
    </VModal>
    <!-- Unsaved Changes Modal -->
    <VModal
      :trigger="isUnsavedChangesModalOpen"
      :function-on-close="
        () => {
          isUnsavedChangesModalOpen = false
        }
      "
    >
      <template #modalHeader>
        <p class="text-center text-xl uppercase text-dark-grey dark:text-light-grey">
          {{ t('views.events.index.unsavedChangesTitle') }}
        </p>
      </template>
      <template #modalBody>
        <p class="text-dark-grey dark:text-light-grey">
          {{ t('views.events.index.unsavedChangesMessage') }}
        </p>
      </template>
      <template #modalFooter>
        <div class="flex justify-between">
          <VButton
            type="button"
            appearance="cancel"
            :label="t('global.cancel')"
            size="medium"
            :functionOnClick="
              () => {
                isUnsavedChangesModalOpen = false
              }
            "
          />
          <VButton
            type="button"
            appearance="default"
            :label="t('global.continue')"
            size="medium"
            :functionOnClick="confirmNavigation"
          />
        </div>
      </template>
    </VModal>
  </MainLayout>
</template>
