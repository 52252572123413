/**
 * This composable is used to check if the user has unsaved changes
 * before navigating away from the current route.
 */

import { ref } from 'vue'
import { useRouter } from 'vue-router'

export function useUnsavedChanges() {
	// Check if the event has unsaved changes before navigating away
	const hasUnsavedChanges = ref(false)
	const nextRoute = ref('')
	// Clone the initial form data to compare against later
	const isUnsavedChangesModalOpen = ref(false)
	// Used to forget unsaved changes when navigating to a new route
	const forgetUnsavedChanges = ref(false)
	// Store the next route to navigate to after confirming the unsaved changes
	const router = useRouter()

	// by clicking on confirmNavigation the user will go on to the clicked route
	function confirmNavigation() {
		hasUnsavedChanges.value = false
		isUnsavedChangesModalOpen.value = false

		if (nextRoute.value !== '') {
			// Navigate to the next route provided
			router.push(nextRoute.value)
		}
	}

	function triggerUnsavedChangesModal(routePath: string | null) {
		if (routePath !== null) {
			// save the route where the user wanted to navigate
			nextRoute.value = routePath
		}
		// open modal to let the user know about unsaved changes
		isUnsavedChangesModalOpen.value = true
	}

	return {
		confirmNavigation,
		forgetUnsavedChanges,
		hasUnsavedChanges,
		isUnsavedChangesModalOpen,
		nextRoute,
		triggerUnsavedChangesModal,
	}
}