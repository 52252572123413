<template>
  <!-- Displays the Cloudinary image with advanced options -->
  <AdvancedImage :cldImg="img" :alt="alt || imageName" />
</template>

<script setup lang="ts">
import { Cloudinary } from '@cloudinary/url-gen'
import { AdvancedImage } from '@cloudinary/vue'

/**
 * Component: CloudImage
 *
 * Description:
 * This component displays an image from Cloudinary using the AdvancedImage
 * component from the Cloudinary Vue library. It takes an image name as a prop,
 * which is used to generate the image URL.
 * https://cloudinary.com/documentation/vue_image_transformations#image_transformations_with_vue
 *
 * Props:
 * - imageName (String): The name of the image to be displayed.
 *
 * Usage:
 * <CloudImage imageName="exampleImage" />
 */

// Create an instance of Cloudinary with the provided cloud name
const cloud = new Cloudinary({ cloud: { cloudName: import.meta.env.VITE_CLOUDINARY_NAME } })

const props = defineProps({
  imageName: String,
  alt: String
})

// Generate the image URL using the provided image name
const img = cloud.image(props.imageName)
</script>
