import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toastification'

import { emptyRegistration } from '@/stores/objects/emptyRegistration'
import { emptyWebformSubmission } from '@/stores/objects/emptyWebformSubmission'
import type { components } from '@/types/swagger'
import { tcFetch } from '@/utils/tcFetch'

type Registration = components['schemas']['Registration']
type CreateRegistrationDto = components['schemas']['CreateRegistrationDto']
type WebformSubmission = components['schemas']['WebformSubmission']
type CreateWebformSubmissionDto = components['schemas']['CreateWebformSubmissionDto']

export const useRegistrationsAndWebformSubmissionsStore = defineStore(
  'registrationsAndWebformSubmissions',
  () => {
    /**
     * ----- Internal Variables -----
     */

    const url = import.meta.env.VITE_BACKEND_URL
    const { t } = useI18n()
    const toast = useToast()

    /**
     * ----- Reactive Variables -----
     */
    const currentRegistration = ref<Registration>(
      structuredClone(emptyRegistration) as Registration
    )
    const currentWebformSubmission = ref<WebformSubmission>(
      structuredClone(emptyWebformSubmission) as WebformSubmission
    )

    /**
     * ----- CRUD Actions -----
     */

    /**
     * Create a registration for the current event.
     *
     * @param eventId - The ID of the event.
     * @param createRegistrationDto - The registration to add.
     * @param apiKey - (Optional) The API key to use for the request.
     *
     * @returns The saved registration.
     */
    const createRegistration = async (
      eventId: number,
      createRegistrationDto: CreateRegistrationDto,
      apiKey?: string
    ): Promise<Registration> => {
      const response = await tcFetch(
        'POST',
        `${url}/events/${eventId}/registrations`,
        createRegistrationDto,
        false,
        apiKey
      )

      //noinspection Duplicates
      if (!response.ok) {
        const errorText = await response.json()
        // Check if errorText is an array
        if (errorText.message.length > 0) {
          // If it's an array, iterate over each error message
          errorText.message.forEach((errorMessage: { message: string }) => {
            toast.error(
              `${t('views.events.registration.failedCreateRegistration')}: ${errorMessage.message}`
            )
          })
        } else {
          // If it's not an array, display a single error message
          toast.error(
            `${t('views.events.registration.failedCreateRegistration')}: ${errorText.message}`
          )
        }
      }

      const data = await response.json()

      currentRegistration.value = structuredClone(data) as Registration

      return data
    }

    /**
     * Create a webform submission for the current event.
     *
     * @param eventId - The ID of the event.
     * @param createWebformSubmissionDto - The form data collected in an elements array.
     * @param apiKey - (Optional) The API key to use for the request.
     *
     * @returns A promise with the created webform submission.
     */
    const createWebformSubmission = async (
      eventId: number,
      createWebformSubmissionDto: CreateWebformSubmissionDto,
      apiKey?: string
    ): Promise<WebformSubmission> => {
      const response = await tcFetch(
        'POST',
        `${url}/events/${eventId}/webform-submissions`,
        {
          elements: createWebformSubmissionDto
        },
        false,
        apiKey
      )

      if (!response.ok) {
        const errorText = await response.json()
        // Check if errorText is an array
        if (errorText.message.length > 0) {
          // If it's an array, iterate over each error message
          errorText.message.forEach((errorMessage: { message: string }) => {
            toast.error(
              `${t('views.events.registration.failedCreateWebformSubmission')}: ${errorMessage.message}`
            )
          })
        } else {
          // If it's not an array, display a single error message
          toast.error(
            `${t('views.events.registration.failedCreateWebformSubmission')}: ${errorText.message}`
          )
        }
      }

      const data = await response.json()
      // update the current webform submission with the new data
      currentWebformSubmission.value = structuredClone(data) as WebformSubmission

      return data
    }

    /**
     * Fetch a single registration by its ID from the backend.
     *
     * @param registrationId - The ID of the registration to fetch.
     *
     * @returns A promise with the fetched registration.
     */
    const fetchRegistrationById = async (registrationId: number): Promise<Registration> => {
      const response = await tcFetch('GET', `${url}/registrations/${registrationId}`)

      if (!response.ok) {
        throw new Error(
          `Failed to fetch registrations. Status: ${response.status} ${response.statusText}`
        )
      }

      const data = await response.json()
      // update the current registration with the new data
      currentRegistration.value = structuredClone(data) as Registration

      return data
    }

    /**
     * Remove a registration by ID.
     *
     * @param registrationId - The ID of the registration to remove.
     *
     * @returns The response from the backend (200, 401, 500).
     */
    const deleteRegistration = async (registrationId: number) => {
      const response = await tcFetch('DELETE', `${url}/registrations/${registrationId}`)

      if (!response.ok) {
        const errorText = await response.json()

        // Check if errorText is an array
        if (Array.isArray(errorText.message) && errorText.message.length > 0) {
          // If it's an array, iterate over each error message
          errorText.message.forEach((errorMessage: { message: string }) => {
            toast.error(
              `${t('views.events.analytics.failedDeleteRegistration')}: ${errorMessage.message}`
            )
          })
        } else {
          // If it's not an array, display a single error message
          toast.error(
            `${t('views.events.analytics.failedDeleteRegistration')}: ${t('views.events.analytics.adminRightsRequired')}`
          )
        }
      }

      resetCurrentRegistration()

      return response
    }

    /**
     * ----- NON CRUD Actions -----
     */

    /**
     * Add a webform to a registration of the current event.
     *
     * @param registrationId - The ID of the registration.
     * @param webformSubmissionId - The ID of the webform submission.
     * @param apiKey - (Optional) The API key to use for the request.
     *
     * @returns The updated registration.
     */
    const addWebformToRegistration = async (
      registrationId: number,
      webformSubmissionId: number,
      apiKey?: string
    ): Promise<Registration> => {
      const response = await tcFetch(
        'POST',
        `${url}/registrations/${registrationId}/webformSubmission/${webformSubmissionId}`,
        {}, // Providing an empty object as the body so that we dont get 400 error (not body provided) TODO: The backend should not give a 400 without a body,
        false,
        apiKey
      )

      //noinspection Duplicates
      if (!response.ok) {
        const errorText = await response.json()
        // Check if errorText is an array
        if (errorText.message.length > 0) {
          // If it's an array, iterate over each error message
          errorText.message.forEach((errorMessage: { message: string }) => {
            toast.error(
              `${t('views.events.registration.failedAddWebformToRegistration')}: ${errorMessage.message}`
            )
          })
        } else {
          // If it's not an array, display a single error message
          toast.error(
            `${t('views.events.registration.failedAddWebformToRegistration')}: ${errorText.message}`
          )
        }
      }

      const data = await response.json()
      // update the current registration with the new data
      currentRegistration.value = structuredClone(data) as Registration

      return data
    }

    /**
     * ----- Helper Functions -----
     */

    /**
     * Reset the current registration to its initial state (emptyRegistration).
     */
    const resetCurrentRegistration = () => {
      currentRegistration.value = structuredClone(emptyRegistration) as Registration
    }

    /**
     * Reset the current webform submission to its initial state (emptyWebformSubmission).
     */
    const resetCurrentWebformSubmission = () => {
      currentWebformSubmission.value = structuredClone(emptyWebformSubmission) as WebformSubmission
    }

    return {
      currentRegistration,
      currentWebformSubmission,
      createRegistration,
      createWebformSubmission,
      fetchRegistrationById,
      deleteRegistration,
      addWebformToRegistration,
      resetCurrentRegistration,
      resetCurrentWebformSubmission
    }
  }
)
