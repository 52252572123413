<script setup lang="ts">
import { VButton } from '@techcast/histoire'

import { storeToRefs } from 'pinia'
import { nextTick, onMounted, onUnmounted, ref, toRaw } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import Player3q from '@/components/video/Player3q.vue'
import MainLayout from '@/layouts/MainLayout.vue'
import { useEventsStore } from '@/stores/events.store'
import { useStreamingStore } from '@/stores/streaming.store'

const { t } = useI18n()

const isLoading = ref(false)

const streamingStore = useStreamingStore()
const { currentProject } = storeToRefs(streamingStore)
const { createProject, loadProject, resetCurrentProject } = streamingStore
const eventStore = useEventsStore()
const { currentEvent } = storeToRefs(eventStore)
const { fetchEventById, resetCurrentEvent, addStreamToEvent } = eventStore

onMounted(async () => {
  const route = useRoute()
  const eventId = route.params.id as string

  if (!currentEvent.value) {
    throw new Error('Current event is missing')
  }

  await fetchEventById(+eventId)

  await nextTick()

  if (currentEvent.value.liveProjectId3q) {
    await loadProject(currentEvent.value.liveProjectId3q)
  }
})

onUnmounted(() => {
  resetCurrentEvent()
  resetCurrentProject()
})

const createLiveProject = async () => {
  isLoading.value = true

  const newProject = await createProject(currentEvent.value.name.de, 'live')

  if (newProject.Id) {
    await addStreamToEvent(currentEvent.value.id, newProject.Id, 'live')
    await loadProject(newProject.Id)
  }

  isLoading.value = false
}
</script>

<template>
  <MainLayout>
    <div class="flex h-full max-h-[calc(100vh_-_5.5rem)] flex-col">
      <h1 class="mb-10 mr-8 text-[32px] font-bold lg:text-[42px] xl:text-[58px]">Live</h1>
      <section v-if="!currentEvent.liveProjectId3q">
        <h2 class="my-6 text-xl">{{ t('views.events.video.noLiveProject3q') }}</h2>
        <VButton
          type="button"
          appearance="default"
          :disabled="isLoading"
          :label="t('views.events.video.createLiveProject3q')"
          size="large"
          :functionOnClick="createLiveProject"
        />
      </section>

      <!--  ---------  -->

      <section
        v-if="currentProject && currentProject.channels && currentProject.channels[0]"
        class="flex w-full grow flex-col gap-1 xl:grid xl:grid-cols-[1fr_1fr] xl:gap-0"
      >
        <div class="xl:pr-3">
          <p class="w-full">
            <span class="inline-block w-[35%] py-1 align-top font-bold">
              {{ t('views.events.video.labelLiveProject3qId') }}
            </span>
            <span class="inline-block w-[65%] rounded-t bg-white px-2 py-1">
              {{ currentProject.Id }}
            </span>
          </p>
          <p class="w-full">
            <span class="inline-block w-[35%] py-1 align-top font-bold">
              {{ t('views.events.video.labelLiveProject3q') }}
            </span>
            <span class="inline-block w-[65%] bg-white px-2 py-1">
              {{ currentProject.Label }}
            </span>
          </p>
          <p class="w-full">
            <span class="inline-block w-[35%] py-1 align-top font-bold">
              {{ t('views.events.video.labelChannelId') }}
            </span>
            <span class="inline-block w-[65%] bg-white px-2 py-1">
              {{ currentProject.channels[0].Id }}
            </span>
          </p>
          <p class="w-full">
            <span class="inline-block w-[35%] py-1 align-top font-bold">
              {{ t('views.events.video.labelChannelStatus') }}
            </span>
            <span class="inline-block w-[65%] bg-white px-2 py-1">
              {{ currentProject.channels[0].ChannelStatus }}
            </span>
          </p>
          <p class="w-full">
            <span class="inline-block w-[35%] py-1 align-top font-bold">rtmp: </span>
            <span class="inline-block w-[65%] bg-white px-2 py-1">
              {{ currentProject.channels[0].ingest?.IngestPoints?.[0].ServerURI }}
            </span>
          </p>
          <p class="w-full">
            <span class="inline-block w-[35%] py-1 align-top font-bold">rtmps: </span>
            <span class="inline-block w-[65%] bg-white px-2 py-1">
              {{ currentProject.channels[0].ingest?.IngestPoints?.[0].SecureServerURI }}
            </span>
          </p>
          <p class="w-full">
            <span class="inline-block w-[35%] py-1 align-top font-bold">Stream Name: </span>
            <span class="inline-block w-[65%] break-all rounded-b bg-white px-2 py-1">
              {{ currentProject.channels[0].ingest?.IngestPoints?.[0].StreamName }}
            </span>
          </p>
        </div>
        <Player3q
          v-if="currentProject.channels[0].Id"
          :channelId="currentProject.channels[0].Id"
          :embedParams="{ Autostart: false }"
          class="h-full overflow-hidden xl:w-full [&>iframe]:aspect-video [&>iframe]:h-full [&>iframe]:max-h-[100%]
            [&>iframe]:w-auto [&>iframe]:max-w-[100%] [&>iframe]:rounded [&>iframe]:outline-none
            xl:[&>iframe]:h-auto xl:[&>iframe]:w-full"
        />
      </section>
    </div>
  </MainLayout>
</template>
