<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { onMounted, watch } from 'vue'
import { RouterView } from 'vue-router'

import { useNavStore } from '@/stores/nav.store'
import { useDarkMode } from '@/utils/useDarkMode'

const navStore = useNavStore()
const { isOpen, width } = storeToRefs(navStore)

const { initializeDarkMode } = useDarkMode()

onMounted(() => initializeDarkMode())

// Watch for window width changes and toggle the nav accordingly
// this watch function must be placed here to 'listen' to the router changes, inside another component it won't keep working after the route changed
watch(width, (newWidth, oldWidth) => {
  if (newWidth >= 1024 && oldWidth < 1024) {
    isOpen.value = true // Always open when width grows over 1024
  } else if (newWidth < 1024) {
    isOpen.value = false // Always close when width is less than 1024
  }
})
</script>

<template>
  <RouterView />
</template>
