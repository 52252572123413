import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toastification'

import { emptySpeaker } from '@/stores/objects/emptySpeaker'
import type { components } from '@/types/swagger'
import { tcFetch } from '@/utils/tcFetch'

type Speaker = components['schemas']['Speaker']
type CreateSpeakerDto = components['schemas']['CreateSpeakerDto']
type UpdateSpeakerDto = components['schemas']['UpdateSpeakerDto']
type Asset = components['schemas']['Asset']

export const useSpeakersStore = defineStore('speakers', () => {
  /**
   * ----- Internal Variables -----
   */

  const url = import.meta.env.VITE_BACKEND_URL
  const { t } = useI18n()
  const toast = useToast()

  /**
   * ----- Reactive Variables -----
   */
  const speakers = ref<Speaker[]>([])
  const speakersAlreadyFetched = ref<boolean>(false)
  const currentSpeaker = ref<Speaker>(structuredClone(emptySpeaker) as Speaker)
  const currentSpeakerLanguage = ref<'de' | 'en'>('de')

  /**
   * ----- CRUD Actions -----
   */

  /**
   * Save an speaker to the backend.
   * If the request is successful, add the speaker to the speakers array and sort it.
   *
   * @param createSpeakerDto - The speaker to save.
   * @param speakerImageFile - (optional) The image file to upload.
   *
   * @returns A promise that resolves with the created speaker.
   */
  const createSpeaker = async (
    createSpeakerDto: CreateSpeakerDto,
    speakerImageFile?: File
  ): Promise<Speaker> => {
    const response = await tcFetch('POST', `${url}/speakers`, createSpeakerDto)

    if (!response.ok) {
      // TODO: Bind validation messages to forms
      const errorText = await response.json()
      // Check if errorText is an array
      if (Array.isArray(errorText.message) && errorText.message.length > 0) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(`${t('views.speakers.index.failedCreateSpeaker')}: ${errorMessage.message}`)
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(`${t('views.speakers.index.failedCreateSpeaker')}: ${errorText.message}`)
      }
    }

    const data = await response.json()

    // Check if the request has an image for this speaker
    const speakerImage = speakerImageFile ? [await uploadSpeakerImage(data, speakerImageFile)] : []

    // Update the speaker object with the image (full or empty array if there is not image)
    speakers.value.push({
      ...structuredClone(data),
      images: speakerImage
    })
    currentSpeaker.value = {
      ...structuredClone(data),
      images: speakerImage
    }

    return data
  }

  /**
   * Fetch speakers from the backend and sort them.
   *
   *
   * @returns A promise that resolves with the fetched speakers.
   */
  const fetchAllSpeakers = async (): Promise<Speaker[]> => {
    const response = await tcFetch('GET', `${url}/speakers`)

    if (!response.ok) {
      throw new Error('Failed to fetch speakers.')
    }

    const data = await response.json()
    speakers.value = structuredClone(data)

    sortSpeakersByLastName(speakers.value)
    speakersAlreadyFetched.value = true // TODO: Is this necessary?

    return data
  }

  /**
   * Fetch a speaker by id from the backend.
   * If the request is successful, update the currentSpeaker object.
   *
   * @param speakerId - The ID of the speaker to fetch.
   *
   * @returns A promise that resolves with the fetched speaker.
   */
  const fetchSpeakerById = async (speakerId: number): Promise<Speaker> => {
    const response = await tcFetch('GET', `${url}/speakers/${speakerId}`)

    if (!response.ok) {
      throw new Error(`Failed to fetch speaker. Status: ${response.status} ${response.statusText}`)
    }

    const data = await response.json()
    // Update the currentSpeaker object
    currentSpeaker.value = structuredClone(data) as Speaker

    return data
  }

  /**
   * Update a speaker to the backend.
   * If the request is successful, add the speaker to the speakers array and sort it.
   *
   * @param updateSpeakerDto - The speaker to update.
   * @param speakerImageFile - (optional) The image file to upload.
   *
   * @returns A promise that resolves with the updated speaker.
   */
  const updateSpeaker = async (
    updateSpeakerDto: UpdateSpeakerDto,
    speakerImageFile?: File
  ): Promise<Speaker> => {
    // Check if the request has an image for this speaker
    const speakerImage = speakerImageFile
      ? await uploadSpeakerImage(updateSpeakerDto, speakerImageFile)
      : null

    const response = await tcFetch(
      'PATCH',
      `${url}/speakers/${updateSpeakerDto.id}`,
      updateSpeakerDto
    )

    if (!response.ok) {
      // TODO: Bind validation messages to forms
      const errorText = await response.json()
      // Check if errorText is an array
      if (Array.isArray(errorText.message) && errorText.message.length > 0) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(`${t('views.speakers.index.failedUpdateSpeaker')}: ${errorMessage.message}`)
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(`${t('views.speakers.index.failedUpdateSpeaker')}: ${errorText.message}`)
      }
    }

    const data = await response.json()

    // fetchAllSpeakers must be called so that the array speakers.value is full with the new data
    await fetchAllSpeakers()

    const index = speakers.value.findIndex((s) => String(s.id) === String(updateSpeakerDto.id))

    const updatedSpeakerImage = speakerImageFile
      ? [speakerImage]
      : speakers.value[index].images[0] !== undefined
        ? [speakers.value[index].images[0]]
        : []

    // If found, update the speaker object in the array
    if (index !== -1) {
      speakers.value[index] = {
        ...structuredClone(data),
        images: updatedSpeakerImage
      }
      currentSpeaker.value = {
        ...structuredClone(data),
        images: updatedSpeakerImage
      }
    }

    return data
  }

  /**
   * Delete a speaker from the backend.
   * If the request is successful, remove the speaker from the speakers array.
   *
   * @param speakerId - The ID of the speaker to delete.
   */
  const deleteSpeaker = async (speakerId: number) => {
    const response = await tcFetch('DELETE', `${url}/speakers/${speakerId}`)

    if (!response.ok) {
      const errorText = await response.json()
      // Check if errorText is an array
      if (Array.isArray(errorText.message) && errorText.message.length > 0) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(`${t('views.speakers.index.failedDeleteSpeaker')}: ${errorMessage.message}`)
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(`${t('views.speakers.index.failedDeleteSpeaker')}: ${errorText.message}`)
      }
    }

    speakers.value = speakers.value.filter((speaker) => String(speaker.id) !== String(speakerId))

    resetCurrentSpeaker()

    sortSpeakersByLastName(speakers.value)

    return response
  }

  /**
   * ----- NON CRUD Actions -----
   */

  /**
   * Upload a preview image for a speaker.
   *
   * @param speaker - The speaker.
   * @param file - The image file to upload.
   *
   * @returns A promise that resolves with the uploaded image.
   */
  const uploadSpeakerImage = async (
    speaker: Speaker | UpdateSpeakerDto,
    file: File
  ): Promise<Asset | undefined> => {
    // Check if the speaker already has an image
    const currentSpeaker = await fetchSpeakerById(speaker.id)
    if (currentSpeaker.images && currentSpeaker.images.length > 0) {
      toast.error(t('views.speakers.index.speakerHasAlreadyImage'))
      return
    }

    const formData = new FormData()
    formData.append('file', file)

    const response = await tcFetch('POST', `${url}/speakers/${speaker.id}/images`, formData, true)

    if (!response.ok) {
      const errorText = await response.json()
      // Check if errorText.message is an array
      if (Array.isArray(errorText.message)) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: string) => {
          toast.error(`${t('views.assets.failedUploadAsset')}: ${errorMessage}`)
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(`${t('views.assets.failedUploadAsset')}: ${errorText.message}`)
      }
      return
    }

    return await response.json()
  }

  /**
   * ----- Helper Functions -----
   */

  /**
   * Sort the speakers array by last name.
   *
   * @param speakers - The speakers array to sort.
   *
   * @returns The sorted speakers array.
   */
  const sortSpeakersByLastName = (speakers: Speaker[]) => {
    return speakers.slice().sort((a, b) => a.lastName.localeCompare(b.lastName))
  }

  /**
   * Reset the currentSpeaker object to its initial state (emptySpeaker).
   */
  const resetCurrentSpeaker = () => {
    currentSpeaker.value = structuredClone(emptySpeaker) as Speaker
  }

  return {
    speakers,
    speakersAlreadyFetched,
    currentSpeaker,
    currentSpeakerLanguage,
    createSpeaker,
    fetchAllSpeakers,
    fetchSpeakerById,
    updateSpeaker,
    deleteSpeaker,
    uploadSpeakerImage,
    sortSpeakersByLastName,
    resetCurrentSpeaker
  }
})
