<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import BackButton from '@/components/nav/utilities/BackButton.vue'
import NavIcon from '@/components/nav/utilities/NavIcon.vue'
import NavLink from '@/components/nav/utilities/NavLink.vue'
import { useNavStore } from '@/stores/nav.store'
import { matchesBasePath } from '@/utils/matchesBasePath'

import SubMenu from '../utilities/SubMenu.vue'

/**
 * Component: TemplatesMenu
 *
 * Description:
 * This component displays a sidebar navigation menu for template-related sections of the application.
 * It includes links to various template categories such as Registration Forms, Design or Email Templates.
 * Each section may have a submenu for creating or editing specific templates. 
 *
 * The visibility of submenus is determined based on the current route and whether it matches the base path of the corresponding template section.
 * The `currentId` is used to dynamically generate links for editing specific templates.
 */

/****************************************
 * TRANSLATIONS
 *****************************************/
const { locale, t } = useI18n()

/****************************************
 * ROUTER
 *****************************************/
const route = useRoute()

/****************************************
 * STORES
 *****************************************/
const navStore = useNavStore()
const { currentRoute } = storeToRefs(navStore)

/****************************************
 * COMPUTED VARIABLES
 *****************************************/
const currentId = computed(() => (route.params.id as string) || null)
</script>

<template>
  <section class="relative overflow-scroll pb-[2rem]">
    <BackButton to="/" />

    <NavLink to="/templates/webform">
      <NavIcon>
        <FontAwesomeIcon :icon="['fal', 'calendar-pen']" />
      </NavIcon>
      <span>Registration form</span>
    </NavLink>
    <SubMenu v-if="currentRoute && matchesBasePath(currentRoute, `${locale}/templates/webform`)">
      <NavLink v-if="currentId" :to="`/templates/webform/${currentId}/update`">
        <span>{{ t('global.edit') }}</span>
      </NavLink>
      <NavLink
        v-else-if="matchesBasePath(currentRoute, `${locale}/templates/webform/new`)"
        :to="`/templates/webform/new`"
      >
        <span>{{ t('global.create') }}</span>
      </NavLink>
    </SubMenu>
    <NavLink to="/templates/design">
      <NavIcon>
        <FontAwesomeIcon :icon="['fal', 'calendar-pen']" />
      </NavIcon>
      <span>Design</span>
    </NavLink>
    <SubMenu v-if="currentRoute && matchesBasePath(currentRoute, `${locale}/templates/design`)">
      <NavLink v-if="currentId" :to="`/templates/design/${currentId}/update`">
        <span>{{ t('global.edit') }}</span>
      </NavLink>
      <NavLink
        v-else-if="matchesBasePath(currentRoute, `${locale}/templates/design/new`)"
        :to="`/templates/design/new`"
      >
        <span>{{ t('global.create') }}</span>
      </NavLink>
    </SubMenu>
    <NavLink to="/templates/email">
      <NavIcon>
        <FontAwesomeIcon :icon="['fal', 'calendar-pen']" />
      </NavIcon>
      <span>{{ t('global.email') }}</span>
    </NavLink>
    <SubMenu v-if="currentRoute && matchesBasePath(currentRoute, `${locale}/templates/email`)">
      <NavLink v-if="currentId" :to="`/templates/email/${currentId}/update`">
        <span>{{ t('global.edit') }}</span>
      </NavLink>
      <NavLink
        v-else-if="matchesBasePath(currentRoute, `${locale}/templates/email/new`)"
        :to="`/templates/email/new`"
      >
        <span>{{ t('global.create') }}</span>
      </NavLink>
    </SubMenu>
  </section>
</template>
