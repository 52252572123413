<script setup lang="ts">
import {
  VButton,
  VCheckbox,
  VDatepicker,
  VInput,
  VMessage,
  VModal,
  VTextarea,
  VToggle,
  VToggleTwoOptions,
  VTooltip
} from '@techcast/histoire'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { storeToRefs } from 'pinia'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { onBeforeRouteLeave } from 'vue-router'
import { useToast } from 'vue-toastification'

import CloudImage from '@/components/utils/CloudImage.vue'
import I18nRouterLink from '@/components/utils/I18nRouterLink.vue'
import ImageCropper from '@/components/utils/ImageCropper.vue'
import { findDifferences } from '@/composables/unsaved-changes/useFindDifferences'
import { useUnsavedChanges } from '@/composables/unsaved-changes/useUnsavedChanges'
import { cloneable } from '@/composables/useClone'
import { useImageCrop } from '@/composables/useImageCropped'
import MainLayout from '@/layouts/MainLayout.vue'
import router from '@/router'
import { useAssetsStore } from '@/stores/assets.store'
import { useEventsStore } from '@/stores/events.store'
import type { components } from '@/types/swagger'
import { generateSlug } from '@/utils/generateSlug'
import { getImageName } from '@/utils/getImageName'
import { SUPPORT_LOCALES } from '@/utils/i18n'
import { useDarkMode } from '@/utils/useDarkMode'

/****************************************
* NOTIFICATIONS
*****************************************/
const toast = useToast()

/****************************************
* TRANSLATIONS
*****************************************/
// this locale is the current language of the interface
const { t, locale } = useI18n()

/**
 * Darkmode
 */
const { isDarkMode } = useDarkMode()

/****************************************
* TYPES
*****************************************/
type Event = components['schemas']['Event']
type CreateEventDto = components['schemas']['CreateEventDto']
type UpdateEventDto = components['schemas']['UpdateEventDto']

/****************************************
* STORES
*****************************************/
const eventStore = useEventsStore()
const { currentEvent, currentEventLanguage } = storeToRefs(eventStore)
const { fetchAllEvents, createEvent, updateEvent, fetchEventById, resetCurrentEvent } = eventStore
const assetStore = useAssetsStore()
const { deleteAsset } = assetStore

/****************************************
* LIFECYCLE HOOKS
*****************************************/
onMounted(async () => {
  // the events.value should be fully loaded before the event is fetched by id
  await fetchAllEvents()

  if (eventId && eventId.value) {
    await fetchEventById(+eventId.value)
  } else {
    // Set the default language to the first language in the SUPPORT_LOCALES array
    handleLanguages('de', true)
  }

  // Save a deep copy of the original event state
  originalEventState.value = cloneable.deepCopy(currentEvent.value)
  // Check if the event has a preview image
  eventHadAlreadyAPreviewImage.value = !!currentEvent.value.previewImages?.length
})

onUnmounted(() => {
  resetCurrentEvent()
  nextRoute.value = ''
})

// Check for unsaved changes before navigating away
onBeforeRouteLeave((to, from, next) => {
  if (hasUnsavedChanges.value) {
    triggerUnsavedChangesModal(to.path)
    next(false) // Prevent navigation
  } else {
    next() // Allow navigation
  }
})

/****************************************
* COMPOSABLES
*****************************************/
// Unsaved changes composable
const {
  hasUnsavedChanges,
  isUnsavedChangesModalOpen,
  confirmNavigation,
  triggerUnsavedChangesModal,
  nextRoute
} = useUnsavedChanges()

// Image crop composable
const { imageUploadMessage, showImageUploadMessage, croppedImageFile, imageCropped } =
  useImageCrop()

/****************************************
* REFS
*****************************************/
const imageCropperRef = ref<any>(null)
const eventHadAlreadyAPreviewImage = ref(false)
const imageHasUnsavedChanges = ref(false)
const imageTemporaryDeleted = ref(false)
const showRemoveTemporaryImageButton = ref(false)
const isNewEvent = ref<boolean>(false)
let originalEventState = ref<Event | null>(null) // Clone the initial form data to compare against later

/****************************************
* COMPUTED VARIABLES
*****************************************/
const eventId = computed(() => router.currentRoute.value.params.id as string)

const isFormValid = computed(() => {
  const requiredFields = ['name', 'description', 'url']

  if (currentEvent.value.isMultilanguage || areAllLanguagesSelected.value) {
    for (const locale of SUPPORT_LOCALES) {
      for (const field of requiredFields) {
        if (!(currentEvent.value[field] as Record<string, string>)[locale]) {
          return false
        }
      }
    }
    return true
  }
})

// Check if any language is selected or not to show the validation message
const isAnyLanguageSelected = computed(() => {
  return Object.values(currentEvent.value.languages!).some((checked) => checked)
})

// Check if all supported languages are selected to show the validation message in multilanguage events which have still empty required fields in any other language
const areAllLanguagesSelected = computed(() => {
  return SUPPORT_LOCALES.every((lang) => currentEvent.value.languages.includes(lang))
})

/****************************************
* METHODS
*****************************************/
// change the value of currentEventLanguage to the current selected language in the toggle component
function handleEventLanguageChange(newValue: string) {
  currentEventLanguage.value = newValue
}

// Check if all supported languages are selected
const checkIfAllSupportedLanguagesAreSelected = () => {
  if (SUPPORT_LOCALES.every((lang) => currentEvent.value.languages.includes(lang))) {
    currentEvent.value.isMultilanguage = true
  } else {
    currentEvent.value.isMultilanguage = false
  }
}

// if the image preview is uploaded before being cropped, hasUnsavedChanges will be set to true
function imagePreviewUploaded() {
  imageUploadMessage.value = 'uploaded'
  showRemoveTemporaryImageButton.value = true

  imageHasUnsavedChanges.value =
    !eventHadAlreadyAPreviewImage.value ||
    (eventHadAlreadyAPreviewImage.value && imageTemporaryDeleted.value)

  // Compare old and new event state
  const differences = findDifferences(originalEventState.value, currentEvent.value)
  // Update hasUnsavedChanges by checking if there are differences in the event or if the image has unsaved changes
  hasUnsavedChanges.value = differences.length > 0 || imageHasUnsavedChanges.value
}

async function handleDeleteImageTemporary() {
  imageTemporaryDeleted.value = true
  showRemoveTemporaryImageButton.value = false

  imageCropperRef.value?.reset()

  imageHasUnsavedChanges.value = eventHadAlreadyAPreviewImage.value

  // Compare old and new event state
  const differences = findDifferences(originalEventState.value, currentEvent.value)
  // Update hasUnsavedChanges by checking if there are differences in the event or if the image has unsaved changes
  hasUnsavedChanges.value = differences.length > 0 || imageHasUnsavedChanges.value
}

async function handleCreateEvent() {
  // Check if all supported languages are selected to edit the isMultilanguage value in case the event is not already multilanguage
  !currentEvent.value.isMultilanguage && checkIfAllSupportedLanguagesAreSelected()

  if (
    (currentEvent.value.previewImages!.length === 0 && imageCropperRef.value) ||
    imageTemporaryDeleted.value
  ) {
    await imageCropperRef.value.cropImage()
  }

  if (eventId.value) {
    if (imageTemporaryDeleted.value && currentEvent.value.previewImages[0]) {
      await deleteAsset(currentEvent.value.previewImages![0].id)
      // Reset the previewImages array
      currentEvent.value.previewImages = []
    }

    await updateEvent(currentEvent.value as UpdateEventDto, croppedImageFile.value!).then(() => {
      if (imageTemporaryDeleted.value) {
        // Reset the image temporary deleted state and the image upload message
        imageTemporaryDeleted.value = false
        showImageUploadMessage.value = false
      }
    })

    if (croppedImageFile.value) {
      // Reset the image upload message and cropped image file
      croppedImageFile.value = null
      showImageUploadMessage.value = false
    }

    originalEventState.value = cloneable.deepCopy(currentEvent.value)

    hasUnsavedChanges.value = false
    imageHasUnsavedChanges.value = false
    eventHadAlreadyAPreviewImage.value = !!currentEvent.value.previewImages?.length

    toast.success(t('views.events.new.eventUpdated'))
  } else {
    await createEvent(currentEvent.value as CreateEventDto, croppedImageFile.value!)

    if (croppedImageFile.value) {
      // Reset the image upload message and cropped image file
      croppedImageFile.value = null
      showImageUploadMessage.value = false
    }

    isNewEvent.value = true
    originalEventState.value = cloneable.deepCopy(currentEvent.value)
    // after route change, reset the unsaved changes
    hasUnsavedChanges.value = false
    imageHasUnsavedChanges.value = false
    eventHadAlreadyAPreviewImage.value = !!currentEvent.value.previewImages?.length

    await router.push({ name: 'event-update', params: { id: currentEvent.value.id.toString() } })

    toast.success(t('views.events.new.eventCreated'))

    isNewEvent.value = false
  }
}

// Check if the language is already selected and push/remove it from the languages array
function isLanguageChecked(lang: string) {
  return currentEvent.value.languages?.includes(lang)
}

/**
 * Handles the change in language checkboxes.
 * Updates the currentEventLanguage based on checkbox changes,
 * manages the list of selected languages, and ensures name values are preserved correctly.
 *
 * @param lang The language code ('de', 'en', etc.) associated with the checkbox.
 * @param isChecked Boolean indicating whether the checkbox is checked or unchecked.
 */
function handleLanguages(lang: string, isChecked: boolean) {
  if (!currentEvent.value.languages) {
    currentEvent.value.languages = []
  }

  // Save the current name value
  const currentName = currentEvent.value.name[currentEventLanguage.value]

  if (isChecked && !isLanguageChecked(lang)) {
    currentEvent.value.languages.push(lang)
    currentEventLanguage.value = lang // Update currentEventLanguage
  } else if (!isChecked && isLanguageChecked(lang)) {
    const index = currentEvent.value.languages.indexOf(lang)
    if (index > -1) {
      currentEvent.value.languages.splice(index, 1)
      // Check if the currentEventLanguage needs to be updated
      if (currentEventLanguage.value === lang) {
        currentEventLanguage.value = currentEvent.value.languages[0] || ''
      }
    }
  }

  // Ensure that the list of selected languages is updated correctly and that the associated name values are preserved when a language checkbox is clicked.
  currentEventLanguage.value = 'de'

  // Restore the name value if the new language does not have a corresponding value
  if (!currentEvent.value.name[currentEventLanguage.value]) {
    currentEvent.value.name[currentEventLanguage.value] = currentName
  }
}

/****************************************
* WATCHERS
*****************************************/
// Watcher to check if the start date is greater than the end date and reset the end date if it is
watch(
  () => currentEvent.value.startDate,
  (newStartDate) => {
    if (new Date(newStartDate) > new Date(currentEvent.value.endDate)) {
      currentEvent.value.endDate = ''
    }
  },
  { deep: true, immediate: true }
)

/**
 * Generate a URL from the event name
 */
watch(
  () => currentEvent.value.name[currentEventLanguage.value],
  (newName) => {
    if (newName) {
      const generatedUrl = generateSlug(newName)
      // Update the URL only if it is currently empty or if the generated URL is different from the current URL.
      if (
        !currentEvent.value.url[currentEventLanguage.value] ||
        currentEvent.value.url[currentEventLanguage.value] !== generatedUrl
      ) {
        currentEvent.value.url[currentEventLanguage.value] = generatedUrl
      }
    }
  },
  { deep: true }
)

// Watch currentEvent.value for changes to track unsaved changes
watch(
  () => currentEvent.value,
  () => {
    if (!isNewEvent.value && originalEventState.value) {
      // Compare old and new event state
      const differences = findDifferences(originalEventState.value, currentEvent.value)
      // Update hasUnsavedChanges by checking if there are differences in the event or if the image has unsaved changes
      hasUnsavedChanges.value = differences.length > 0 || imageHasUnsavedChanges.value
    }
  },
  { deep: true }
)
</script>

<template>
  <MainLayout>
    <section class="flex h-full flex-col pb-4 text-dark-grey dark:text-light-grey">
      <h1 class="text-[32px] font-bold lg:text-[42px] xl:text-[58px]">
        {{ currentEvent.name[currentEventLanguage] || '\u200B' }}
      </h1>
      <div class="my-5 flex w-full justify-between">
        <div class="flex flex-row items-center gap-6">
          <VToggleTwoOptions
            v-if="currentEvent.isMultilanguage || currentEvent.languages?.length > 1"
            v-model="currentEventLanguage"
            input-id="currentEventLanguage"
            leftOptionValue="de"
            rightOptionValue="en"
            @change="handleEventLanguageChange"
          />
          <VMessage
            v-if="areAllLanguagesSelected && !isFormValid"
            :errorMessage="{ text: t('views.events.new.mandatoryFieldsNotFilledIn') }"
          />
        </div>
        <div v-if="eventId" class="ml-auto flex justify-end gap-x-2.5">
          <VButton
            :href="`/events-public/${currentEvent.url[currentEventLanguage]}-${eventId}`"
            type="button"
            appearance="outline"
            :label="t('views.events.new.registered')"
            :disabled="false"
            size="small"
            :has-language="true"
            :language="currentEventLanguage"
          />
          <VButton
            type="button"
            appearance="outline"
            :label="t('views.events.new.public')"
            :disabled="false"
            size="small"
            :has-language="true"
            :language="currentEventLanguage"
          />
          <VButton
            type="button"
            appearance="default"
            :label="t('views.events.new.producerView')"
            :disabled="false"
            size="small"
          />
        </div>
      </div>
      <!-- the class 'group/form' triggers the form validation classes of the submit button -->
      <form class="group/form grow tracking-wide" novalidate>
        <div
          class="h-[calc(100svh-18rem)] overflow-y-auto rounded-lg bg-white p-10 shadow md:h-[calc(100svh-21.5rem)]
            dark:bg-dark-grey"
        >
          <VInput
            v-model="currentEvent.name[currentEventLanguage]!"
            type="text"
            input-id="event-name"
            :label="t('views.events.new.eventTitle')"
            :placeholder="t('views.events.new.eventTitlePlaceholder')"
            :required="true"
            :tooltip="t('global.requiredField')"
            :errorMessage="t('global.invalidValue')"
            :disabled="false"
            class="mb-7"
          />
          <div class="mb-7 grid grid-cols-2 gap-8 lg:gap-16">
            <VInput
              v-model="currentEvent.url[currentEventLanguage]!"
              type="text"
              input-id="event-url"
              label="Url"
              placeholder="Event Url..."
              :required="true"
              :tooltip="t('global.requiredField')"
              :errorMessage="t('global.invalidValue')"
              :disabled="false"
              class="col-span-2 lg:col-span-1"
            />
            <VInput
              v-model="currentEvent.subtitle[currentEventLanguage]!"
              type="text"
              input-id="event-subtitle"
              :label="t('views.events.new.eventSubtitle')"
              :placeholder="t('views.events.new.eventSubtitlePlaceholder')"
              :disabled="false"
              class="col-span-2 lg:col-span-1"
            />
          </div>
          <div class="mb-8 grid grid-cols-2 gap-8 lg:gap-16">
            <VDatepicker
              :input-id="`event-start-date-${currentEvent.id}`"
              v-model="currentEvent.startDate"
              :label="t('views.events.new.eventStartDate')"
              :required="true"
              :tooltip="t('global.requiredField')"
              :errorMessage="t('global.invalidValue')"
              :disabled="false"
              :locale="locale"
              class="col-span-2 lg:col-span-1"
              :select-text="t('global.select')"
              :cancel-text="t('global.cancel')"
              :now-button-label="t('global.now')"
              :dark="isDarkMode"
            />
            <VDatepicker
              :input-id="`event-end-date-${currentEvent.id}`"
              v-model="currentEvent.endDate"
              :label="t('views.events.new.eventEndDate')"
              :required="true"
              :tooltip="t('global.requiredField')"
              :errorMessage="t('global.invalidValue')"
              :disabled="false"
              :locale="locale"
              :min-date="currentEvent.startDate"
              class="col-span-2 lg:col-span-1"
              :select-text="t('global.select')"
              :cancel-text="t('global.cancel')"
              :now-button-label="t('global.now')"
              :dark="isDarkMode"
            />
          </div>
          <div class="mb-7 grid grid-cols-2 gap-8 lg:gap-16">
            <VTextarea
              v-model="currentEvent.description[currentEventLanguage]"
              input-id="event-description"
              :label="t('views.events.new.eventDescription')"
              :placeholder="t('views.events.new.eventDescriptionPlaceholder')"
              :required="true"
              :tooltip="t('global.requiredField')"
              :errorMessage="t('global.invalidValue')"
              :disabled="false"
              class="col-span-2 lg:col-span-1"
            />
            <div class="col-span-2 flex flex-col gap-2 lg:col-span-1">
              <div class="flex items-end justify-between">
                <label
                  class="flex flex-row gap-2 text-base font-bold text-dark-grey dark:text-light-grey"
                  ><span>{{ t('components.events.EventTable.previewImage') }}</span
                  ><span class="group relative">
                    <VTooltip
                      :modelValue="t('global.imageDeletedAfterSave')"
                      :position="'center'"
                      :className="'-top-5 text-sm'"
                    />
                    <FontAwesomeIcon :icon="['fal', 'circle-info']" /> </span
                ></label>
                <VButton
                  v-if="imageUploadMessage === 'uploaded' && showRemoveTemporaryImageButton"
                  type="button"
                  appearance="empty"
                  :function-on-click="handleDeleteImageTemporary"
                >
                  <FontAwesomeIcon :icon="['fal', 'xmark']" class="size-5" />
                </VButton>
              </div>
              <div
                v-if="currentEvent.previewImages?.[0]?.public_id && !imageTemporaryDeleted"
                class="flex flex-col gap-4"
              >
                <div class="flex flex-row items-start gap-4">
                  <CloudImage
                    :imageName="currentEvent.previewImages[0].public_id"
                    class="aspect-video max-h-60 max-w-[calc(100%-3rem)] rounded object-cover"
                  />
                  <VButton
                    type="button"
                    appearance="empty"
                    size="small"
                    :function-on-click="handleDeleteImageTemporary"
                  >
                    <FontAwesomeIcon :icon="['fal', 'trash-can']" class="size-5 p-1.5" />
                  </VButton>
                </div>
                <p class="text-xs">{{ getImageName(currentEvent.previewImages[0].public_id) }}</p>
              </div>
              <div v-else class="flex flex-col">
                <p
                  v-if="showImageUploadMessage"
                  class="mt-2 text-sm font-bold"
                  :class="{
                    'text-dark-yellow dark:text-light-yellow': imageUploadMessage === 'uploading',
                    'text-dark-green dark:text-light-green': imageUploadMessage !== 'uploading'
                  }"
                >
                  {{
                    imageUploadMessage === 'uploading'
                      ? t('views.assets.assetBeingUploaded')
                      : t('views.assets.assetSuccessfullyUploaded')
                  }}
                </p>
                <div v-else class="flex w-full flex-row items-start gap-2">
                  <ImageCropper
                    ref="imageCropperRef"
                    cropForm="rectangle"
                    @imageCropped="imageCropped"
                    @imagePreviewUploaded="imagePreviewUploaded"
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="relative mt-3 flex flex-wrap items-center justify-between gap-4">
            <div class="flex items-center">
              <span
                class="font-bold"
                :class="!isAnyLanguageSelected && 'text-dark-red dark:text-light-red'"
              >
                {{ t('global.languages') }}:
              </span>
              <VCheckbox
                :model-value="isLanguageChecked('de')"
                @change="handleLanguages('de', ($event.target as HTMLInputElement)?.checked)"
                input-id="event-languages-german"
                :label="t('global.german')"
                :disabled="currentEvent.isMultilanguage"
              />
              <VCheckbox
                :model-value="isLanguageChecked('en')"
                @change="handleLanguages('en', ($event.target as HTMLInputElement)?.checked)"
                input-id="event-languages-english"
                :label="t('global.english')"
                :disabled="currentEvent.isMultilanguage"
              />
              <!-- Hidden Input Field: This field is updated with the selected value, making it part of the form submission. -->
              <input
                v-if="!isAnyLanguageSelected"
                class="invisible hidden h-0 w-0"
                type="text"
                required
              />
            </div>
            <VMessage
              v-if="!isAnyLanguageSelected"
              class="absolute left-0 top-full h-4 w-max overflow-hidden"
              :errorMessage="{ text: t('global.requiredField') }"
            />
            <div class="flex items-center">
              <span class="mr-4 font-bold"> {{ t('views.events.new.eventProtected') }}: </span>
              <VToggle
                v-model="currentEvent.protected"
                input-id="event-protected"
                :value="currentEvent.protected"
                :disabled="false"
              />
            </div>
          </div>
        </div>
        <div class="mt-10 flex justify-end gap-8">
          <I18nRouterLink to="/events" class="overflow-hidden">
            <VButton
              type="button"
              appearance="cancel"
              :label="t('global.cancel')"
              :disabled="false"
              size="large"
              :functionOnClick="confirmNavigation"
            />
          </I18nRouterLink>
          <VButton
            type="submit"
            appearance="default"
            :label="t('global.save')"
            :disabled="(areAllLanguagesSelected && !isFormValid) || !hasUnsavedChanges"
            size="large"
            :functionOnClick="handleCreateEvent"
          />
        </div>
      </form>
    </section>
    <template #modal>
      <!-- Unsaved Changes Modal -->
      <VModal :trigger="isUnsavedChangesModalOpen" avoidCloseModalOnOverlay>
        <template #modalHeader>
          <p class="text-center text-xl uppercase text-dark-grey dark:text-light-grey">
            {{ t('views.events.index.unsavedChangesTitle') }}
          </p>
        </template>
        <template #modalBody>
          <p class="text-dark-grey dark:text-light-grey">
            {{ t('views.events.index.unsavedChangesMessage') }}
          </p>
        </template>
        <template #modalFooter>
          <div class="flex justify-between">
            <VButton
              type="button"
              appearance="cancel"
              :label="t('global.cancel')"
              size="medium"
              :functionOnClick="
                () => {
                  isUnsavedChangesModalOpen = false
                }
              "
            />
            <VButton
              type="button"
              appearance="default"
              :label="t('global.continue')"
              size="medium"
              :functionOnClick="confirmNavigation"
            />
          </div>
        </template>
      </VModal>
    </template>
  </MainLayout>
</template>
