import type { components } from '@/types/swagger'

type Download = components['schemas']['Download']

export const emptyDownload: Partial<Download> = {
  id: undefined,
  name: '',
  eventId: undefined,
  assets: []
}
