import { defineStore } from 'pinia'
import { type WritableComputedRef, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'

import type { components } from '@/types/swagger'
import { setupI18n } from '@/utils/i18n'
import { tcFetch } from '@/utils/tcFetch'
import { emptyUser } from './objects/emptyUser'

const i18n = setupI18n()
const toast = useToast()
const notificationTranslations = {
  noUserAvailable: {
    de: 'Kein Benutzer vorhanden',
    en: 'No user available.'
  },
  logoutFailed: {
    de: 'Logout fehlgeschlagen. Bitte versuche es erneut.',
    en: 'Logout failed. Please try again.'
  },
  loginFailed: {
    de: 'Anmeldung fehlgeschlagen. Bitte versuche es erneut.',
    en: 'Failed to initialize login session.'
  },
  emailAlreadyTaken: {
    de: 'E-Mail-Adresse bereits vergeben.',
    en: 'Email address is already taken.'
  }
}

type User = components['schemas']['User']

export const useAuthStore = defineStore('auth', () => {
  const user = ref<User | null>(null)
  const currentUser = ref<User>(structuredClone(emptyUser) as User)
  const users = ref<User[]>([])
  const url = import.meta.env.VITE_BACKEND_URL
  const roles = ref<Record<string, any>[]>([
    {
      label: "Admin",
      value: "admin",
    },
    {
      label: "Event Manager",
      value: "event-manager",
    }
  ],)

  /**
   * Login the user by sending a POST request to the backend with the email and password.
   * If the request is successful, the backend sends a http-only cookie.
   *
   * @param email - The email of the user
   * @param password - The password of the user
   *
   * @returns SignInResponseDto - The response from the backend
   *
   * @see {SignInDto}
   * @see {SignInResponseDto}
   */
  const login = async (email: string, password: string) => {
    /**
     * We don't use tcFetch here because it's the only situation where `x-techcast-csrf-protection` is set but no api key is used.
     *
     * @see https://cheatsheetseries.owasp.org/cheatsheets/Cross-Site_Request_Forgery_Prevention_Cheat_Sheet.html#possible-csrf-vulnerabilities-in-login-forms
     */
    const response = await fetch(`${url}/auth/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'x-techcast-csrf-protection': '1' },
      credentials: 'include',
      body: JSON.stringify({ email, password })
    })

    if (!response.ok) {
      const errorText = await response.json()

      if (Array.isArray(errorText.message)) {
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(errorMessage.message)
        })
      } else {
        toast.error(errorText.message)
      }

      /**
       * Early return to prevent further code execution.
       */
      return Promise.resolve()
    }

    const data: { message: string; user: User } = await response.json()

    if (!data.user) {
      toast.error(notificationTranslations.noUserAvailable['de'])
      /**
       * Early return to prevent further code execution.
       */
      return Promise.resolve()
    }

    user.value = data.user

    /**
     *  Without a returned promise, the login function will not be awaited
     */
    return Promise.resolve()
  }

  /**
   * Logout the user by setting the user to null and removing the cookie.
   * Redirects to the login page.
   *
   * @returns logoutDto - The response from the backend
   */
  const logout = async () => {
    // console.log('Logging out...')

    try {
      const response = await fetch(`${url}/auth/logout`, {
        method: 'POST',
        credentials: 'include'
      })

      if (!response.ok) {
        // console.log('Failed to logout:', response.statusText)
        toast.error(notificationTranslations.logoutFailed['de'])
        return
      }

      user.value = null

      let locale: string
      if ('global' in i18n) {
        locale = (i18n.global.locale as WritableComputedRef<string>).value
      } else {
        locale = i18n.locale.value
      }

      const router = useRouter()

      if (router && router.currentRoute) {
        await router.push({ name: 'login', params: { locale: locale } })
      }
    } catch (error) {
      // console.log('A problem occurred during logout:', error)
      toast.error(notificationTranslations.logoutFailed['de'])
    }
  }

  /**
   * This is used in the `locale` path in the router (language setter before children routes).
   *
   * @see `src/router/index.ts`
   */
  const initializeStore = async () => {
    /**
     * Try to get the user profile - simple check to see if the user is logged in.
     */
    try {
      const response = await tcFetch('GET', `${url}/users/profile`, undefined, false)

      if (response.ok) {
        user.value = await response.json()
      } else {
        await logout()
      }
    } catch (error) {
      toast.error(notificationTranslations.loginFailed['de'])
      await logout()
    }
  }

  /**
   * Fetch all users from the backend.
   *
   * @returns User[] - The list of users from the backend
   */
  const fetchAllUsers = async () => {
    const response = await tcFetch('GET', `${url}/users`)

    if (!response.ok) {
      const errorText = await response.json()
      toast.error(errorText.message)
      return []
    }

    const data: User[] = await response.json()

    users.value = structuredClone(data)

    return data
  }

  /**
   * Create a new user by sending a POST request to the backend with the user data.
   *
   * @param newUser - The new user data
   *
   * @returns User - The created user from the backend
   */
  const createUser = async (newUser: User) => {   
    // Check if the email already exists
    const isEmailTaken = users.value.some((user) => user.email === newUser.email)
    
    if (isEmailTaken) {
      toast.error(notificationTranslations.emailAlreadyTaken['de'])
      return null
    }

    const response = await tcFetch('POST', `${url}/users`, newUser)

    if (!response.ok) {
      const errorText = await response.json()
      // Check if errorText is an array
      if (Array.isArray(errorText.message) && errorText.message.length > 0) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(
            `${errorMessage.message}`
          )
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(
          `${errorText.message}`
        )
      }
      return null
    }

    const data: User = await response.json()

    users.value.push(data)

    return data
  }

  /**
   * Update the user profile by sending a PATCH request to the backend with the updated user data.
   *
   * @param updatedUser - The updated user data
   *
   * @returns User - The updated user from the backend
   */
  const updateUser = async (updatedUser: User) => {
    const response = await tcFetch('PATCH', `${url}/users/profile`, updatedUser)

    if (!response.ok) {
      const errorText = await response.json()
      // Check if errorText is an array
      if (Array.isArray(errorText.message) && errorText.message.length > 0) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(
            `${errorMessage.message}`
          )
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(
          `${errorText.message}`
        )
      }
    }

    const data: User = await response.json()

    const index = users.value.findIndex(u => u.id === updatedUser.id)
    if (index !== -1) {
      users.value[index] = updatedUser
    }

    return data
  }

  /**
   * Delete a user by sending a DELETE request to the backend with the user ID.
   *
   * @param userId - The ID of the user to be deleted
   *
   * @returns boolean - True if the user was successfully deleted, false otherwise
   */
  const deleteUser = async (userId: number) => {
    const response = await tcFetch('DELETE', `${url}/users/${userId}`)

    if (!response.ok) {
      const errorText = await response.json()
      // Check if errorText is an array
      if (Array.isArray(errorText.message) && errorText.message.length > 0) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(
            `${errorMessage.message}`
          )
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(
          `${errorText.message}`
        )
      }
    }

    const data = await response.json()

    users.value = users.value.filter(user => String(user.id) !== String(userId))

    return true
  }

  const changeUserPassword = async (currentPassword: string, newPassword: string) => {
    const response = await tcFetch('PATCH', `${url}/users/profile/change-password`, { currentPassword, newPassword })

    // Parse the response body only once
    const responseData = await response.json()

    if (!response.ok) {
      // Check if errorText is an array
      if (Array.isArray(responseData.message) && responseData.message.length > 0) {
        // If it's an array, iterate over each error message
        responseData.message.forEach((errorMessage: { message: string }) => {
          toast.error(`${errorMessage.message}`)
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(`${responseData.message}`)
      }
      return false // Return false to indicate the current password check failed
    }
    
    return responseData // Return true to indicate the password was changed successfully
  }

  /**
   * Reset the currentUser object to its initial state (emptyUser).
   */
  const resetCurrentUser = () => {
    currentUser.value = structuredClone(emptyUser) as User
  }

  return {
    user,
    currentUser,
    users,
    roles,
    login,
    logout,
    initializeStore,
    fetchAllUsers,
    updateUser,
    resetCurrentUser,
    createUser,
    deleteUser,
    changeUserPassword
  }
})
