<script setup lang="ts">
import { VButton, VModal, VTable, type VTableColumn } from '@techcast/histoire'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { storeToRefs } from 'pinia'
import { type ComputedRef, computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toastification'

import I18nRouterLink from '@/components/utils/I18nRouterLink.vue'
import MainLayout from '@/layouts/MainLayout.vue'

/****************************************
* NOTIFICATIONS
*****************************************/
const toast = useToast()

/****************************************
* TRANSLATIONS
*****************************************/
const { t, locale } = useI18n()

/****************************************
* TYPES
*****************************************/

/****************************************
* STORES
*****************************************/

/****************************************
* LIFECYCLE HOOKS
*****************************************/
onMounted(async () => {
  // console.log('onmounted')
})

/****************************************
* REFS
*****************************************/
const isDeleteModalOpen = ref<boolean>(false) // State to track whether the delete confirmation modal is open.
// const emailTemplateToDelete = ref<EmailTemplate | null>(null) // State to store the email template to be deleted.

/****************************************
* COMPUTED VARIABLES
*****************************************/
const columns: ComputedRef<VTableColumn[]> = computed(() => [
  // Define the columns for the table displaying email templates.
  {
    title: t('global.title'),
    name: 'name',
    sortable: true,
    align: 'left',
    valign: 'middle',
    keys: ['name'],
    showTooltip: false,
    keyPositionInSmallDevices: 3
  },
  {
    title: t('global.actions'),
    name: 'id',
    align: 'right',
    valign: 'middle',
    keys: ['id'],
    keyPositionInSmallDevices: 1
  }
])

/****************************************
* METHODS
*****************************************/

/**
 * Closes the delete confirmation modal.
 */
function closeModal() {
  isDeleteModalOpen.value = false // Close the delete modal.
}
</script>

<template>
  <MainLayout>
    <section class="w-full text-dark-grey dark:text-light-grey">
      <div class="mb-10 flex flex-wrap items-center">
        <h1 class="mr-8 text-[32px] font-bold lg:text-[42px] xl:text-[58px]">
          {{ t('global.emails') }}
        </h1>
        <I18nRouterLink to="/templates/email/new">
          <VButton
            type="button"
            appearance="default"
            :label="t('views.templates.email.newEmailTemplate')"
            size="large"
          >
            <FontAwesomeIcon :icon="['fal', 'circle-plus']" />
          </VButton>
        </I18nRouterLink>
      </div>
      <!-- <VTable
        v-if="emailTemplates.length > 0"
        :columns="columns"
        :data="emailTemplates"
        :filterButtonsInSmallDevices="['name']"
      >
        <template #name="{ value }">
          <span class="text-lg font-semibold">
            {{ value }}
          </span>
        </template>
        <template #id="{ value }">
          <VButton type="button" appearance="empty">
            <RouterLink :to="`/${locale}/templates/email/${value}/update`">
              <FontAwesomeIcon :icon="['fal', 'pen-circle']" class="mr-2 size-5 p-1.5" />
            </RouterLink>
          </VButton>
          <VButton type="button" appearance="empty" :function-on-click="() => cloneTemplate(value)">
            <FontAwesomeIcon :icon="['fal', 'clone']" class="mr-2 size-5 p-1.5" />
          </VButton>
          <VButton
            type="button"
            appearance="empty"
            size="medium"
            class="cursor-pointer"
            :functionOnClick="() => openDeleteModal(Number(value))"
          >
            <FontAwesomeIcon :icon="['fal', 'trash-can']" class="mr-2 size-5 p-1.5" />
          </VButton>
        </template>
      </VTable>
      <div
        v-else
        class="h-[calc(100svh-18.5rem)] overflow-y-auto rounded-lg bg-white p-10 shadow dark:bg-dark-grey"
      >
        <div>
          <p class="mb-4">{{ t('views.templates.email.noEmailTemplatesFound') }}</p>
        </div>
      </div> -->
    </section>
    <!-- <template #modal>
      <VModal v-model:trigger="isDeleteModalOpen" @update:trigger="isDeleteModalOpen = $event">
        <template #modalHeader>
          <p
            v-if="emailTemplateToDelete"
            class="text-center text-xl uppercase text-dark-grey dark:text-light-grey"
          >
            {{ emailTemplateToDelete.name }}
          </p>
        </template>
        <template #modalBody>
          <p class="text-dark-grey dark:text-light-grey">
            {{ t('views.templates.registration.confirmDeleteTemplate') }}
          </p>
        </template>
        <template #modalFooter>
          <div class="flex justify-between">
            <VButton
              type="button"
              appearance="cancel"
              :label="t('global.cancel')"
              size="medium"
              :functionOnClick="closeModal"
            />
            <VButton
              v-if="emailTemplateToDelete"
              type="button"
              appearance="default"
              :label="t('global.delete')"
              size="medium"
              :functionOnClick="
                () => {
                  handleDeleteEmailTemplate(Number(emailTemplateToDelete!.id))
                }
              "
            />
          </div>
        </template>
      </VModal>
    </template> -->
  </MainLayout>
</template>
