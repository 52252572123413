const streamTypes = ['live', 'vod'] as const

type RolesAsType = typeof streamTypes

export type StreamType = RolesAsType[number]

const streamTypeNumbers = [1, 2] as const

type StreamTypeNumbersAsType = typeof streamTypeNumbers

export type StreamTypeNumber = StreamTypeNumbersAsType[number]

/**
 * Utility function to convert a StreamType to a StreamTypeNumber
 */
export const convertStreamType = (streamType: StreamType): StreamTypeNumber => {
  switch (streamType) {
    case 'vod':
      return 1
    case 'live':
      return 2
  }
}
