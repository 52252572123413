import { defineAsyncComponent } from 'vue'

const asyncDefault = defineAsyncComponent(() => import('./AccessDefault.vue'))

const mappedFrontendAccessComponents = {
  default: asyncDefault
}

export {
  asyncDefault,
  mappedFrontendAccessComponents
}
