<script setup lang="ts">
import { VButton, VModal, VTable, type VTableColumn } from '@techcast/histoire'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { storeToRefs } from 'pinia'
import { type ComputedRef, computed, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouterLink } from 'vue-router'
import { useToast } from 'vue-toastification'

import I18nRouterLink from '@/components/utils/I18nRouterLink.vue'
import { cloneable } from '@/composables/useClone'
import MainLayout from '@/layouts/MainLayout.vue'
import { useWebformTemplatesStore } from '@/stores/webformTemplates.store'
import type { components } from '@/types/swagger'

/****************************************
* NOTIFICATIONS
*****************************************/
const toast = useToast()

/****************************************
* TRANSLATIONS
*****************************************/
const { t, locale } = useI18n()

/****************************************
* TYPES
*****************************************/
type WebformTemplate = components['schemas']['WebformTemplate']
type CreateWebformTemplateDto = components['schemas']['CreateWebformTemplateDto']

/****************************************
* STORES
*****************************************/
const webformTemplateStore = useWebformTemplatesStore()
const { webformTemplates } = storeToRefs(webformTemplateStore)
const {
  fetchWebformTemplateById,
  fetchAllWebformTemplates,
  deleteWebformTemplate,
  resetCurrentWebformTemplate,
  createWebformTemplate
} = webformTemplateStore

/****************************************
* LIFECYCLE HOOKS
*****************************************/
onMounted(async () => {
  await fetchAllWebformTemplates()
})

onUnmounted(() => {
  resetCurrentWebformTemplate()
})

/****************************************
* REFS
*****************************************/
const isDeleteModalOpen = ref<boolean>(false) // State to track whether the delete confirmation modal is open.
const webformTemplateToDelete = ref<WebformTemplate | null>(null) // State to store the webform template to be deleted.

/****************************************
* COMPUTED VARIABLES
*****************************************/
const columns: ComputedRef<VTableColumn[]> = computed(() => [
  // Define the columns for the table displaying webform templates.
  {
    title: t('global.title'),
    name: 'name',
    sortable: true,
    align: 'left',
    valign: 'middle',
    keys: ['name'],
    showTooltip: false,
    keyPositionInSmallDevices: 3
  },
  {
    title: t('global.actions'),
    name: 'id',
    align: 'right',
    valign: 'middle',
    keys: ['id'],
    keyPositionInSmallDevices: 1
  }
])

/****************************************
* METHODS
*****************************************/
/**
 * Opens the delete confirmation modal.
 * Fetches the webform template by its ID and displays it in the modal.
 * @param webformId - The ID of the webform template to delete.
 */
async function openDeleteModal(webformId: number) {
  await fetchWebformTemplateById(webformId).then((response) => {
    webformTemplateToDelete.value = response // Set the webform template to be deleted.
    isDeleteModalOpen.value = true // Open the delete modal.
  })
}

/**
 * Closes the delete confirmation modal.
 */
function closeModal() {
  isDeleteModalOpen.value = false // Close the delete modal.
}

/**
 * Deletes the selected webform template.
 * If the deletion is successful, it closes the modal and shows a success message.
 * @param webformId - The ID of the webform template to delete.
 */
async function handleDeleteWebformTemplate(webformId: number) {
  await deleteWebformTemplate(webformId).then((response) => {
    if (response.ok) {
      closeModal() // Close the delete modal upon success.
      webformTemplateToDelete.value = null // Clear the reference to the deleted template.
      toast.success(t('views.templates.registration.templateDeleted')) // Show a success message.
    }
  })
}

/**
 * Clones the selected webform template.
 * Creates a deep copy of the original template, assigns a new name, and creates a new template in the backend.
 * @param templateId - The ID of the template to clone.
 */
async function cloneTemplate(templateId: number) {
  // Fetch the design template to be cloned.
  const originalTemplate = await fetchWebformTemplateById(+templateId)

  // Create a deep copy of the original template, excluding the `id` field.
  const { id, ...clonedTemplateData } = cloneable.deepCopy(originalTemplate)

  // Optionally rename the clone.
  const clonedTemplate: CreateWebformTemplateDto = {
    ...clonedTemplateData,
    name: `${t('global.cloneOf')} ${originalTemplate.name}` // Add 'Clone of' to the name.
  }

  // Create the cloned design template on the backend.
  await createWebformTemplate(clonedTemplate)
}
</script>

<template>
  <MainLayout>
    <section class="w-full text-dark-grey dark:text-light-grey">
      <div class="mb-10 flex flex-wrap items-center">
        <h1 class="mr-8 text-[32px] font-bold lg:text-[42px] xl:text-[58px]">
          {{ t('global.forms') }}
        </h1>
        <I18nRouterLink to="/templates/webform/new">
          <VButton
            type="button"
            appearance="default"
            :label="t('views.templates.registration.newForm')"
            size="large"
          >
            <FontAwesomeIcon :icon="['fal', 'circle-plus']" />
          </VButton>
        </I18nRouterLink>
      </div>
      <VTable
        v-if="webformTemplates.length > 0"
        :columns="columns"
        :data="webformTemplates"
        :filterButtonsInSmallDevices="['name']"
      >
        <template #name="{ value }">
          <span class="text-lg font-semibold">
            {{ value }}
          </span>
        </template>
        <template #id="{ value }">
          <VButton type="button" appearance="empty">
            <RouterLink :to="`/${locale}/templates/webform/${value}/update`">
              <FontAwesomeIcon :icon="['fal', 'pen-circle']" class="mr-2 size-5 p-1.5" />
            </RouterLink>
          </VButton>
          <VButton type="button" appearance="empty" :function-on-click="() => cloneTemplate(value)">
            <FontAwesomeIcon :icon="['fal', 'clone']" class="mr-2 size-5 p-1.5" />
          </VButton>
          <VButton
            type="button"
            appearance="empty"
            size="medium"
            class="cursor-pointer"
            :functionOnClick="() => openDeleteModal(Number(value))"
          >
            <FontAwesomeIcon :icon="['fal', 'trash-can']" class="mr-2 size-5 p-1.5" />
          </VButton>
        </template>
      </VTable>
      <div
        v-else
        class="h-[calc(100svh-18.5rem)] overflow-y-auto rounded-lg bg-white p-10 shadow dark:bg-dark-grey"
      >
        <div>
          <p class="mb-4">{{ t('views.templates.registration.noTemplatesFound') }}</p>
        </div>
      </div>
    </section>
    <template #modal>
      <VModal v-model:trigger="isDeleteModalOpen" @update:trigger="isDeleteModalOpen = $event">
        <template #modalHeader>
          <p
            v-if="webformTemplateToDelete"
            class="text-center text-xl uppercase text-dark-grey dark:text-light-grey"
          >
            {{ webformTemplateToDelete.name }}
          </p>
        </template>
        <template #modalBody>
          <p class="text-dark-grey dark:text-light-grey">
            {{ t('views.templates.registration.confirmDeleteTemplate') }}
          </p>
        </template>
        <template #modalFooter>
          <div class="flex justify-between">
            <VButton
              type="button"
              appearance="cancel"
              :label="t('global.cancel')"
              size="medium"
              :functionOnClick="closeModal"
            />
            <VButton
              v-if="webformTemplateToDelete"
              type="button"
              appearance="default"
              :label="t('global.delete')"
              size="medium"
              :functionOnClick="
                () => {
                  handleDeleteWebformTemplate(Number(webformTemplateToDelete!.id))
                }
              "
            />
          </div>
        </template>
      </VModal>
    </template>
  </MainLayout>
</template>
