<script setup lang="ts">
import { storeToRefs } from 'pinia'

import TopMenu from '@/components/nav/TopMenu.vue'
import LeftMenu from '@/components/nav/left-menu/LeftMenu.vue'
import { useNavStore } from '@/stores/nav.store'

const navStore = useNavStore()
const { isOpen } = storeToRefs(navStore)

/**
 * Grid Layout:
 *
 *     auto             1fr
 * |----------|--------------------|
 * |          |       nav-top      | auto
 * | nav-left |--------------------|
 * |          |       content      | 1fr
 * |----------|--------------------|
 *
 * Build with: https://grid.layoutit.com/
 */

// Tailwind dark mode option has been set to 'selector' in tailwind.config.js
// So we can toggle dark mode by adding 'dark' class to the main element
// This allows us to toggle dark mode manually
// See: https://tailwindcss.com/docs/dark-mode#toggling-dark-mode-manually
</script>

<template>
  <main
    class="font-plus-jakarta opacity-1 grid min-h-svh w-full grid-cols-[auto_1fr] grid-rows-[auto_1fr] gap-x-4
      bg-ice-grey pr-4 [grid-template-areas:'nav-left_nav-top''nav-left_content'] md:gap-x-8 md:p-4
      md:pr-8 lg:gap-x-16 lg:pr-16 dark:bg-medium-grey"
  >
    <LeftMenu class="fixed [grid-area:nav-left]" />
    <TopMenu class="[grid-area:nav-top]" />
    <!-- TODO: Add transitions back in when backend has more specific endpoints and we don't use the refreshPage monkey patch anymore -->
    <!-- ----- -->
    <!--    <transition name="slide" mode="out-in" appear>-->
    <div
      class="overflow-auto overflow-y-hidden transition-[margin-left] [grid-area:content]
        motion-reduce:transition-none"
      :class="isOpen ? 'ml-[347px]' : 'ml-[96px]'"
    >
      <slot />
    </div>
    <!--    </transition>-->
    <slot name="modal" />
  </main>
</template>

<style lang="css">
.slide-enter-active,
.slide-leave-active {
  transition:
    opacity 0.3s,
    transform 0.3s;
}
.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translateX(10%);
}
</style>

<!-- transitions docs: https://router.vuejs.org/guide/advanced/transitions -->
