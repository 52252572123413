import { useThrottleFn } from '@vueuse/core'
import { computed, onMounted, onUnmounted, ref } from 'vue'

/**
 * A composable to detect the window width.
 * @returns The current window width.
 */
export const useWidth = () => {
  const windowWidth = ref(window.innerWidth)

  // Throttle the window resize event to prevent performance issues
  const onWidthChange = useThrottleFn(() => {
    windowWidth.value = window.innerWidth
  }, 100)

  onMounted(() => window.addEventListener('resize', onWidthChange))
  onUnmounted(() => window.removeEventListener('resize', onWidthChange))

  return computed(() => windowWidth.value)
}

// source: https://stackoverflow.com/a/63944126/13746045
