import type { components } from '@/types/swagger'

type WebformSubmission = components['schemas']['WebformSubmission']

export const emptyWebformSubmission: Partial<WebformSubmission> = {
  id: undefined,
  eventId: undefined,
  event: undefined,
  registration: undefined,
  elements: {}
}
