/**
 * Utility function that adds the client name to the label
 *
 * @example
 * ```ts
 * // In .env: VITE_CLIENT_NAME=techcast_staging
 * convertLabel('my_new_event')
 * // Returns 'techcast_staging-my_new_event'
 * ```
 */
export const convertLabel = (originalLabel: string): string => {
  const clientName = import.meta.env.VITE_CLIENT_NAME

  if (!clientName) {
    console.warn('No client name provided')
  }

  return `${clientName}-${originalLabel}`
}
