import { cloneable } from '@/composables/useClone'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useToast } from 'vue-toastification'

import { emptyDesignTemplate } from '@/stores/objects/emptyDesignTemplate'
import type { components } from '@/types/swagger'
import { tcFetch } from '@/utils/tcFetch'

type DesignTemplate = components['schemas']['DesignTemplate']
type DesignPreview = components['schemas']['DesignPreview']
type CreateDesignTemplateDto = components['schemas']['CreateDesignTemplateDto']
type UpdateDesignTemplateDto = components['schemas']['UpdateDesignTemplateDto']
type CreateDesignPreviewDto = components['schemas']['CreateDesignPreviewDto']
type UpdateDesignPreviewDto = components['schemas']['UpdateDesignPreviewDto']
type Asset = components['schemas']['Asset']

export const useDesignTemplatesStore = defineStore('designTemplates', () => {
  /**
   * ----- Internal Variables -----
   */

  const url = import.meta.env.VITE_BACKEND_URL
  const { t } = useI18n()
  const toast = useToast()

  /**
   * ----- Reactive Variables -----
   */

  const designTemplates = ref<DesignTemplate[]>([])
  const currentDesignTemplate = ref<DesignTemplate>(
    structuredClone(emptyDesignTemplate) as DesignTemplate
  )
  const currentDesignTemplateLanguage = ref<'de' | 'en'>('de')
  const previewDesignTemplate = ref<DesignTemplate>(structuredClone(emptyDesignTemplate) as DesignTemplate)

  /**
   * ----- CRUD Actions -----
   */

  /**
   * Save an designTemplate to the backend.
   * If the request is successful, add the designTemplate to the designTemplates array and sort it.
   *
   * @param createDesignTemplateDto - The designTemplate object to save.
   * @param logoFile - (Optional) The logo file to upload.
   *
   * @returns The saved designTemplate.
   */
  const createDesignTemplate = async (
    createDesignTemplateDto: CreateDesignTemplateDto,
    logoFile?: File
  ): Promise<DesignTemplate> => {
    const response = await tcFetch('POST', `${url}/templates/designs`, createDesignTemplateDto)

    if (!response.ok) {
      // TODO: Bind validation messages to forms
      const errorText = await response.json()
      // Check if errorText is an array
      if (Array.isArray(errorText.message) && errorText.message.length > 0) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(
            `${t('views.templates.design.failedCreateDesignTemplate')}: ${errorMessage.message}`
          )
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(
          `${t('views.templates.design.failedCreateDesignTemplate')}: ${errorText.message}`
        )
      }
    }

    const data = await response.json()

    // Upload the logo if provided
    let uploadedLogo = null;
    if (logoFile) {
        uploadedLogo = await uploadLogo(data.id, logoFile);
    }

    designTemplates.value.push(structuredClone(data))

    // Create a preview design for the current design
    await createPreviewDesignTemplate(data.id, createDesignTemplateDto as CreateDesignPreviewDto)

    currentDesignTemplate.value = structuredClone(data)

    const index = designTemplates.value.findIndex(
      (template) => String(template.id) === String(currentDesignTemplate.value.id)
    )

    if (index !== -1) {
      // Update the design templates object with the logo
      designTemplates.value[index] = {
        ...structuredClone(data),
        logos: uploadedLogo ? [uploadedLogo] : []
      }
      // Update the current design template object with the logo
      currentDesignTemplate.value = {
        ...structuredClone(data),
        logos: uploadedLogo ? [uploadedLogo] : []
      }
    }

    return currentDesignTemplate.value
  }

  /**
   * Fetch designTemplates from the backend and update the designTemplates array.
   *
   * @returns A promise with the fetched designTemplates.
   */
  const fetchAllDesignTemplates = async (): Promise<DesignTemplate[]> => {
    const response = await tcFetch('GET', `${url}/templates/designs`)

    if (!response.ok) {
      throw new Error(
        `Failed to fetch designTemplates. Status: ${response.status} ${response.statusText}`
      )
    }

    const data = await response.json()

    // Assign the fetched data to the designTemplates array.
    designTemplates.value = structuredClone(data)

    sortDesignTemplates(designTemplates.value)

    return data
  }

  /**
   * Fetch a single designTemplate by its ID from the backend.
   *
   * @param designTemplateId - The ID of the designTemplate to fetch.
   *
   * @returns The designTemplate object.
   */
  const fetchDesignTemplateById = async (designTemplateId: number): Promise<DesignTemplate> => {
    const response = await tcFetch('GET', `${url}/templates/designs/${designTemplateId}`)

    if (!response.ok) {
      throw new Error(
        `Failed to fetch designTemplates. Status: ${response.status} ${response.statusText}`
      )
    }

    const data = await response.json()

    // Assign the fetched data to the currentDesignTemplate object.
    currentDesignTemplate.value = cloneable.deepCopy(data) as DesignTemplate

    return data
  }

  /**
   * Update an existing designTemplate.
   * If the request is successful, update the designTemplate in the designTemplates array and sort it.
   *
   * @param updateDesignTemplateDto - The designTemplate object to update.
   * @param logoFile - (Optional) The logo file to upload.
   *
   * @returns The updated designTemplate.
   */
  const updateDesignTemplate = async (
    updateDesignTemplateDto: UpdateDesignTemplateDto,
    logoFile?: File
  ) => {

    // Check if the request has an image for this event
    const logo = logoFile ? await uploadLogo(updateDesignTemplateDto.id, logoFile) : null

    const response = await tcFetch(
      'PATCH',
      `${url}/templates/designs/${updateDesignTemplateDto.id}`,
      updateDesignTemplateDto as UpdateDesignTemplateDto
    )

    if (!response.ok) {
      const errorText = await response.json()
      // Check if errorText is an array
      if (Array.isArray(errorText.message) && errorText.message.length > 0) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(
            `${t('views.templates.design.failedUpdateDesignTemplate')}: ${errorMessage.message}`
          )
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(
          `${t('views.templates.design.failedUpdateDesignTemplate')}: ${errorText.message}`
        )
      }
    }

    const data = await response.json()

    const updatedLogo = logoFile
      ? [logo]
      : updateDesignTemplateDto.logos?.[0] !== undefined
        ? [updateDesignTemplateDto.logos?.[0]]
        : []

    await updatePreviewDesignTemplate(updateDesignTemplateDto.id, data as UpdateDesignPreviewDto)

    // fetchAllDesignTemplates must be called so that the array designTemplates.value is full with the new data
    await fetchAllDesignTemplates()

    const index = designTemplates.value.findIndex(
      (template) => String(template.id) === String(currentDesignTemplate.value.id)
    )

    // If found, update the template object in the array
    if (index !== -1) {
      // Update the design template object with the logo
      designTemplates.value[index] = {
        ...structuredClone(data),
        logos: updatedLogo
      }
      currentDesignTemplate.value = {
        ...structuredClone(data),
        logos: updatedLogo
      }
    }

    return data
  }

  /**
   * Delete an designTemplate from the backend.
   * If the request is successful, remove the designTemplate from the designTemplates array.
   *
   * @param designTemplateId - The ID of the designTemplate to delete.
   *
   * @returns The response from the backend (200, 401, 500).
   */
  const deleteDesignTemplate = async (designTemplateId: number): Promise<Response> => {
    const response = await tcFetch('DELETE', `${url}/templates/designs/${designTemplateId}`)

    if (!response.ok) {
      const errorText = await response.json()
      // Check if errorText is an array
      if (Array.isArray(errorText.message) && errorText.message.length > 0) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(
            `${t('views.templates.design.failedDeleteDesignTemplate')}: ${errorMessage.message}`
          )
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(
          `${t('views.templates.design.failedDeleteDesignTemplate')}: ${errorText.message}`
        )
      }
    }

    designTemplates.value = designTemplates.value.filter(
      (designTemplate) => String(designTemplate.id) !== String(designTemplateId)
    )

    sortDesignTemplates(designTemplates.value)

    resetCurrentDesignTemplate()

    return response
  }

  /**
 * PREVIEW DESIGN
 */

  /**
   * 
   * Create a preview design
   * 
   * @param designId 
   * @param design 
   * @param logoFile 
   * @returns 
   */
  const createPreviewDesignTemplate = async (
    designId: number,
    createDesignPreviewDto: CreateDesignPreviewDto,
    logoFile?: File
  ): Promise<DesignPreview> => {

    const payload = cloneable.deepCopy({ ...createDesignPreviewDto, entityType: "designTemplate" })
    // Manually delete id if found in the payload
    if ('id' in payload) {
      delete payload['id'];
    }

    const response = await tcFetch('POST', `${url}/designs/${designId}/preview`, payload)

    if (!response.ok) {
      const errorText = await response.json()
      // Check if errorText is an array
      if (Array.isArray(errorText.message) && errorText.message.length > 0) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(`${t('views.templates.design.failedCreateDesignTemplate')}: ${errorMessage.message}`)
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(`${t('views.templates.design.failedCreateDesignTemplate')}: ${errorText.message}`)
      }
    }

    const data = await response.json()

    previewDesignTemplate.value = structuredClone(data)

    return data
  }

  /**
   * 
   * Update a preview design
   * 
   * @param designPreviewId 
   * @param updateDesignPreviewDto 
   * @param logoFile 
   * @returns 
   */
  const updatePreviewDesignTemplate = async (
    designPreviewId: number,
    updateDesignPreviewDto: UpdateDesignPreviewDto
  ): Promise<DesignPreview> => {

    const rawUpdateDesignDto = cloneable.deepCopy(updateDesignPreviewDto)

    const payload = cloneable.deepCopy({ ...rawUpdateDesignDto, id: designPreviewId, entityType: "designTemplate" })

    const response = await tcFetch(
      'PATCH',
      `${url}/designs/previews/${designPreviewId}`,
      payload
    )

    if (!response.ok) {
      const errorText = await response.json()
      // Check if errorText is an array
      if (Array.isArray(errorText.message) && errorText.message.length > 0) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: { message: string }) => {
          toast.error(
            `${t('views.templates.design.failedUpdateDesignTemplate')}: ${errorMessage.message}`
          )
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(`${t('views.templates.design.failedUpdateDesignTemplate')}: ${errorText.message}`)
      }
    }

    let data = await response.json()

    // Update the design object with the logo
    previewDesignTemplate.value = structuredClone(data)

    return data
  }

  /**
   * 
   * Fetch a preview design by id
   * 
   * @param designPreviewId 
   * @returns 
   */
  const fetchPreviewDesignTemplateById = async (designPreviewId: number): Promise<DesignPreview> => {
    const response = await tcFetch('GET', `${url}/designs/previews/${designPreviewId}`)

    if (!response.ok) {
      throw new Error(`Failed to fetch designs. Status: ${response.status} ${response.statusText}`)
    }

    const data = await response.json()

    // Assign the fetched data to the previewDesignTemplate object.
    previewDesignTemplate.value = structuredClone(data) as Design

    return data
  }


  /**
   * ----- NON CRUD Actions -----
   */

  /**
   * Upload a logo for a design template.
   *
   * @param designTemplateId - The ID of the design template.
   * @param file - The image file to upload.
   *
   * @returns A promise with the uploaded asset.
   */
  const uploadLogo = async (designTemplateId: number | undefined, file: File): Promise<Asset | undefined> => {
    const formData = new FormData()
    formData.append('file', file)

    const response = await tcFetch(
      'POST',
      `${url}/templates/designs/${designTemplateId}/images`,
      formData,
      true
    )

    //noinspection Duplicates
    if (!response.ok) {
      const errorText = await response.json()
      // Check if errorText.message is an array
      if (Array.isArray(errorText.message)) {
        // If it's an array, iterate over each error message
        errorText.message.forEach((errorMessage: string) => {
          toast.error(`${t('views.assets.failedUploadAsset')}: ${errorMessage}`)
        })
      } else {
        // If it's not an array, display a single error message
        toast.error(`${t('views.assets.failedUploadAsset')}: ${errorText.message}`)
      }
      return
    }

    const data = await response.json()

    return data
  }

  /**
   * ----- Helper Functions -----
   */

  /**
   * Reset the currentDesignTemplate object to its initial state (emptyDesignTemplate).
   * This function can be called when adding a new designTemplate.
   */
  const resetCurrentDesignTemplate = () => {
    currentDesignTemplate.value = structuredClone(emptyDesignTemplate) as DesignTemplate
  }

  /**
   * Reset the previewDesignTemplate object to its initial state (emptyDesign).
   * This function can be called when adding a new design.
   */
  const resetPreviewDesignTemplate = () => {
    previewDesignTemplate.value = structuredClone(emptyDesignTemplate) as DesignTemplate
  }

  /**
   * Sort the designTemplates array by the updated_at property.
   */
  const sortDesignTemplates = (designTemplates: DesignTemplate[]) => {
    // sort by updated_at
    designTemplates.sort((a, b) => {
      return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
    })
  }

  return {
    designTemplates,
    currentDesignTemplate,
    currentDesignTemplateLanguage,
    fetchAllDesignTemplates,
    fetchDesignTemplateById,
    createDesignTemplate,
    updateDesignTemplate,
    deleteDesignTemplate,
    resetCurrentDesignTemplate,
    resetPreviewDesignTemplate,
    sortDesignTemplates,
    previewDesignTemplate,
    updatePreviewDesignTemplate
  }
})
