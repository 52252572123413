<script setup lang="ts">
import { onMounted, ref } from 'vue'

import { useStreamingStore } from '@/stores/streaming.store'
import type { components as components3q } from '@/types/types3q'

type embedParams = components3q['schemas']['FileEmbedParams']

// Define props with TypeScript
const props = defineProps<{
  channelId: number
  embedParams?: embedParams
}>()

// State to hold the embed code
const embedCode = ref('')

// Import the store and destructure the getEmbedCodes function
const streamingStore = useStreamingStore()
const { getEmbedCodes } = streamingStore

// Function to fetch embed code from the store
const loadEmbedCode = async (channelId: number, params?: embedParams | undefined) => {
  const data = await getEmbedCodes(channelId, params)

  if (data.ChannelEmbedCodes.iFrame) {
    embedCode.value = data.ChannelEmbedCodes.iFrame
  } else {
    throw new Error('No iFrame embed code found')
  }
}

// Fetch the embed code when the component is mounted
onMounted(() => {
  loadEmbedCode(props.channelId, props.embedParams)
})
</script>

<template>
  <div v-if="embedCode" v-html="embedCode"></div>
  <div v-else>Loading player...</div>
</template>
