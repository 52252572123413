/**
 * Composable function to handle image cropping
 */
import { ref } from 'vue'

export function useImageCrop() {
	const imageUploadMessage = ref<string>('|uploading|uploaded')
	const showImageUploadMessage = ref(false)
	const croppedImageFile = ref<File | null>(null)

	function imageCropped(file: File) {
		imageUploadMessage.value = 'uploading'
		showImageUploadMessage.value = true
		croppedImageFile.value = file // Store the cropped image file
	}

	return {
		imageUploadMessage,
		showImageUploadMessage,
		croppedImageFile,
		imageCropped,
	}
}
