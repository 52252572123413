<script setup lang="ts">
import { VButton, VInput, VModal, VRangeSlider, VSelect } from '@techcast/histoire'

import autoAnimate from '@formkit/auto-animate'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { storeToRefs } from 'pinia'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'

import I18nRouterLink from '@/components/utils/I18nRouterLink.vue'
import { findDifferences } from '@/composables/unsaved-changes/useFindDifferences'
import { useUnsavedChanges } from '@/composables/unsaved-changes/useUnsavedChanges'
import { cloneable } from '@/composables/useClone'
import { type User, useUserSetup } from '@/composables/user/useUserSetup'
import MainLayout from '@/layouts/MainLayout.vue'
import { useAuthStore } from '@/stores/auth.store'
import { usePasswordVisibility } from '@/utils/passwordVisibility'

/****************************************
* STORES
*****************************************/
const userStore = useAuthStore()
const { user, roles } = storeToRefs(userStore)
const { updateUser, changeUserPassword } = userStore

/****************************************
* COMPUTED VARIABLES
*****************************************/
// Computed property to find the label of the selected role
const selectedRoleLabel = computed(() => {
  const selectedRoleValue = user.value ? user.value.roles[0] : '' // Get the current role value if user is not null
  const role = roles.value.find((r) => r.value === selectedRoleValue)
  return role ? role.label : '' // Return the role label if found, otherwise return an empty string
})

/****************************************
* LIFECYCLE HOOKS
*****************************************/
onMounted(() => {
  if (changePasswordDropdown.value) {
    autoAnimate(changePasswordDropdown.value)
  }

  // Save a deep copy of the original user state
  originalUserState.value = cloneable.deepCopy(user.value)
})

onUnmounted(() => {
  nextRoute.value = ''
})

// Check for unsaved changes before navigating away
onBeforeRouteLeave((to, from, next) => {
  if (forgetUnsavedChanges.value) {
    hasUnsavedChanges.value = false
    originalUserState.value = null
    resetPasswordFields()
    forgetUnsavedChanges.value = false
    next() // Allow navigation
  } else if (hasUnsavedChanges.value) {
    triggerUnsavedChangesModal(to.path)
    next(false) // Prevent navigation
  } else {
    next() // Allow navigation
  }
})

/****************************************
* COMPOSABLES
*****************************************/
// User setup composable
const {
  changePassword,
  changePasswordDropdown,
  confirmPassword,
  confirmPasswordErrorMessage,
  currentPassword,
  generateRandomPassword,
  onSelectRole,
  originalUserState,
  password,
  passwordLength,
  passwordValidationMessages,
  resetPasswordFields,
  t,
  toast,
  toggleChangePassword,
  validatePassword
} = useUserSetup()

// Unsaved changes composable
const {
  confirmNavigation,
  forgetUnsavedChanges,
  hasUnsavedChanges,
  isUnsavedChangesModalOpen,
  triggerUnsavedChangesModal,
  nextRoute
} = useUnsavedChanges()

// Use the composable for password visibility
const { showPassword, togglePasswordVisibility } = usePasswordVisibility()
const {
  showPassword: showConfirmPassword,
  togglePasswordVisibility: toggleConfirmPasswordVisibility
} = usePasswordVisibility()
const {
  showPassword: showCurrentPassword,
  togglePasswordVisibility: toggleCurrentPasswordVisibility
} = usePasswordVisibility()

/**
 *
 * ---------- Methods ----------
 */

async function handleUpdateUser() {
  // Clone the user object to avoid mutating the original object
  let payload = cloneable.deepCopy(user.value)

  // Proceed only if there is a valid user
  if (user.value) {
    if (changePassword.value) {
      ;(payload as any).password = password.value

      // Check if the old password is correct
      const isCurrentPasswordCorrect = await changeUserPassword(
        currentPassword.value,
        password.value
      )

      // If the old password is incorrect, show an error and return
      if (!isCurrentPasswordCorrect) {
        toast.error(t('views.user.invalidCurrentPassword')) // Show an appropriate error message
        return // Stop further execution if the login fails
      }
    }

    // Update the user and handle the common post-update logic
    await updateUser(payload as User)
    originalUserState.value = cloneable.deepCopy(user.value)
    hasUnsavedChanges.value = false
    resetPasswordFields()

    toast.success(t('views.user.userUpdated'))
  }
}

async function cancelActionsUser() {
  resetPasswordFields()
  user.value = cloneable.deepCopy(originalUserState.value)
  confirmNavigation()
}

/**
 * Function to confirm and handle unsaved changes when a modal is open.
 * This function resets various unsaved change states and refetches the users list.
 */
async function confirmUnsavedChangesModal() {
  confirmNavigation() // Confirm navigation and reset unsaved changes related to the image and other form data
  originalUserState.value = null // Clear the original user state, since it's no longer needed after confirmation
  resetPasswordFields() // Reset the current user state
}

/****************************************
* WATCHERS
*****************************************/
// Watch user and password values for changes to track unsaved changes
watch(
  [() => user.value, () => password.value, () => confirmPassword.value],
  () => {
    // Skip comparison if we are supposed to forget unsaved changes or if the original state is not available
    if (forgetUnsavedChanges.value || !originalUserState.value || !user.value) {
      hasUnsavedChanges.value = false // Reset unsaved changes if data is not available
      return
    }

    // Ensure both user and originalUserState are valid objects
    if (
      typeof user.value === 'object' &&
      user.value !== null &&
      typeof originalUserState.value === 'object' &&
      originalUserState.value !== null
    ) {
      // Compare old and new user state
      const differences = findDifferences(originalUserState.value, user.value)

      // Only consider password and confirmPassword if the changePassword toggle is active
      const hasPasswordChanges = changePassword.value
        ? (password.value !== '' || confirmPassword.value !== '') &&
          password.value === confirmPassword.value
        : false

      // Update hasUnsavedChanges based on other fields or password change if applicable
      hasUnsavedChanges.value = differences.length > 0 || hasPasswordChanges
    } else {
      hasUnsavedChanges.value = false // Reset unsaved changes if invalid objects
    }
  },
  { deep: true }
)

// Validate the password whenever it changes
watch(password, (newPassword) => {
  validatePassword(newPassword)
})

// Watch for changes in password and confirm password to validate
watch([() => password.value, () => confirmPassword.value], ([newPassword, newConfirmPassword]) => {
  // Check if both fields are filled before comparing
  if (newPassword && newConfirmPassword) {
    if (newPassword !== newConfirmPassword) {
      confirmPasswordErrorMessage.value = t('views.user.passwordMismatch')
    } else {
      confirmPasswordErrorMessage.value = ''
    }
  } else {
    // If one of the fields is empty, reset the error message
    confirmPasswordErrorMessage.value = ''
  }
})
</script>

<template>
  <MainLayout>
    <section class="w-full text-dark-grey dark:text-light-grey">
      <h1 class="mb-10 mr-8 text-[32px] font-bold lg:text-[42px] xl:text-[58px]">
        {{ t('global.profile') }}
      </h1>
      <!-- the class 'group/form' triggers the form validation classes of the submit button -->
      <form v-if="user" @submit.prevent class="group/form grow tracking-wide" novalidate>
        <div
          class="flex h-[calc(100svh-18rem)] flex-col gap-10 overflow-y-auto rounded-lg bg-white p-10 shadow
            md:h-[calc(100svh-21.5rem)] dark:bg-dark-grey"
        >
          <div class="grid w-full grid-cols-2 grid-rows-2 gap-8">
            <VInput
              v-model="user!.firstName"
              type="text"
              input-id="user-first-name"
              :label="t('global.firstName')"
              placeholder=""
              :required="true"
              :tooltip="t('global.requiredField')"
              :errorMessage="t('global.invalidValue')"
              :disabled="false"
              class="col-span-2 lg:col-span-1"
            />
            <VInput
              v-model="user!.lastName"
              type="text"
              input-id="user-last-name"
              :label="t('global.lastName')"
              placeholder=""
              :required="true"
              :tooltip="t('global.requiredField')"
              :errorMessage="t('global.invalidValue')"
              :disabled="false"
              class="col-span-2 lg:col-span-1"
            />
            <VInput
              v-model="user!.email"
              type="email"
              input-id="user-email"
              :label="t('global.email')"
              placeholder=""
              :required="true"
              :tooltip="t('global.requiredField')"
              :errorMessage="t('global.invalidValue')"
              :disabled="true"
              class="col-span-2 lg:col-span-1"
            />
            <VSelect
              v-model="user!.roles[0]"
              :selectedValue="selectedRoleLabel"
              :label="`${t('global.role')}:`"
              :placeholder="t('global.role')"
              @selectOption="onSelectRole(user!, $event)"
              :options="roles"
              :disabled="true"
              class="col-span-2 lg:col-span-1"
            />
          </div>
          <div class="flex w-full flex-col justify-end gap-8">
            <VButton
              type="button"
              appearance="button"
              :label="t('views.user.changePassword')"
              :disabled="false"
              size="large"
              :functionOnClick="toggleChangePassword"
            />
            <div ref="changePasswordDropdown">
              <div v-if="changePassword" class="grid w-full grid-cols-2 gap-8">
                <!-- TODO: implement current password input -->
                <div class="col-span-2 grid w-full grid-cols-2 gap-8">
                  <div class="relative col-span-2 lg:col-span-1">
                    <VInput
                      v-model="currentPassword"
                      :type="showCurrentPassword ? 'text' : 'password'"
                      input-id="user-old-password"
                      :label="t('global.currentPassword')"
                      placeholder=""
                      :required="true"
                      :tooltip="t('global.requiredField')"
                      :errorMessage="t('global.invalidValue')"
                      :show-error-message="currentPassword === ''"
                      :disabled="false"
                      class="col-span-2 lg:col-span-1"
                    />
                    <VButton
                      type="button"
                      appearance="empty"
                      size="medium"
                      :functionOnClick="toggleCurrentPasswordVisibility"
                      class="absolute right-1 top-1"
                    >
                      <FontAwesomeIcon
                        v-if="showCurrentPassword"
                        :icon="['fal', 'eye']"
                        class="absolute right-1 top-1"
                      />
                      <FontAwesomeIcon
                        v-else
                        :icon="['fal', 'eye-slash']"
                        class="absolute right-1 top-1"
                      />
                    </VButton>
                  </div>
                </div>
                <div class="relative col-span-2 lg:col-span-1">
                  <div class="flex flex-col gap-4">
                    <VInput
                      v-model="password"
                      :type="showPassword ? 'text' : 'password'"
                      input-id="user-password"
                      :label="t('global.password')"
                      placeholder=""
                      :required="true"
                      :tooltip="t('global.requiredField')"
                      :showValidClasses="password !== '' && passwordValidationMessages.length === 0"
                      :show-error-message="password === '' || passwordValidationMessages.length > 0"
                      :disabled="false"
                      class="col-span-2 lg:col-span-1"
                    />
                    <VButton
                      type="button"
                      appearance="empty"
                      size="medium"
                      :functionOnClick="togglePasswordVisibility"
                      class="absolute right-1 top-1"
                    >
                      <FontAwesomeIcon
                        v-if="showPassword"
                        :icon="['fal', 'eye']"
                        class="absolute right-1 top-1"
                      />
                      <FontAwesomeIcon
                        v-else
                        :icon="['fal', 'eye-slash']"
                        class="absolute right-1 top-1"
                      />
                    </VButton>
                    <ul v-if="passwordValidationMessages.length" class="flex flex-col gap-2">
                      <li
                        v-for="(error, index) in passwordValidationMessages"
                        :key="index"
                        class="text-xs text-dark-red dark:text-light-red"
                      >
                        * {{ error }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="relative col-span-2 lg:col-span-1">
                  <VInput
                    v-model="confirmPassword"
                    :type="showConfirmPassword ? 'text' : 'password'"
                    input-id="user-confirm-password"
                    :label="t('views.user.confirmPassword')"
                    placeholder=""
                    :required="true"
                    :tooltip="t('global.requiredField')"
                    :errorMessage="confirmPasswordErrorMessage"
                    :showValidClasses="
                      password !== '' &&
                      passwordValidationMessages.length === 0 &&
                      password === confirmPassword
                    "
                    :show-error-message="confirmPasswordErrorMessage ? true : false"
                    :disabled="password === '' || passwordValidationMessages.length > 0"
                    class="col-span-2 lg:col-span-1"
                  />
                  <VButton
                    type="button"
                    appearance="empty"
                    size="medium"
                    :functionOnClick="toggleConfirmPasswordVisibility"
                    class="absolute right-1 top-1"
                  >
                    <FontAwesomeIcon
                      v-if="showConfirmPassword"
                      :icon="['fal', 'eye']"
                      class="absolute right-1 top-1"
                    />
                    <FontAwesomeIcon
                      v-else
                      :icon="['fal', 'eye-slash']"
                      class="absolute right-1 top-1"
                    />
                  </VButton>
                </div>
                <div class="flex flex-col gap-2">
                  <VRangeSlider
                    :value="passwordLength"
                    :min="10"
                    :max="20"
                    :disabled="false"
                    :help="`${t('views.user.passwordLength')}: ${passwordLength}`"
                    :class-name="'text-dark-grey dark:text-light-grey'"
                    @update:modelValue="(newValue: number) => (passwordLength = newValue)"
                  />
                  <VButton
                    type="button"
                    appearance="button"
                    :label="t('views.user.createRandomPassword')"
                    :disabled="false"
                    size="medium"
                    :functionOnClick="() => generateRandomPassword(passwordLength)"
                    :class="'mt-3'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-10 flex justify-end gap-8">
          <I18nRouterLink to="/events" class="overflow-hidden">
            <VButton
              type="button"
              appearance="cancel"
              :label="t('global.cancel')"
              :disabled="false"
              size="large"
              :functionOnClick="cancelActionsUser"
            />
          </I18nRouterLink>
          <VButton
            type="submit"
            appearance="default"
            :label="t('global.save')"
            :disabled="!hasUnsavedChanges || (changePassword && password !== confirmPassword)"
            size="large"
            :functionOnClick="handleUpdateUser"
          />
        </div>
      </form>
    </section>
    <template #modal>
      <!-- Unsaved Changes Modal -->
      <VModal :trigger="isUnsavedChangesModalOpen" avoidCloseModalOnOverlay>
        <template #modalHeader>
          <p class="text-center text-xl uppercase text-dark-grey dark:text-light-grey">
            {{ t('views.events.index.unsavedChangesTitle') }}
          </p>
        </template>
        <template #modalBody>
          <p class="text-dark-grey dark:text-light-grey">
            {{ t('views.events.index.unsavedChangesMessage') }}
          </p>
        </template>
        <template #modalFooter>
          <div class="flex justify-between">
            <VButton
              type="button"
              appearance="cancel"
              :label="t('global.cancel')"
              size="medium"
              :functionOnClick="
                () => {
                  isUnsavedChangesModalOpen = false
                }
              "
            />
            <VButton
              type="button"
              appearance="default"
              :label="t('global.continue')"
              size="medium"
              :functionOnClick="confirmUnsavedChangesModal"
            />
          </div>
        </template>
      </VModal>
    </template>
  </MainLayout>
</template>
