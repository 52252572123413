import type { components } from '@/types/swagger'

type EmailTemplate = components['schemas']['EmailTemplate']

export const emptyEmailTemplate: Partial<EmailTemplate> = {
  id: undefined,
  name: '',
  subject: {
    de: '',
    en: ''
  },
  content: {
    de: '<p></p>',
    en: '<p></p>'
  },
}
